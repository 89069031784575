import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const AvisoCurriculo = styled.div`
  background-color: #fcf8e3;
  color: #8a6d3b;
  padding: 15px 20px;
  text-align: center;
  position: sticky;
  top: 55px;
  width: 100%;
  z-index: 2;
`;
