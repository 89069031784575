import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import ListSupervisorActions from "@/store/ducks/supervisores/list";

const getAuthData = state => state.auth.data;

export function* list(action) {
  try {
    const { empresa_id } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.get,
      `supervisores/empresa/${empresa_id}`, makeOptions(authData)
    );

    if (retorno.success) {
      yield put(ListSupervisorActions.listSupervisorSuccess(retorno.data));
    } else {
      yield put(ListSupervisorActions.listSupervisorFailure(retorno.errors));
      // exibindo um toast para cada erro
      [...Object.keys(retorno.errors)].forEach(e => {
        toastify(retorno.errors[e][0], "error");
      });
    }
  } catch (e) {
    yield put(ListSupervisorActions.listSupervisorFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}
