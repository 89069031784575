import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isAfter, isValid, parse } from "date-fns";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import { Form, Input } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";

import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalAddCurso({ onClose }) {
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  const schema = Yup.object().shape({
    curso_nome: Yup.string().required("Obrigatório"),
    curso_descricao: Yup.mixed(),
    data_inicio: Yup.date()
      .typeError("Data inválida")
      .required("Obrigatório"),
    data_termino: Yup.mixed()
      .test("data-conclusao-maior", "Data inválida", function(value) {
        if (value === "") return true;
        const termino = parse(`${value}`, "yyyy-MM-dd", new Date());
        return isValid(termino) && isAfter(termino, this.parent.data_inicio);
      })
      .notRequired(),
  });

  async function createCurso(data, resetForm) {
    setLoading(true);
    const endpoint = `alunos/curriculos/cursos-complementares`;
    const alunoId = aluno.id;
    data.aluno_id = alunoId;
    const { data: response } = await api.post(
      endpoint,
      data,
      makeOptions(user)
    );
    setLoading(false);
    if (response.success) {
      toastify("Curso salvo com sucesso");
      fetchAluno(alunoId);
      resetForm();
      onClose();
    }
  }

  function handleSubmit(data, { resetForm }) {
    createCurso(data, resetForm);
  }

  return (
    <ModalContent>
      <Form onSubmit={handleSubmit} schema={schema}>
        <ModalBody>
          <Row>
            <Col>
              <Input label="Nome do curso" name="curso_nome" />
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <Input
                type="date"
                max="2099-01-01"
                label="Data de início"
                name="data_inicio"
              />
            </Col>
            <Col>
              <Input
                type="date"
                max="2099-01-01"
                label="Data de término (ou previsão)"
                name="data_termino"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                multiline
                showLength
                maxLength={500}
                label="Descrição do curso"
                name="curso_descricao"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="cancel" onClick={onClose}>
            CANCELAR
          </Button>
          <Button
            type="submit"
            variant="tangerine"
            loading={loading || undefined}
          >
            SALVAR ALTERAÇÕES
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
