import React from "react";
import {
  // HashRouter as Router,
  Router,
  Switch,
  Route,
} from "react-router-dom";
import { useSelector } from "react-redux";

// rotas especiais
import {
  PrivateRoute,
  AdminOnlyRoute,
  FranqueadoOnlyRoute,
  GuestRoute,
  PerfilRoute,
  VagasRoute,
  VagaRoute,
  VagaPerfilAlunoRoute,
  VagaEntrevistasRoute,
  SupervisoresRoute,
  EntrevistasRoute,
  AlunosRoute,
  AlunoRoute,
  TCEsRoute,
  TCERoute,
  AvaliacaoRoute
} from "./RoutesComponents";

// Apenas a págna Home
import Home from "@/pages/Home";

// Páginas que fazem parte do processo de autenticação ou cadastro pré login
import CadastreSe from "@/pages/Auth/CadastreSe";
import CadastroEmpresa from "@/pages/Auth/CadastroEmpresa";
import CadastroEscola from "@/pages/Auth/CadastroEscola";
import CadastroEstudante from "@/pages/Auth/CadastroEstudante";
import CompletarCadastro from "@/pages/Auth/CompletarCadastro";
import EditarCredenciais from "@/pages/Auth/EditarCredenciais";
//processo de recuperacao de senha
import RecuperarSenha from "@/pages/Auth/RecuperarSenha";
import NovaSenha from "@/pages/Auth/NovaSenha";
//
import AceitarTCE from "@/pages/Coordenador/AceitarTCE";
import AssinarTCE from "@/pages/Assinante/AssinarTCE";
import GuestTCEView from "@/pages/Guest/TCE";
import Login from "@/pages/Auth/Login";

// termod de serviço
import TermosDeUso from "@/pages/TermosDeUso";

// Rotas de admin
import Empresas from "@/pages/Admin/Empresas/Empresas";
import Empresa from "@/pages/Admin/Empresas/Empresa";
import Escolas from "@/pages/Admin/Escolas/Escolas";
import Franqueados from "@/pages/Admin/Franqueados/Franqueados";
import Escola from "@/pages/Admin/Escolas/Escola";
import SugestoesEscolas from "@/pages/Admin/Escolas/SugestoesEscola";
import AdminCadastroAluno from "@/pages/Admin/Alunos/CadastroAluno";
import AdminCadastroEmpresa from "@/pages/Admin/Empresas/CadastroEmpresa";
import AdminCadastroEscola from "@/pages/Admin/Escolas/CadastroEscola";
import AdminCadastroFranqueado from "@/pages/Admin/Franqueados/CadastroFranqueado";
import AdminVagas from "@/pages/Admin/Vagas";
import IndicadoresTotais from "@/pages/Admin/Indicadores/Totais";

//Rotas Franqueados
import FranqueadosVagas from "@/pages/Franqueados/Vagas";
import FranqueadosEmpresas from "@/pages/Franqueados/Empresas/Empresas";
import FranqueadosEmpresa from "@/pages/Franqueados/Empresas/Empresa";
import FranqueadosCadastroEmpresa from "@/pages/Franqueados/Empresas/CadastroEmpresa";

export default function Routes(props) {
  const auth = useSelector(state => state.auth);

  return (
    <Router basename="/" {...props}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/cadastre-se" exact component={CadastreSe} />
        <Route path="/cadastro-empresa" exact component={CadastroEmpresa} />
        <Route path="/cadastro-escola" exact component={CadastroEscola} />
        <Route path="/cadastro-estudante" exact component={CadastroEstudante} />
        <Route path="/recuperar-senha" exact component={RecuperarSenha} />
        <Route path="/nova-senha/:recuperacao_senha" exact component={NovaSenha} />
        <Route path="/termos" exact component={TermosDeUso} />

        <PrivateRoute path="/editar-credenciais" auth={auth} exact component={EditarCredenciais} />
        {/* <Route path="/aluno/vagas" exact component={AlunoVagas} /> */}
        <GuestRoute
          auth={auth}
          path="/completar-cadastro/:codigo?"
          exact
          component={CompletarCadastro}
        />
        <Route
          auth={auth}
          path="/coordenador-aceitar-tce/:tceId/:codigo"
          exact
          component={AceitarTCE}
        />
        <Route
          auth={auth}
          path="/assinante-aceitar-tce/:tceId/:codigo"
          exact
          component={AssinarTCE}
        />
        <Route
          // auth={auth}
          path="/visualizar-tce/:tceId"
          exact
          component={GuestTCEView}
        />
        <Route path="/login" exact component={Login} />

        <PerfilRoute auth={auth} path="/perfil" exact />
        <VagasRoute auth={auth} path="/vagas" exact />
        <VagaRoute auth={auth} path="/vaga/:id" exact />
        <VagaPerfilAlunoRoute
          auth={auth}
          path="/vaga-estudante/:vagaId/:alunoId"
        />
        <VagaEntrevistasRoute
          auth={auth}
          path="/vaga-entrevistas/:vagaId"
          exact
        />

        <AdminOnlyRoute auth={auth} path="/empresas/:status" exact component={Empresas} />
        <AdminOnlyRoute auth={auth} path="/empresa/:id" exact component={Empresa} />
        <AdminOnlyRoute auth={auth} path="/escolas/:status" exact component={Escolas} />
        <AdminOnlyRoute auth={auth} path="/sugestoes-escolas" exact component={SugestoesEscolas} />
        <AdminOnlyRoute auth={auth} path="/escola/:id" exact component={Escola} />
        <AdminOnlyRoute auth={auth} path="/admin/cadastro-estudante" exact component={AdminCadastroAluno} />
        <AdminOnlyRoute auth={auth} path="/admin/cadastro-empresa" exact component={AdminCadastroEmpresa} />
        <AdminOnlyRoute auth={auth} path="/admin/cadastro-escola" exact component={AdminCadastroEscola} />
        <AdminOnlyRoute auth={auth} path="/admin/cadastro-franqueado" exact component={AdminCadastroFranqueado} />
        <AdminOnlyRoute auth={auth} path="/admin/indicadores/totais" exact component={IndicadoresTotais} />
        <AdminOnlyRoute auth={auth} path="/admin/vagas" exact component={AdminVagas} />
        <AdminOnlyRoute auth={auth} path="/franqueados/:status" exact component={Franqueados} />

        <FranqueadoOnlyRoute auth={auth} path="/franqueado/vagas" exact component={FranqueadosVagas} />
        <FranqueadoOnlyRoute auth={auth} path="/franqueado/empresas" exact component={FranqueadosEmpresas} />
        <FranqueadoOnlyRoute auth={auth} path="/franqueado/empresa/:id" exact component={FranqueadosEmpresa} />
        <FranqueadoOnlyRoute auth={auth} path="/franqueado/cadastro-empresa" exact component={FranqueadosCadastroEmpresa} />

        <EntrevistasRoute auth={auth} path="/entrevistas" exact />
        <SupervisoresRoute auth={auth} path="/supervisores" exact />
        <AlunosRoute auth={auth} path="/alunos" exact />
        <AlunosRoute auth={auth} path="/alunos/:status" exact />
        <AlunoRoute auth={auth} path="/aluno/:alunoId" exact />
        <TCEsRoute auth={auth} path="/tces" exact />
        <TCEsRoute auth={auth} path="/tces/:status" exact />
        <TCERoute auth={auth} path="/tce/:id" exact />
        <AvaliacaoRoute auth={auth} path="/avaliacao/:id" exact />
      </Switch>
    </Router>
  );
}
