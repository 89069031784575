import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useEmpresa } from "@/hooks/empresas";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { AdminMenu, EmpresaObservacoes, AdminPainel } from "@/components/Admin";
import { Sidebar, Sobre, TCEs } from "@/components/Empresa";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
} from "./styles";

function _Perfil({ match }) {
  const { fetchEmpresa, empresa } = useEmpresa();

  useEffect(() => {
    fetchEmpresa(match.params.id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <PainelPrincipal>
                <h1>Empresa</h1>
                <Tabs
                  forceRenderTabPanel={true}
                >
                  <TabList>
                    <Tab>Sobre a empresa</Tab>
                    <Tab>Contratos</Tab>
                  </TabList>
                  <TabPanel>
                    <Sobre />
                  </TabPanel>
                  <TabPanel>
                    <TCEs empresaId={match.params.id} />
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
              <EmpresaObservacoes empresaId={empresa.id} />
              <AdminPainel id={empresa.id} modo="empresa" />
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Perfil({ history, match }) {
  return (
    <EmpresaProvider>
      <_Perfil history={history} match={match} />
    </EmpresaProvider>
  );
}
