import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
`;

export const DashboardContainer = styled.main.attrs({
  className: "dashboard-main",
})`
  width: 100%;
  max-width: 1440px;
  padding: 55px 32px;
  margin: 0 auto;

  @media screen and (max-width: 1290px) {
    padding-left: 2em;
    padding-right: 2em;
  }
`;

export const Container = styled.div.attrs({
  className: "grid-container",
})`
  width: 100%;
  margin: 0 auto;
  max-width: ${props => ({ fluid }) => {
    return fluid !== undefined ? "100%" : "1100px";
  }};
  display: flex;
`;

export const Row = styled.div.attrs({
  className: "grid-row",
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;

  ${props => ({ gaps, hascols }) => {
    if (hascols !== undefined) {
      const _gaps = gaps !== undefined ? gaps / 2 : 8;
      return `
        margin-left: -${_gaps}px;
        margin-right: -${_gaps}px;
        width: calc(100% + ${_gaps * 2}px);

        div.grid-col {
          padding-left: ${_gaps}px;
          padding-right: ${_gaps}px;
        }
      `;
    }
  }};

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const Col = styled.div.attrs({
  className: "grid-col",
})`
  flex: 1;
  margin-bottom: 25px;

  ${({ auto }) => {
    if (auto !== undefined) return `flex: 0`;
  }}

  ${({ sm }) => {
    if (sm !== undefined)
      return `
      @media screen and (min-width: 576px) {
        flex: 1 1 100%;
        max-width: calc(100% * ${sm})
      }
    `;
  }}

  ${({ md }) => {
    if (md !== undefined)
      return `
      @media screen and (min-width: 768px) {
        flex: 1 1 100%;
        max-width: calc(100% * ${md})
      }
    `;
  }}

  ${({ lg }) => {
    if (lg !== undefined)
      return `
      @media screen and (min-width: 992px) {
        flex: 1 1 100%;
        max-width: calc(100% * ${lg})
      }
    `;
  }}

  ${({ xl }) => {
    if (xl !== undefined)
      return `
      @media screen and (min-width: 1200px) {
        flex: 1 1 100%;
        max-width: calc(100% * ${xl})
      }
    `;
  }}

  @media screen and (max-width: 575px) {
    flex: 1 0 100%;
  }
`;
