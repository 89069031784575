import React, { useState } from "react";
import { Row, Col } from "@/components/Grid";
import { Navbar, Footer } from "@/components/Blocks";
import { Hero } from "@/components/PageComponents/Home";
import VagaCard from "@/components/Common/Card/VagaCard";
import { Button } from "@/components/Common";
import Paginator, { Loading } from "@/components/Paginator";

import lineFirula from "@/assets/images/line-firula.png";

import {
  Page,
  SectionHero,
  SectionVagas,
  SectionHeader,
  SectionFeatures,
  Feature,
  Divider,
  Empresas,
  Empresa,
} from "./styles";

export default function Home() {
  const [vagasQuery] = useState({ count: 4 });
  const [vagas, setVagas] = useState([]);
  const [loadingVagas, setLoadingVagas] = useState(false);

  function handleFetchComplete(data) {
    setVagas(data.data);
  }

  function renderVagas() {
    const listVagas = vagas.map(vaga => {
      return (
        <Col xl="1/4" lg="1/2" md="1" sm="1" key={vaga.id}>
          <VagaCard vaga={vaga} key={vaga.id}/>
        </Col>
      );
    });
    return <Row hascols>{listVagas}</Row>;
  }

  const features = [
    {
      titulo: "Para Empresas",
      descricao:
        "Quer contratar um estagiário? O Integrador.app é totalmente intuitivo e completo. Encontre candidatos, marque entrevistas, recrute, formalize e cumpra todas as exigências legais com segurança e facilidade.",
      image: require("../../assets/images/icons/empresa.png"),
    },
    {
      titulo: "Para Escolas",
      descricao:
        "Disponibilize vagas de estágio para seus estudantes. Assine, aprove e gerencie toda a documentação de estágio e avaliações. Acompanhe de perto o estágio dos estudantes com um painel intuitivo de gestão e participe com o Integrador.app da revolução do processo de estágio no Brasil.",
      image: require("../../assets/images/icons/escola.png"),
    },
    {
      titulo: "Para Estudantes",
      descricao:
        "Procurando estágio? Cadastre-se e participe de processos seletivos na sua região. Encontre vagas no seu perfil e inicie sua carreira profissional.",
      image: require("../../assets/images/icons/estudante.png"),
    },
  ];

  const FeaturesList = features.map((f, index) => (
    <Col key={index} lg="1/3" md="1" sm="1" >
      <Feature>
        <img src={f.image} alt=""/>
        <p className="titulo">{f.titulo}</p>
        <p className="descricao">{f.descricao}</p>
      </Feature>
    </Col>
  ));

  return (
    <Page>
      <Navbar type="home" />

      <SectionHero>
        <Hero />
      </SectionHero>

      <SectionVagas>
        <img src={lineFirula} alt="Lines" className="firula" />
        <SectionHeader>
          <h3 className="vagasTitle">Vagas em destaque</h3>
          <p className="vagasDescription">Confira as oportunidades de estágio e candidate-se.</p>
        </SectionHeader>
        <div className="vagas">
          <Loading loading={loadingVagas}>
            {renderVagas()}
            <Paginator
              endpoint="convidado-vagas"
              query={vagasQuery}
              onFetchComplete={handleFetchComplete}
              onLoading={l => setLoadingVagas(l)}
              hidePaginator
            />
          </Loading>
        </div>
        <Button to="/vagas" variant="integradorYellow-outline">
          Ver todas as vagas
        </Button>
      </SectionVagas>

      <SectionFeatures>
        <SectionHeader>
          <h3>
            O Integrador.app facilita a conexão entre empresas, estudantes e
            escolas.
          </h3>
          <p>
            A tecnologia mudou a forma de se pedir transporte, comida, fazer
            investimentos e agora mudou também a maneira de se contratar um
            estagiário. Apresentamos o Integrador.app, primeiro Agente de
            Integração 100% online do Brasil.
          </p>
        </SectionHeader>
        <div className="features">
          <Row hascols gaps="40">
            {FeaturesList}
          </Row>
        </div>
        {/* <Divider /> */}
      </SectionFeatures>
      <Footer />
    </Page>
  );
}
