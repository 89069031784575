import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { Form, Select } from "@/components/Form";
import { useTce } from "@/hooks/tces";
import { ModalContent, ModalFooter } from "./styles";
import api, { makeOptions } from "@/services/api";

export default function ModalRecuse({ onClose, tce }) {
  const { data: user } = useSelector(state => state.auth);
  const { recusarTce, loading } = useTce();
  const [cancelamentoOptions, setCancelamentoOptions] = useState([]);

  const schema = Yup.object().shape({
    tce_cancelamento_motivo_id: Yup.number().typeError("Obrigatório").required("Obrigatório"),
  });

  function handleSubmit(data = {}) {
    function onComplete() {
      if (onClose) onClose();
    }
    recusarTce(tce.id, data, onComplete);
  }

  const fetchMotivosCancelamentoCB = useCallback(async () => {
    const endpoint = `tces-cancelamento-motivos/${tce.id}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    if (response.success) {
      setCancelamentoOptions(() => {
        return response.data.map(option => {
          return { id: option.id, title: option.nome };
        });
      });
    }
  }, [api, makeOptions, setCancelamentoOptions]);

  useEffect(() => {
    fetchMotivosCancelamentoCB();
  }, [fetchMotivosCancelamentoCB]);

  return (
    <ModalContent>
      <h2>Tem certeza de que deseja recusar este contrato?</h2>
      <p>Esta ação é irreversível</p>
      <Form schema={schema} onSubmit={handleSubmit} initialData={FormData}>
        <Row>
          <Col>
            <Select
              name="tce_cancelamento_motivo_id"
              label="Motivo do cancelamento"
              options={cancelamentoOptions}
            />
          </Col>
        </Row>
        <ModalFooter>
          <Button
            type="submit"
            block
            variant="tangerine"
            loading={loading || undefined}
          >
            Aceitar
          </Button>
          <Button
            type="button"
            block
            variant="cancel"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
