import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { AceiteContainer, IconContainer, Message } from "./styles";

export default function Autorizo({ onChange }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  function toggle() {
    setChecked(!checked);
  }

  return (
    <>
      <AceiteContainer>
        <IconContainer onClick={toggle}>
          {checked && <FaCheck color="#888888" size={12} />}
        </IconContainer>
        <Message>
          Autorizo o Integrador.app a encaminhar SMS, enviar notificações via
          WhatsApp, e-mail e telefone.
        </Message>
      </AceiteContainer>
    </>
  );
}
