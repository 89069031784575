import React from "react";
import { Button } from "@/components/Common";
import { Form } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, H1, Div, A } from "./styles";

export default function ModalContato({ OnClose }) {
  return (
    <ModalContent>
      <ModalBody>
        <Row>
          <Col>
            <H1>Fale Conosco</H1>
          </Col>
        </Row>
        <Div>
          <h4>Mande um e-mail:</h4>
          <A href="mailto:contato@integrador.app">
            <Button>Mande um e-mail</Button>
          </A>
        </Div>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Div>
          <h4>Mande um mensagem:</h4>
          <A href="https://api.whatsapp.com/send?phone=5524999359020">
            <Button>Mande uma mensagem</Button>
          </A>
        </Div>
        <Row>
          <Col></Col>
        </Row>
        <Div>
          <Button className="btn-cancel" onClick={() => OnClose()}>
            Cancelar
          </Button>
        </Div>
      </ModalBody>
    </ModalContent>
  );
}
