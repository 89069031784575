import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import {
  OuterContainer,
  MenuIconContainer,
  MenuIcon,
  CloseIconContainer,
  CloseIcon,
  HiddenContainer,
  HiddenContent,
  OptionLink,
  Menu,
  MenuHeader,
  MenuHeaderTitle,
  MenuBody,
  MenuOptionLink,
} from "./styles";

function MobileMenu({ type }) {
  const [openMobileMenu, setOpenMobileMenu] = useState("close");

  return (
    <OuterContainer>
      <MenuIconContainer onClick={() => setOpenMobileMenu("open")}>
        <MenuIcon type={type} />
      </MenuIconContainer>

      <HiddenContainer state={openMobileMenu}>
        <HiddenContent>
          <CloseIconContainer onClick={() => setOpenMobileMenu("close")}>
            <CloseIcon />
          </CloseIconContainer>

          <OptionLink to="/">Início</OptionLink>

          <Menu>
            <MenuHeader>
              <MenuHeaderTitle>Estudantes</MenuHeaderTitle>
            </MenuHeader>
            <MenuBody>
              <MenuOptionLink to="/vagas">Vagas de estágio</MenuOptionLink>
              <MenuOptionLink to="/login">Entrar</MenuOptionLink>
              <MenuOptionLink to="/cadastro-estudante">
                Cadastre-se
              </MenuOptionLink>
            </MenuBody>
          </Menu>

          <Menu>
            <MenuHeader>
              <MenuHeaderTitle>Empresas</MenuHeaderTitle>
            </MenuHeader>
            <MenuBody>
              <MenuOptionLink to="/login">Entrar</MenuOptionLink>
              <MenuOptionLink to="/cadastro-empresa">
                Cadastre-se
              </MenuOptionLink>
            </MenuBody>
          </Menu>

          <Menu>
            <MenuHeader>
              <MenuHeaderTitle>Escolas</MenuHeaderTitle>
            </MenuHeader>
            <MenuBody>
              <MenuOptionLink to="/login">Entrar</MenuOptionLink>
              <MenuOptionLink to="/cadastro-escola">Cadastre-se</MenuOptionLink>
            </MenuBody>
          </Menu>
        </HiddenContent>
      </HiddenContainer>
    </OuterContainer>
  );
}

export default withRouter(MobileMenu);
