import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSupervisores } from "@/hooks/supervisores";

import { Button } from "@/components/Common";
import { Row, Col } from "@/components/Grid";
import { Form, Input, InputMask, Scope } from "@/components/Form";
import { FormSubtitle } from "./styles";

import { createSchema } from "./schemas";

const initialData = {
  nome: "",
  cpf: "",
  telefone: "",
  usuario: {
    email: "",
    email_c: "",
  }
};
export default function SupervisorCreateForm({}) {

  const [formData] = useState(initialData);

  const {data: user} = useSelector(state => state.auth);
  const { createSupervisor, loading } = useSupervisores();

  const handleSubmit = (data, { resetForm }) => {
    const supervisor = {
      ...data,
      ...{empresa_id: user.empresa.id}
    };
    createSupervisor(supervisor).then(() => {
      resetForm();
    });
  };

  return (
    <Form onSubmit={handleSubmit} schema={createSchema} initialData={formData}>
      <Row>
        <Col>
          <Input label="Nome completo" name="nome" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input label="Cargo" name="cargo" />
        </Col>
      </Row>
      <Row hascols gaps="30">
        <Col>
          <InputMask type="cpf" label="CPF" name="cpf" />
        </Col>
        <Col>
          <InputMask type="telefone" label="Telefone" name="telefone" />
        </Col>
      </Row>
      <FormSubtitle>Dados de acesso:</FormSubtitle>
      <Scope path="usuario">
        <Row hascols gaps="30">
          <Col>
            <Input type="mail" label="E-mail" name="email" />
          </Col>
          <Col>
            <Input type="mail" label="Confirmar e-mail" name="email_c" />
          </Col>
        </Row>
      </Scope>
      <Row>
        <Button
          type="submit"
          variant="tangerine"
          loading={loading}
        >
          Cadastrar
        </Button>
      </Row>
    </Form>
  );
}
