import styled from "styled-components";
import { Card } from "@/components/Common";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  border-bottom: 1px solid #B9B7CC;
  h4 {
    color: #F78764;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  h2 {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 14px;
  }
  .breadcrumbs {
    color: #A09FAA;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 28px;
    span {
      color: #279CD9;
    }
  }
`;

export const SectionForm = styled(Card)`
  width: 100%;
  max-width: 820px;
  margin: 50px auto;
  *.button-container:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: -24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 110px;
  height: 110px;
  border: 2px solid #f1f1f1;
  border-radius: 55px;
  background-color: #fafafa;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const AvisoMensagem = styled.div`
  margin-bottom: 2rem;
`;
