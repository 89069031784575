import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";
import { Row, Col } from "@/components/Grid";
import { Input } from "@/components/Form";
import Paginator, { Loading } from "@/components/Paginator";
import EventEmitter from "@/event-emitter";
import { moeda } from "@/formatters";

import { Candidatos, TotalResult, BuscarVagas } from "./styles";

import { VagaCard } from "./styles";

function ListarVagasAnteriores({ history }) {
  const [vagas, setVagas] = useState([]);
  const [loadingVagas, setLoadingVagas] = useState(false);
  const [totalVagas, setTotalVagas] = useState(null);
  const [query, setQuery] = useState({ count: 9, filters: "finalizadas" });
  const paginatorRef = useRef();

  function handlePaginatorResetAndFetchData(event) {
    if (paginatorRef.current) {
      paginatorRef.current.resetAndFetchData();
    }
  }

  function handleOnFetchComplete(data) {
    setVagas(data.data);
    setTotalVagas(data.paginator.total);
  }

  function handleOnLoading(loading) {
    setLoadingVagas(loading);
  }

  function handleOpenVaga(vagaId) {
    history.push(`/vaga/${vagaId}`);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderVagas() {
    return (
      <>
        {totalVagas != null && (
          <TotalResult>{totalVagas} vagas cadastradas</TotalResult>
        )}
        <Loading loading={loadingVagas}>
          <Row hascols>
            {vagas.map(vaga => {
              const { candidatos_count, cursos } = vaga;

              let candidatosCountText;
              switch (candidatos_count) {
                case 0:
                  candidatosCountText = "Nenhum candidato";
                  break;
                case 1:
                  candidatosCountText = "1 candidato";
                  break;
                default:
                  candidatosCountText = `${candidatos_count} candidatos`;
                  break;
              }

              const cursosList = cursos.length ? (
                <ul className="cursos">
                  {cursos.map(curso => {
                    return (
                      <li>
                        {curso.nome} ({curso.nivel.nome})
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <></>
              );

              return (
                <Col xl="1/3" lg="1/2" md="1" sm="1" key={vaga.id}>
                  <VagaCard onClick={() => handleOpenVaga(vaga.id)}>
                    <div className="body">
                      <h4 className="tipo">Vaga finalizada</h4>
                      <h3 className="titulo">
                        Vaga Nº {vaga.id} - {vaga.titulo}
                      </h3>
                      <h5 className="remuneracao">
                        {moeda(Number(vaga.remuneracao))}
                        {Number(vaga.pcd) === 1 && (
                          <span> (Exclusivo para PCD)</span>
                        )}
                      </h5>
                      <h5 className="local">
                        {vaga.cidade.nome}/{vaga.cidade.estado.sigla}
                      </h5>
                      {cursosList}
                    </div>
                    <div className="footer">
                      <Candidatos>
                        <FaChevronCircleRight />
                        {candidatosCountText}
                      </Candidatos>
                    </div>
                  </VagaCard>
                </Col>
              );
            })}
          </Row>
        </Loading>
      </>
    );
  }

  useEffect(() => {
    EventEmitter.subscribe("vaga-added", event =>
      handlePaginatorResetAndFetchData(event)
    );
  }, []);

  return (
    <>
      <BuscarVagas>
        <Row hascols>
          <Col sm="1" md="1/2" lg="1/5">
            <Input
              type="number"
              name="vaga_id"
              label="Número da vaga"
              placeholder="número da vaga"
              onChange={handleOnChange}
            />
          </Col>
          <Col sm="1" md="1/2" lg="4/5">
            <Input
              name="buscar"
              label="Buscar vagas"
              placeholder="Digite o nome da vaga"
            />
          </Col>
        </Row>
      </BuscarVagas>
      {renderVagas()}
      <Paginator
        endpoint="vagas"
        onFetchComplete={handleOnFetchComplete}
        onLoading={handleOnLoading}
        query={query}
        ref={paginatorRef}
      />
    </>
  );
}

export default withRouter(ListarVagasAnteriores);
