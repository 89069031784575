import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "@rocketseat/unform";
import { InputContainer } from "../styles";

export default function InputMask({
  type,
  label,
  placeholder,
  name,
  rounded,
  onChange,
  value,
  ...rest
}) {
  const _label = label !== undefined ? label : undefined;
  const _placeholder = placeholder !== undefined ? placeholder : undefined;
  const _type = type !== undefined ? type : "telefone";

  let inputMask = "";
  let formatChars = { "9": "[0-9]", a: "[A-Z, a-z]", "*": "[A-Z, a-z, 0-9]" };

  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [mask, setMask] = useState(defaultValue || "");

  switch (_type) {
    case "telefone":
      let rawTelefone = mask.replace(/[^\d]+/g, "");
      if (rawTelefone.length > 10) {
        inputMask = "(99) 99999-9999";
      } else {
        inputMask = "(99) 9999-9999?";
      }
      formatChars = { "9": "[0-9]", "?": "[0-9]" };
      break;
    case "cpf":
      inputMask = "999.999.999-99";
      formatChars = { "9": "[0-9]", "?": "[0-9]" };
      break;
    case "cnpj":
      inputMask = "99.999.999/9999-99";
      formatChars = { "9": "[0-9]", "?": "[0-9]" };
      break;
    case "cep":
      inputMask = "99999-999";
      formatChars = { "9": "[0-9]", "?": "[0-9]" };
      break;
  }

  // if (value !== undefined) {
  //   setMask(value);
  // }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "props.value",
      clearValue: inputMaskRef => {
        inputMaskRef.setInputValue(defaultValue);
      },
    });

    if (value !== undefined) {
      setMask(value);
    }
  }, [ref.current, fieldName, value]);

  function handleMask(event) {
    const { value } = event.target;
    // executando os eventos do pai via callback
    if (onChange !== undefined) {
      event.target.value = value;
      onChange(event);
    }
    return setMask(value);
  }

  return (
    <InputContainer label={_label} rounded={rounded !== undefined}>
      {_label && <label>{label}</label>}
      <ReactInputMask
        type="text"
        name={fieldName}
        placeholder={_placeholder}
        value={mask}
        mask={inputMask}
        formatChars={formatChars}
        maskChar=""
        onChange={e => handleMask(e)}
        ref={ref}
        {...rest}
      />
      {error && <span>{error}</span>}
    </InputContainer>
  );
}
