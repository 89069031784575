import styled from "styled-components";

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const SmallCircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px #b9b7cc solid;
  color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
    border-color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  }
`;

export const Container = styled.div.attrs({
  className: "dados-pessoais",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #b9b7cc;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 40px;
  }
`;

export const ListITem = styled.div.attrs({
  className: "list-item",
})`
  font-size: 18px;
  position: relative;
  margin-bottom: 32px;
  width: calc(100% / 6);

  @media screen and (max-width: 1400px) {
    width: 25%;
  }

  @media screen and (max-width: 1100px) {
    width: 50%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  h2 {
    color: #423b8d;
    font-size: 18px;
    font-weight: 600;
  }
  div.menu {
    opacity: 0;
    color: #707070;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    div.menu {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const ListItemActions = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  button:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const MenuContainer = styled.div.attrs({
  className: "menu-container",
})`
  position: absolute;
  top: 0;
  right: 46px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 4px;
  min-width: 150px;
  text-align: right;

  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
  .option {
    color: #423b8d;
    /* font-weight: bold; */
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    &-danger {
      color: #f78764;
    }
  }
`;
