import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaListUl } from "react-icons/fa";
import queryString from "query-string";

import { EmpresaProvider } from "@/context/empresas/empresa";
import { useEmpresa } from "@/hooks/empresas";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { Input, Select } from "@/components/Form";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { CardEntrevista } from "@/components/Empresa/Vagas";
import api, { makeOptions } from "@/services/api";
import { Loading } from "@/components/Paginator";
import { useThrottle } from "@/hooks";
import { Sidebar, SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";
import ListEntrevistasPassadas from "@/components/Empresa/Entrevistas/ListEntrevistasPassadas";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
  PainelPrincipalTopo,
  ContentCards,
  GroupCards,
  ButtonVerLista,
  Icon,
  Label,
  Filtros,
  NotFound,
} from "./styles";

function _Entrevistas({ history }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchEmpresa, empresa } = useEmpresa();
  const [agrupamentos, setAgrupamentos] = useState([]);
  const [loadingAgrupamentos, setLoadingAgrupamentos] = useState(false);
  const [vagasOptions, setVagasOptions] = useState([]);
  const [query, setQuery] = useState({});

  const tQuery = useThrottle(query, 500);

  useEffect(() => {
    fetchEntrevistasAgrupamento();
  }, [tQuery]);

  useEffect(() => {
    fetchEmpresa(user.empresa.id);
  }, []);

  async function fetchEntrevistasAgrupamento() {
    setLoadingAgrupamentos(true);
    const _query = queryString.stringify(tQuery);
    const endpoint = `vagas/agrupamento-entrevistas?${_query}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    setLoadingAgrupamentos(false);
    if (response.success) {
      setAgrupamentos(response.data);
      // somente alimentar as options do select da primeira vez
      if (vagasOptions.length === 0) {
        setVagasOptions(state => {
          const options = response.data.map(vaga => {
            return { id: vaga.id, title: vaga.titulo };
          });
          options.unshift({ id: "", title: "Todos" });
          return options;
        });
      }
    }
  }

  function renderEntrevistasAgrupamento() {
    return agrupamentos.map(vaga => {
      const { entrevistas } = vaga;

      const list = entrevistas.length ? (
        entrevistas.map(entrevista => {
          return (
            <CardEntrevista
              data={entrevista}
              key={entrevista.id}
              onClick={() => {
                history.push(
                  `/vaga-estudante/${vaga.id}/${entrevista.aluno.id}`
                );
              }}
            />
          );
        })
      ) : (
        <NotFound>
          Você ainda não agendou nenhuma entrevista para esta vaga.
        </NotFound>
      );

      return (
        <ContentCards>
          <h2>
            {vaga.titulo} ({vaga.entrevistas_count})
          </h2>
          <GroupCards>
            {list}
            {vaga.entrevistas_count > 0 && (
              <ButtonVerLista onClick={() => {
                history.push(`/vaga-entrevistas/${vaga.id}`);
              }}>
                <Icon>
                  <FaListUl color="#fff" size={16} />
                </Icon>
                <Label>Ver lista detalhada</Label>
              </ButtonVerLista>
            )}
          </GroupCards>
        </ContentCards>
      );
    });
  }

  function renderFiltros() {
    return (
      <Filtros>
        <Row hascols>
          <Col>
            <Input
              name="aluno_nome"
              onChange={handleOnChange}
              label="Nome do candidato:"
            />
          </Col>
          <Col>
            <Select
              name="vaga_id"
              onChange={handleOnChange}
              label="Vaga:"
              options={vagasOptions}
            />
          </Col>
        </Row>
      </Filtros>
    );
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {empresa.escola && <SubmenuEscola type="entrevistas" /> || <SubmenuEmpresa type="entrevistas" />}
              </MenuInterno>
              <PainelPrincipal>
                <PainelPrincipalTopo>
                  <h1>Entrevistas</h1>
                </PainelPrincipalTopo>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Próximas entrevistas</Tab>
                    <Tab>Entrevistas anteriores</Tab>
                  </TabList>
                  <TabPanel>
                    {renderFiltros()}
                    <Loading loading={loadingAgrupamentos}>
                      {renderEntrevistasAgrupamento()}
                    </Loading>
                  </TabPanel>
                  <TabPanel>
                    <ContentCards>
                      <ListEntrevistasPassadas />
                    </ContentCards>
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Entrevistas({ history }) {
  return (
    <EmpresaProvider>
      <_Entrevistas history={history} />
    </EmpresaProvider>
  );
}
