import styled from "styled-components";

import iconChevronDownLight from "@/assets/images/icons/chevron-down-light.png";

export const UserContainer = styled.div.attrs({
  className: "navbar-user",
})`
  /* background-color: #f00; */
  border-left: 1px solid #403754;
  padding-left: 20px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 800px){
    div.dropdown-content {
      right: -30px;
    }
  }
`;

export const Avatar = styled.div`
  min-width: 28px;
  min-height: 28px;
  width: 28px;
  height: 28px;
  border-radius: 17px;
  background-color: #fff;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
`;

export const AdminBadge = styled.div`
  svg {
    color: #f1c40f;
  }
  margin-right: 20px;
`;

export const Nome = styled.h4`
  color: #FFFFFF;
  margin-right: 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const MenuHandler = styled.div.attrs({
  className: "menu-handler",
})`
  margin-left: 10px;
  width: 28px;
  height: 28px;
  background-image: url(${iconChevronDownLight});
  background-position: center;
  background-repeat: no-repeat;
`;

export const MenuContainer = styled.div.attrs({
  className: "menu-container",
})`
  pointer-events: all;
  background-color: #ffffff;
  padding: 20px 17px;
  border-radius: 0px 0px 4px 4px;
  min-width: 180px;
  text-align: right;
  margin-top: 42px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  ul {
    margin: 0px;
    li {
      list-style: none;
      padding: 10px 0;
      *.menu-option {
        color: #423b8d;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
      }
    }
    li:not(:last-child) {
      border-bottom: 1px solid #b9b7cc;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
`;
