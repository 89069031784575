import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAluno } from "@/hooks/alunos";
import { Button } from "@/components/Common";

import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalFooter } from "./styles";

export default function ModalDeleteExperiencia({ onClose, id }) {
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  async function deleteExperiencia() {
    setLoading(true);
    const endpoint = `alunos/curriculos/experiencias-profissionais/${id}`;
    const { data: response } = await api.delete(
      endpoint,
      makeOptions(user)
    );
    setLoading(false);
    if (response.success) {
      toastify("Experiência profissional removida com sucesso");
      fetchAluno(aluno.id);
      onClose();
    }
  }

  return (
    <ModalContent>
      <h2>Remover esta experiência profissional?</h2>
      <ModalFooter>
        <Button variant="cancel" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="tangerine"
          onClick={deleteExperiencia}
          loading={loading || undefined}
        >
          Remover
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
