import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { AssinanteContext } from "@/context/assinantes/assinante";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import EventEmitter from "@/event-emitter";

const useAssinante = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(AssinanteContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchAssinante(assinanteId) {
    _setLoading(true);
    const endpoint = `assinantes/${assinanteId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function deleteAssinante(assinanteId) {
    _setLoading(true);
    const endpoint = `assinantes/${assinanteId}`;
    const { data: response } = await api.delete(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      EventEmitter.dispatch("assinante-added", null);
      toastify("Assinante alterado com sucesso");
      _setData(response.data);
    } else {
      toastify(response.message, "error");
    }
  }

  async function createAssinante(data) {
    _setLoading(true);
    const endpoint = `assinantes`;
    const { data: response } = await api.post(
      endpoint,
      data,
      makeOptions(user)
    );
    _setLoading(false);
    if (response.success) {
      EventEmitter.dispatch("assinante-added", null);
      toastify("Assinante criado com sucesso");
      _setData(response.data);
    }
  }

  async function updateAssinante(assinanteId, data, onSuccess = false, onError =  false) {
    _setLoading(true);
    const endpoint = `assinantes/${assinanteId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      EventEmitter.dispatch("assinante-added", null);
      toastify("Assinante alterado com sucesso");
      _setData(response.data);
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError();
    }
  }

  return {
    fetchAssinante,
    createAssinante,
    updateAssinante,
    deleteAssinante,
    assinante: state.data,
    loading: state.loading,
  };
};

export { useAssinante };
