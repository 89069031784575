import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FiMapPin, FiBriefcase, FiEdit2 } from "react-icons/fi";
import { useEmpresa } from "@/hooks/empresas";
import { telefone, cep } from "@/formatters";
import { Modal } from "@/components/Common";
import { Row, Col } from "@/components/Grid";
import { ModalEditPerfil } from "@/components/Empresa";
import { SobreContainer } from "./styles";

export default function Sobre() {
  const { data: user } = useSelector(state => state.auth);
  const { empresa } = useEmpresa();
  const [modalEditPerfilOpen, setModalEditPerfilOpen] = useState(false);

  function renderEndereco() {
    const { endereco } = empresa;
    return (
      <>
        <h4>Endereço</h4>
        <p>
          {endereco.logradouro}, {endereco.numero}, {endereco.bairro}
        </p>
        <p>
          {endereco.cidade.nome}/{endereco.cidade.estado.sigla}
        </p>
        <p>{cep(endereco.cep)}</p>
      </>
    );
  }

  function renderContato() {
    return (
      <>
        <h4>Contato</h4>
        <p>{telefone(empresa.responsavel_legal_telefone)}</p>
      </>
    );
  }

  return (
    <SobreContainer>
      {empresa && (
        <>
          <h2 className="nome-exibicao">
            {empresa.nome_exibicao}
            {user.tipo === "funcionario" && (
              <button onClick={() => setModalEditPerfilOpen(true)}>
                <FiEdit2 />
              </button>
            )}
          </h2>
          <div className="info">
            <p>
              <FiMapPin />
              {empresa.endereco.cidade.nome} /{" "}
              {empresa.endereco.cidade.estado.sigla}
            </p>
            <p>
              <FiBriefcase />
              {empresa.atuacao}
            </p>
          </div>
          <div className="apresentacao">
            <h5>Apresentação:</h5>
            <p>{empresa.apresentacao}</p>
          </div>
          <div className="contato">
            <Row hascols>
              <Col md="1/3">{renderEndereco()}</Col>
              <Col md="1/3">{renderContato()}</Col>
            </Row>
          </div>
          <Modal
            open={modalEditPerfilOpen}
            onClose={() => setModalEditPerfilOpen(false)}
          >
            <ModalEditPerfil onClose={() => setModalEditPerfilOpen(false)} />
          </Modal>
        </>
      )}
    </SobreContainer>
  );
}
