import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  AdminMenuWrapper,
  MainMenu,
  CloseButton,
  MenuHandler,
  MenuLabel,
  ActiveIndicator,
  SubMenu,
  SubMenuBackdrop,
  SubMenuSections,
  Section,
  SectionTitle,
  SectionOption,
} from "./styles";

function AdminMenu({ history }) {
  const [activeMainMenu, setActiveMainMenu] = useState(null);
  const subMenuRef = useRef();

  function handleClickOutside(event) {
    const { target } = event;
    if (subMenuRef.current && !subMenuRef.current.contains(target)) {
      setActiveMainMenu(null);
    }
  }

  const menus = [
    {
      label: "Contratos",
      sections: [
        {
          title: "Novos Contratos",
          options: [
            {
              label: "Pendente aprovação do Integrador.App",
              onClick: () => handleNavigate("/tces/pendente-integrador"),
            },
            {
              label: "Pendente aceite da empresa concedente",
              onClick: () => handleNavigate("/tces/pendente-contratante"),
            },
            {
              label: "Pendente aceite do estudante",
              onClick: () => handleNavigate("/tces/pendente-contratado"),
            },
            {
              label: "Pendente aceite da escola",
              onClick: () => handleNavigate("/tces/pendente-contratado-escola"),
            },
            {
              label: "Todos",
              onClick: () => handleNavigate("/tces/pendente"),
            },
          ],
        },
        {
          title: "Contratos Efetivados",
          options: [
            {
              label: "Ativos",
              onClick: () => handleNavigate("/tces/ativo"),
            },
            {
              label: "Encerrados",
              onClick: () => handleNavigate("/tces/encerrado"),
            },
            {
              label: "Todos",
              onClick: () => handleNavigate("/tces/efetivado"),
            },
            {
              label: "Com solicitação de desligamento",
              onClick: () => handleNavigate("/tces/desligamento-pendente"),
            },
          ],
        },
        {
          title: "Contratos Recusados",
          options: [
            {
              label: "Recusados pelo Integrador.App",
              onClick: () => handleNavigate("/tces/recusado-integrador"),
            },
            {
              label: "Recusados pela empresa concedente",
              onClick: () => handleNavigate("/tces/recusado-contratante"),
            },
            {
              label: "Recusados pelo estudante",
              onClick: () => handleNavigate("/tces/recusado-contratado"),
            },
            {
              label: "Recusados pela escola",
              onClick: () => handleNavigate("/tces/recusado-contratado-escola"),
            },
            {
              label: "Todos",
              onClick: () => handleNavigate("/tces/recusado"),
            },
          ],
        },
      ],
    },
    {
      label: "Gerenciar Estudantes",
      sections: [
        {
          title: "Estudantes",
          options: [
            {
              label: "Todos os estudantes",
              onClick: () => handleNavigate("/alunos/todos"),
            },
            {
              label: "Com contratos ativos",
              onClick: () => handleNavigate("/alunos/comTceAtivo"),
            },
            {
              label: "Sem contratos ativos",
              onClick: () => handleNavigate("/alunos/semTceAtivo"),
            },
            {
              label: "Com avaliações de contratos pendentes",
              onClick: () => handleNavigate("/alunos/comAvaliacoesPendentes"),
            },
          ],
        },
        {
          title: "Contas",
          options: [
            {
              label: "Cadastrar novo estudante",
              onClick: () => handleNavigate("/admin/cadastro-estudante"),
            },
          ],
        },
      ],
    },
    {
      label: "Gerenciar Empresas",
      sections: [
        {
          title: "Empresas",
          options: [
            {
              label: "Todas as empresas",
              onClick: () => handleNavigate("/empresas/todas"),
            },
            {
              label: "Com avaliações de contratos pendentes",
              onClick: () => handleNavigate("/empresas/comAvaliacoesPendentes"),
            },
          ],
        },
        {
          title: "Contas",
          options: [
            {
              label: "Cadastrar nova empresa",
              onClick: () => handleNavigate("/admin/cadastro-empresa"),
            },
          ],
        },
      ],
    },
    {
      label: "Gerenciar Escolas",
      sections: [
        {
          title: "Escolas",
          options: [
            {
              label: "Todas as escolas",
              onClick: () => handleNavigate("/escolas/todas"),
            },
            {
              label: "Sugestões de escolas",
              onClick: () => handleNavigate("/sugestoes-escolas"),
            },
          ],
        },
        {
          title: "Contas",
          options: [
            {
              label: "Cadastrar nova escola",
              onClick: () => handleNavigate("/admin/cadastro-escola"),
            },
          ],
        },
      ],
    },
    {
      label: "Gerenciar Franqueados",
      sections: [
        {
          title: "Franqueados",
          options: [
            {
              label: "Todas os franqueados",
              onClick: () => handleNavigate("/franqueados/todos"),
            },
          ],
        },
        {
          title: "Contas",
          options: [
            {
              label: "Cadastrar novo franqueado",
              onClick: () => handleNavigate("/admin/cadastro-franqueado"),
            },
          ],
        },
      ],
    },
  ];

  function handleNavigate(pathname) {
    setActiveMainMenu(null);
    history.push(pathname);
  }

  function renderMainMenu() {
    return (
      <MainMenu>
        <MenuHandler>
          <MenuLabel
            onClick={() => handleNavigate("/admin/indicadores/totais")}
          >
            Indicadores
          </MenuLabel>
        </MenuHandler>

        <MenuHandler>
          <MenuLabel onClick={() => handleNavigate("/admin/vagas")}>
            Fila de Vagas
          </MenuLabel>
        </MenuHandler>

        {menus.map((menu, i) => {
          return (
            <MenuHandler key={i}>
              <MenuLabel onClick={() => setActiveMainMenu(menu.label)}>
                {menu.label}
              </MenuLabel>
              {activeMainMenu == menu.label && <ActiveIndicator />}
            </MenuHandler>
          );
        })}
      </MainMenu>
    );
  }

  function renderSubMenu() {
    if (!activeMainMenu) return <></>;

    const sections = menus.filter(menu => menu.label === activeMainMenu)[0]
      .sections;

    return (
      <SubMenu ref={subMenuRef}>
        <CloseButton onClick={() => setActiveMainMenu(null)}>
          &times;
        </CloseButton>
        <SubMenuSections>
          {sections.map((section, i) => {
            const { options, title } = section;
            const sectionOptions = options.map((option, j) => {
              return (
                <SectionOption
                  key={j}
                  onClick={() => {
                    option.onClick();
                  }}
                >
                  {option.label}
                </SectionOption>
              );
            });

            return (
              <Section key={i}>
                {title && <SectionTitle>{title}</SectionTitle>}
                <ul>{sectionOptions}</ul>
              </Section>
            );
          })}
        </SubMenuSections>
      </SubMenu>
    );
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <AdminMenuWrapper>
      {renderMainMenu()}
      {renderSubMenu()}
    </AdminMenuWrapper>
  );
}

export default withRouter(AdminMenu);
