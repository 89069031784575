import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/Common";

import bgHero from "@/assets/images/hero-0.png";

import {
  Container,
  ContentContainer,
  Content,
  Block,
  Title,
  Intro,
  Links,
  HeroSideImage,
  HeroMobileImage,
} from "./styles";

export default function Hero() {
  return (
    <Container>
      <ContentContainer>
        <Content>
          <Block>
            <Title>
              Encontre um estágio <br /> perto de você
            </Title>
            <Intro>
              Facilitamos a conexão entre estudantes, empresas e escolas para
              trazer a você as melhores oportunidades de estágio para iniciar
              sua carreira.
            </Intro>
            <Button to="/cadastre-se" variant="integradorBlue">
              Cadastre-se
            </Button>
          </Block>
          <Block>
            <Links>
              Quer contratar um estagiário?{" "}
              <Link to="/cadastro-empresa">
                Cadastre-se e aproveite os benefícios para empresas
              </Link>{" "}
              <br />É responsável por uma escola?{" "}
              <Link to="/cadastro-escola">
                Cadastre-se e ofereça vagas de estágio para seus estudantes
              </Link>
            </Links>
          </Block>
        </Content>
      </ContentContainer>

      <HeroSideImage src={bgHero} />
      <HeroMobileImage />
    </Container>
  );
}
