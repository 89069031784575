import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { differenceInYears, isAfter, isValid } from "date-fns";

import {
  disponibilidade as optionsDisponibilidade,
  grau_parentesco as optionsParentesco,
} from "@/data";

import ListPaisesActions from "@/store/ducks/paises/list";
import { Button } from "@/components/Common";
import { Form, Input, InputMask, Select } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter, AvisoMensagem } from "./styles";
import { useAluno } from "@/hooks/alunos";

export default function ModalEditSobre({ onClose }) {
  const dispatch = useDispatch();
  const { data: optionsPaises } = useSelector(state => state.listPaises);

  const { aluno, loading, updateAluno } = useAluno();
  const [formData, setFormData] = useState(null);
  const [showResponsavelArea, setShowResponsavelArea] = useState(false);
  const [showAviso16Anos, setShowAviso16Anos] = useState(false);
  const [showPcdCampo, setShowPcdCampo] = useState(false);

  const optionsSexo = [
    { id: "F", title: "Feminino" },
    { id: "M", title: "Masculino" },
  ];

  const schema = Yup.object().shape({
    nome: Yup.string().required("Obrigatório"),
    cpf: Yup.string().required("Obrigatório"),
    pais_id: Yup.string().required("Obrigatório"),
    nacionalidade: Yup.string().required("Obrigatório"),
    sexo: Yup.string().required("Obrigatório"),
    data_nascimento: Yup.date()
      .test("teste-idade-minima", "Idade mínima é de 16 anos", function(value) {
        return differenceInYears(new Date(), new Date(value)) >= 16;
      })
      .typeError("Obrigatório"),
    responsavel_nome: Yup.string().when(
      "data_nascimento",
      (data_nascimento, schema) => {
        const dataNascimento = new Date(data_nascimento);
        const idade = differenceInYears(new Date(), dataNascimento);
        if (idade < 18) {
          return schema.required("Obrigatório");
        }
      }
    ),
    responsavel_grau_parentesco: Yup.string().when(
      "data_nascimento",
      (data_nascimento, schema) => {
        const dataNascimento = new Date(data_nascimento);
        const idade = differenceInYears(new Date(), dataNascimento);
        if (idade < 18) {
          return schema.required("Obrigatório");
        }
      }
    ),
    responsavel_cpf: Yup.string().when(
      "data_nascimento",
      (data_nascimento, schema) => {
        const dataNascimento = new Date(data_nascimento);
        const idade = differenceInYears(new Date(), dataNascimento);
        if (idade < 18) {
          return schema.required("Obrigatório");
        }
      }
    ),
    responsavel_telefone: Yup.string().when(
      "data_nascimento",
      (data_nascimento, schema) => {
        const dataNascimento = new Date(data_nascimento);
        const idade = differenceInYears(new Date(), dataNascimento);
        if (idade < 18) {
          return schema.required("Obrigatório");
        }
      }
    ),
    responsavel_email: Yup.string().when(
      "data_nascimento",
      (data_nascimento, schema) => {
        const dataNascimento = new Date(data_nascimento);
        const idade = differenceInYears(new Date(), dataNascimento);
        if (idade < 18) {
          return schema.required("Obrigatório");
        }
      }
    ),
    disponibilidade: Yup.string().required("Obrigatório"),
    possui_necessidade: Yup.string(),
    pcd: Yup.string(),
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleDataNascimentoChange(event) {
    event.persist();
    const { value } = event.target;
    const dataNascimento = new Date(value);
    setShowAviso16Anos(state => {
      return differenceInYears(new Date(), dataNascimento) < 16;
    });
  }

  function handleSubmit(data) {
    data.pcd = data.possui_necessidade === "s" ? data.pcd : "";
    // caso não seja menor de 18, forçar os campos de responsável para ficarem em branco
    // pois assim a api os transforma em null
    if (!showResponsavelArea) {
      Object.assign(data,  {
        responsavel_nome: "",
          responsavel_cpf: "",
          responsavel_grau_parentesco: "",
          responsavel_email: "",
          responsavel_telefone: ""
      });
    }
    updateAluno(aluno.id, data);
  }

  const setShowResponsavelAreaCB = useCallback(() => {
    if (formData && formData.data_nascimento) {
      const dataNascimento = new Date(formData.data_nascimento);
      const menor18 = differenceInYears(new Date(), dataNascimento) < 18;
      setShowResponsavelArea(menor18);
    }
  }, [formData, setShowResponsavelArea]);

  const setShowPcdCampoCB = useCallback(() => {
    if (formData && formData.possui_necessidade) {
      const show = formData.possui_necessidade === "s";
      setShowPcdCampo(show);
    }
  }, [formData, setShowPcdCampo]);

  useEffect(() => {
    setShowResponsavelAreaCB();
  }, [setShowResponsavelAreaCB]);

  useEffect(() => {
    setShowPcdCampoCB();
  }, [setShowPcdCampoCB]);

  useEffect(() => {
    dispatch(ListPaisesActions.listPaisesRequest());
  }, []);

  useEffect(() => {
    if (!loading) onClose();
  }, [loading]);

  useEffect(() => {
    setFormData(() => {
      const {
        nome,
        cpf,
        pais_id,
        sexo,
        nacionalidade,
        data_nascimento,
        responsavel_nome,
        responsavel_grau_parentesco,
        responsavel_cpf,
        responsavel_telefone,
        responsavel_email,
        disponibilidade,
        pcd,
      } = aluno;

      return {
        nome,
        cpf,
        pais_id,
        sexo,
        nacionalidade,
        data_nascimento,
        responsavel_nome,
        responsavel_grau_parentesco,
        responsavel_cpf,
        responsavel_telefone,
        responsavel_email,
        disponibilidade,
        pcd,
        possui_necessidade: pcd !== null ? "s" : "n",
      };
    });
  }, [aluno]);

  return (
    <ModalContent>
      {formData && (
        <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  label="Nome completo"
                  name="nome"
                  value={formData.nome}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <InputMask
                  type="cpf"
                  label="CPF"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Select
                  label="Sexo"
                  name="sexo"
                  options={optionsSexo}
                  value={formData.sexo}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Select
                  label="Pais de origem"
                  name="pais_id"
                  options={optionsPaises}
                  value={formData.pais_id || undefined}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Input
                  name="nacionalidade"
                  label="Nacionalidade"
                  value={formData.nacionalidade}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  type="date"
                  max="2099-01-01"
                  label="Data de nascimento"
                  name="data_nascimento"
                  value={formData.data_nascimento}
                  onChange={e => {
                    handleDataNascimentoChange(e);
                    handleOnChange(e);
                  }}
                />
              </Col>
              <Col>
                <Select
                  name="disponibilidade"
                  label="Disponibilidade para o estágio"
                  options={optionsDisponibilidade}
                  value={formData.disponibilidade || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            {showAviso16Anos && (
              <AvisoMensagem>
                <h4>
                  De acordo com a lei 11.788/08 é necessário ter no mínimo 16
                  anos para ser estagiário.
                </h4>
              </AvisoMensagem>
            )}

            {showResponsavelArea && (
              <>
                <Row>
                  <Col>
                    <Input
                      label="Nome do responsável"
                      name="responsavel_nome"
                      value={formData.responsavel_nome || ""}
                      onChange={handleOnChange}
                    />
                    <small>Obrigatório caso seja menor</small>
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Select
                      label="Grau de parentesco do responsável"
                      name="responsavel_grau_parentesco"
                      options={optionsParentesco}
                      value={formData.responsavel_grau_parentesco || ""}
                      onChange={handleOnChange}
                    />
                    <small>Obrigatório caso seja menor</small>
                  </Col>
                  <Col>
                    <InputMask
                      type="cpf"
                      label="CPF do responsável"
                      name="responsavel_cpf"
                      value={formData.responsavel_cpf || ""}
                      onChange={handleOnChange}
                    />
                    <small>Obrigatório caso seja menor</small>
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <InputMask
                      type="telefone"
                      label="Telefone do responsável"
                      name="responsavel_telefone"
                      value={formData.responsavel_telefone || ""}
                      onChange={handleOnChange}
                    />
                    <small>Obrigatório caso seja menor</small>
                  </Col>
                  <Col>
                    <Input
                      label="E-mail do responsável"
                      name="responsavel_email"
                      type="mail"
                      value={formData.responsavel_email || ""}
                      onChange={handleOnChange}
                    />
                    <small>Obrigatório caso seja menor</small>
                  </Col>
                </Row>
              </>
            )}

            <Row hascols>
              <Col>
                <Select
                  label="Possui alguma necessidade especial?"
                  options={[
                    { id: "n", title: "Não" },
                    { id: "s", title: "Sim" },
                  ]}
                  name="possui_necessidade"
                  value={formData.possui_necessidade}
                  onChange={handleOnChange}
                />
              </Col>
              {showPcdCampo && (
                <Col>
                  <Input
                    label="Código PCD"
                    name="pcd"
                    value={formData.pcd}
                    onChange={handleOnChange}
                  />
                  <small>Apenas para pessoas com deficiência</small>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
