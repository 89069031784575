import styled from "styled-components";

import iconChevronDownDark from "@/assets/images/icons/chevron-down-dark.png";
import iconSearchDark from "@/assets/images/icons/search-dark.png";
import iconPinDark from "@/assets/images/icons/pin-dark.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  label,
  input,
  textarea,
  select {
    display: block;
    font-size: 16px;
    text-align: left;
    width: 100%;
  }

  select {
    font-size: 14px;
  }

  input,
  textarea,
  select {
    padding: 14px 16px;
    border: 1px solid #a1a0a8;
    border-radius: ${props => ({ rounded }) => {
      return rounded ? "24px" : "4px";
    }};
    min-width: 50px;
    height: 52px;
    &:not([type="mail"]):not([type="url"]):not([type="password"]) {
      text-transform: uppercase;
    }
    &[type="mail"], &[type="url"] {
      text-transform: lowercase;
    }
  }

  textarea {
    height: 200px;
  }

  label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  span {
    display: block;
    position: absolute;
    right: 0px;
    color: #DB4C81;
  }

`;

export const InputContainer = styled(Container).attrs({
  className: "input-container"
})``;

export const LengthInfo = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
`;

export const SelectContainer = styled(Container)`
  select {
    padding: 15px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-position: center right 20px;
    background-repeat: no-repeat;
    outline: none;
    background-image: ${() => {
      const _icon = theme.icons["chevron-down-dark"];
      return `url(${_icon})`;
    }};
  }
  > div.css-0 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 31px;
    right: 1px;
    background-color: #FFF;
    border-radius: 25px;
  }
`;

export const CheckboxContainer = styled.div`

`;

export const theme = {
  icons: {
    "search-dark": iconSearchDark,
    "chevron-down-dark": iconChevronDownDark,
    "pin-dark": iconPinDark,
  },
};
