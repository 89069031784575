import React, { useState, useRef } from "react";
import { FaFacebook, FaTwitter, FaCopy, FaWhatsapp } from "react-icons/fa";
import toastify from "@/services/toastify";

import { ShareContainer, ShareButtons, ShareButton, ClipboardButton, ClipboardInput } from "./styles";

export default function Share({ url, children }) {
  const [showButtons, setShowButtons] = useState(false);
  const integradorUrl = "https://www.integrador.app/";
  const shareFacebook = `https://www.facebook.com/sharer/sharer.php?u=${integradorUrl}${url}`;
  const shareTwitter = `http://twitter.com/share?url=${integradorUrl}${url}`;
  const shareWhatsApp = `https://api.whatsapp.com/send?text=${integradorUrl}${url}`;

  const clipboardInputRef = useRef();

  function toggleShow() {
    setShowButtons(state => {
      return !state;
    });
  }

  function handleCopy() {
    if (clipboardInputRef.current) {
      clipboardInputRef.current.focus();
      clipboardInputRef.current.select(); // desktop
      clipboardInputRef.current.setSelectionRange(0, 99999); // mobile
      document.execCommand("copy");
      toastify("Url copiada para sua área de transferência");
    }
  }

  return (
    <ShareContainer onClick={toggleShow}>
      {children}
      <ShareButtons show={showButtons || undefined}>
        <ShareButton className="facebook" href={shareFacebook} target="_blank">
          <FaFacebook size={24} />
        </ShareButton>
        <ShareButton className="twitter" href={shareTwitter} target="_blank">
          <FaTwitter size={24} />
        </ShareButton>
        <ShareButton className="whatsapp" href={shareWhatsApp} target="_blank">
          <FaWhatsapp size={24} />
        </ShareButton>
        <ClipboardButton>
          <FaCopy size={24} onClick={handleCopy} />
          <ClipboardInput value={`${integradorUrl}${url}`} ref={clipboardInputRef} />
        </ClipboardButton>
      </ShareButtons>
    </ShareContainer>
  );
}

