import React, { useState, useEffect, useCallback } from "react";
import { FullPageLoader } from "@/components/Common";

import TCEContent from "./TCE";
import api from "@/services/api";

export default function AceitarTCE({ match }) {
  const { tceId } = match.params;
  const [tce, setTce] = useState(null);

  const fetchTceCB = useCallback(async () => {
    if (!tceId) return null;
    const endpoint = `convidado-tce/${tceId}`;
    const { data: response } = await api.get(endpoint);
    if (response.success) {
      setTce(() => {
        return response.data;
      });
    }
  }, [tceId, api, setTce]);

  useEffect(() => {
    fetchTceCB();
  }, [fetchTceCB]);

  return (
    <>
      {!tce && <FullPageLoader />}
      {tce && <TCEContent tce={tce} />}
    </>
  );
}
