import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, parse, compareAsc, compareDesc } from "date-fns";
import update from "immutability-helper";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { VagaProvider } from "@/context/vagas/vaga";
import { EntrevistaProvider } from "@/context/entrevistas/entrevista";
import { useEmpresa } from "@/hooks/empresas";
import { useVaga } from "@/hooks/vagas";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Modal } from "@/components/Common";
import ListEntrevista from "@/components/Empresa/Entrevistas/ListEntrevista";
import ModalEditEntrevista from "@/components/Empresa/Entrevistas/ModalEditEntrevista";
import Paginator, { Loading } from "@/components/Paginator";
import EventEmitter from "@/event-emitter";
import { Sidebar, SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
  PainelPrincipalTopo,
  Breadcrumbs,
  TituloVaga,
  CidadeVaga,
  Filtros,
  ContentCards,
} from "./styles";

function _VagaEntrevistas({ match, history }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchEmpresa, empresa, loading } = useEmpresa();
  const { fetchVaga, vaga, loading: loadingVaga } = useVaga();
  const [entrevistas, setEntrevistas] = useState([]);
  const [entrevistasLoading, setEntrevistasLoading] = useState(false);
  const [modalEditEntrevista, setModalEditEntrevista] = useState(false);
  const [entrevistaToEdit, setEntrevistaToEdit] = useState(null);

  const [query, setQuery] = useState({
    count: 100, // trazer um monte
    vaga_id: match.params.vagaId,
  });

  useEffect(() => {
    const { vagaId } = match.params;
    fetchEmpresa(user.empresa.id);
    fetchVaga(vagaId);
  }, []);

  useEffect(() => {
    EventEmitter.unsubscribe("entrevista-updated");
    EventEmitter.subscribe("entrevista-updated", handleEntrevistaUpdated);
  }, [entrevistas]);

  function handleEntrevistaUpdated(entrevista) {
    const { id, data, hora } = entrevista;

    // etapa 1 atualizar o elemento do array cujo id seja igual ao id da entrevista editada
    const index = entrevistas.findIndex(e => e.id == id);

    let newEntrevistas = update(entrevistas, {
      [index]: {
        data: { $set: format(data, "yyyy-MM-dd") },
        hora: { $set: hora },
      },
    }).sort((prev, next) => {
      const prevDataHora = parse(
        `${prev.data} ${prev.hora}`,
        "yyyy-MM-DD H:m",
        new Date()
      );
      const nextDataHora = parse(
        `${next.data} ${next.hora}`,
        "yyyy-MM-DD H:m",
        new Date()
      );
      return compareDesc(prevDataHora, nextDataHora);
    });

    setEntrevistas(() => {
      return newEntrevistas;
    });
  }

  function handleOnFetchComplete(data) {
    setEntrevistas(data.data);
  }

  function renderTopo() {
    if (!vaga) return <></>;

    const { titulo, cidade } = vaga;
    return (
      <PainelPrincipalTopo>
        <h1>Entrevistas para esta vaga</h1>
        <Breadcrumbs>
          <ul>
            <li>Entrevistas</li>
            <li className="ativo">Detalhes</li>
          </ul>
        </Breadcrumbs>
        <TituloVaga>{titulo}</TituloVaga>
        <CidadeVaga>
          {cidade.nome}/{cidade.estado.sigla}
        </CidadeVaga>
      </PainelPrincipalTopo>
    );
  }

  function renderEntrevistas() {
    return entrevistas.map(entrevista => {
      return (
        <ListEntrevista
          data={entrevista}
          key={entrevista.id}
          onReagendarClick={() => {
            const { data, hora, id, endereco } = entrevista;
            setEntrevistaToEdit({ data, hora, id, endereco });
            setModalEditEntrevista(true);
          }}
          onQueroContratarClick={() => {
            const { vagaId } = match.params;
            history.push(`/vaga-estudante/${vagaId}/${entrevista.aluno.id}`);
          }}
        />
      );
    });
  }

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {(empresa.escola && <SubmenuEscola type="vagas" />) || (
                  <SubmenuEmpresa type="vagas" />
                )}
              </MenuInterno>
              <PainelPrincipal>
                {renderTopo()}
                <Filtros />
                <Loading loading={entrevistasLoading}>
                  <ContentCards>{renderEntrevistas()}</ContentCards>
                </Loading>
                <div>
                  <Paginator
                    endpoint="entrevistas"
                    onFetchComplete={handleOnFetchComplete}
                    onLoading={loading => setEntrevistasLoading(loading)}
                    query={query}
                  />
                </div>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
      <Modal
        open={modalEditEntrevista && entrevistaToEdit !== null}
        onClose={() => setModalEditEntrevista(false)}
      >
        <ModalEditEntrevista
          entrevista={entrevistaToEdit}
          onClose={() => setModalEditEntrevista(false)}
          onUpdateComplete={handleEntrevistaUpdated}
        />
      </Modal>
    </Page>
  );
}

export default function VagaEntrevistas({ match, history }) {
  return (
    <EmpresaProvider>
      <VagaProvider>
        <EntrevistaProvider>
          <_VagaEntrevistas match={match} history={history} />
        </EntrevistaProvider>
      </VagaProvider>
    </EmpresaProvider>
  );
}
