import styled from "styled-components";

import iconChevronDownDark from "@/assets/images/icons/chevron-down-dark.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  span {
    display: block;
    position: absolute;
    right: 0px;
    color: #db4c81;
  }
`;

export const ValueContainer = styled.div.attrs({
  className: "value-container",
})`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  border: 1px solid #a1a0a8;
  min-height: 52px;
  min-width: 50px;
  display: block;
  font-size: 16px;
  text-align: left;
  border-radius: 4px;
  ${({ multiple }) => {
    if (multiple) return "padding: 6px 66px 6px 8px";
    return "padding: 14px 66px 14px 16px";
  }}
`;

export const MenuHandler = styled.div`
  background-image: url(${iconChevronDownDark});
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 100%;
  cursor: pointer;
  border-left: 1px solid #a1a0a8;
`;

export const ValueLabel = styled.div`
  display: inline-block;
  padding: 4px 8px;
  background-color: #7294a5;
  color: #ffffff;
  margin: 4px;
  button {
    color: #ffffff;
    background-color: transparent;
    padding: 2px;
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const SearchAndListContainer = styled.div.attrs({
  className: "search-and-list-container",
})`
  position: ${({ relative }) => {
    return relative ? "relative" : "absolute";
  }};
  top: ${({ relative }) => {
    return relative ? "-52px" : "30px";
  }};
  display: ${({ open }) => {
    return open ? "flex" : "none";
  }};
  flex-direction: column;
  width: 100%;
  height: 300px;
  background-color: #fff;
  border: 1px solid #a1a0a8;
  z-index: 2;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 4px;
`;

export const InputContainer = styled(Container)`
  position: sticky;
  top: 0;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
  input {
    padding: 14px 16px;
    border: transparent;
    /* border-bottom: 1px solid #a1a0a8; */
    height: 52px;
    min-width: 50px;
    display: block;
    font-size: 16px;
    text-align: left;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  bottom: 6px;
  right: 20px;
  width: 60px;
  z-index: 5;
`;

export const OptionsContainer = styled.div.attrs({
  className: "options-container",
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OptionsList = styled.div.attrs({
  className: "options-list",
})``;

export const Option = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #fafafa;
  }
`;
