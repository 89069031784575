import React from "react";
import { osName } from "react-device-detect";
import { FaDownload } from "react-icons/fa";

import DocumentoTCE from "@/components/TCE/Documento";
import { Button } from "@/components/Common";

import { DownloadButtonContainer } from "./styles";

export default function TCE({ tce }) {
  function render() {
    if (["android"].includes(osName.toLowerCase())) {
      const downloadUrl = `https://www.integrador.app/rest/files/tce-pdf/${btoa(tce.id)}`;
      return (
        <DownloadButtonContainer>
          <Button
            variant="cyan"
            to={downloadUrl}
            externalLink
            download="download"
          >
            <FaDownload size={13} /> Fazer download do contrato
          </Button>
        </DownloadButtonContainer>
      );
    }

    return <DocumentoTCE height={"99.5vh"} tceId={tce.id} />;
  }

  return <>{render()}</>;
}
