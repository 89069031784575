import { combineReducers } from "redux";

// auth
import { reducer as auth } from "./auth";
import { reducer as authEditarCredenciais } from "./auth/editar-credenciais";

// empresas
import { reducer as viewEmpresa } from "./empresas/view";
import { reducer as createEmpresa } from "./empresas/create";
import { reducer as updateEmpresa } from "./empresas/update";
import { reducer as cursoEmpresa } from "./empresas/cursos";

//franqueados
import { reducer as createFranqueado } from "./franqueados/create";

// observacoes
import { reducer as empresaCreateObservacao } from "./empresas-observacoes/create";
import { reducer as empresaDeleteObservacao } from "./empresas-observacoes/delete";

// supervisores
import { reducer as listSupervisor } from "./supervisores/list";
import { reducer as createSupervisor } from "./supervisores/create";
import { reducer as deleteSupervisor } from "./supervisores/delete";

// utils
import { reducer as consultaCnpj } from "./utils/consulta-cnpj";
import { reducer as consultaCep } from "./utils/consulta-cep";

// paises
import { reducer as listPaises } from "./paises/list";

// alunos
import { reducer as deleteAluno } from "./alunos/delete";

// tces
import { reducer as tceAddCoordenador } from "./tces/add-coordenador";

// relatorios
import { reducer as planilhaTces } from "./planilhas/tces";
import { reducer as planilhaAlunos } from "./planilhas/alunos";
import { reducer as planilhaEmpresas } from "./planilhas/empresas";
import { reducer as planilhaEscolas } from "./planilhas/escolas";
import { reducer as planilhaSugestoesEscolas } from "./planilhas/sugestoes-escolas";

// selects
import { reducer as selectCidades } from "./cidades/select-cidades";

// indicadores
import { reducer as indicadoresTotaisGerais } from "./indicadores/totais-gerais";
import { reducer as indicadoresTotaisPorPeriodo } from "./indicadores/totais-por-periodo";

const reducers = combineReducers({
  auth,
  authEditarCredenciais,
  consultaCep,
  consultaCnpj,
  createEmpresa,
  createFranqueado,
  createSupervisor,
  cursoEmpresa,
  deleteAluno,
  deleteSupervisor,
  empresaCreateObservacao,
  empresaDeleteObservacao,
  listPaises,
  listSupervisor,
  tceAddCoordenador,
  updateEmpresa,
  viewEmpresa,
  planilhaTces,
  planilhaAlunos,
  planilhaEmpresas,
  planilhaEscolas,
  planilhaSugestoesEscolas,
  selectCidades,
  indicadoresTotaisGerais,
  indicadoresTotaisPorPeriodo,
});

export default reducers;
