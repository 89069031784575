import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators  } = createActions({
  deleteEmpresaRequest: ["id", "history", "modo"],
  deleteEmpresaSuccess: [],
  deleteEmpresaFailure: ["error"],
});

export const deleteEmpresaTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  error: null
};

export const deleteEmpresaRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const deleteEmpresaSuccess = (state, action) => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const deleteEmpresaFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_EMPRESA_REQUEST]: deleteEmpresaRequest,
  [Types.DELETE_EMPRESA_SUCCESS]: deleteEmpresaSuccess,
  [Types.DELETE_EMPRESA_FAILURE]: deleteEmpresaFailure,
});
