import styled from "styled-components";

export const StepperContainer = styled.div``;

export const StepContainer = styled.div.attrs({
  className: "stepper-step",
})`
  display: ${props => ({ active }) => {
    return active ? "initial" : "none";
  }};

  div.header {
    display: flex;
    border-bottom: ${({ noBorder }) => {
      if (noBorder === undefined) return "1px solid #D6D6D6";
      return "none";
    }};
    padding-bottom: 23px;
    margin-bottom: 30px;
    div.passo {
      background-color: #E6E6E6;
      padding: 7px 18px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
`;
