import React from "react";
import { useSelector } from "react-redux";
import { FiMapPin, FiBriefcase, FiEdit2 } from "react-icons/fi";
import { useAluno } from "@/hooks/alunos";
import { Row, Col } from "@/components/Grid";

import {
  CurriculoContainer,
  HeaderNome,
  CircleButton,
  DadosPessoais,
  DadosPessoaisBox,
} from "./styles";

import RedesSociais from "./RedesSociais";
import Idiomas from "./Idiomas";
import Formacoes from "./Formacoes";
import ExperienciasProfissionais from "./ExperienciasProfissionais";
import CursosComplementares from "./CursosComplementares";
import AtividadesComplementares from "./AtividadesComplementares";

import Sobre from "./Sobre";
import Endereco from "./Endereco";
import Contato from "./Contato";

export default function Curriculo() {
  const { aluno } = useAluno();
  const { data: user } = useSelector(state => state.auth);

  function renderHeader() {
    const local = `${aluno.endereco.cidade.nome}, ${aluno.endereco.cidade.estado.sigla}`;

    return (
      <Row hascols>
        <Col md="1/2" sm="1">
          <HeaderNome>
            <div className="linha1">
              <h2>{aluno.nome}</h2>
            </div>
            <div className="linha2">
              <ul>
                <li>
                  <FiMapPin />
                  {local}
                </li>
                <li>
                  <FiBriefcase />
                  {aluno.curso.titulo}
                </li>
              </ul>
            </div>
          </HeaderNome>
        </Col>
        <Col md="1/2" sm="1">
          <RedesSociais redes={{
            facebook: aluno.facebook,
            linkedin: aluno.linkedin,
            instagram: aluno.instagram,
            twitter: aluno.twitter,
          }} />
        </Col>
      </Row>
    );
  }

  function renderDadosPessoais() {

    return (
      <DadosPessoais>
        <div className="content">
          <Sobre />
          <Endereco />
          <Contato />
        </div>
      </DadosPessoais>
    );
  }

  return (
    <CurriculoContainer>
      {aluno && (
        <>
          {renderHeader()}
          {renderDadosPessoais()}
          <Formacoes />
          <ExperienciasProfissionais />
          <CursosComplementares />
          <AtividadesComplementares />
          <Idiomas />
        </>
      )}
    </CurriculoContainer>
  );
}
