import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isAfter, isValid } from "date-fns";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import { Form, Input, Select } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";

import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditFormacaoAnterior({ onClose, data }) {
  const [optionsNiveis, setOptionsNiveis] = useState([]);
  const [formData, setFormData] = useState(null);
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  const schema = Yup.object().shape({
    nivel_id: Yup.number()
      .typeError("Obrigatório")
      .required("Obrigatório"),
    curso_nome: Yup.string().required("Obrigatório"),
    empresa_nome: Yup.string().required("Obrigatório"),
    data_inicio: Yup.date()
      .typeError("Data inválida")
      .required("Obrigatório"),
    data_termino: Yup.date()
      .typeError("Data inválida")
      .test("data-conclusao-maior", "Data inválida", function(value) {
        return isValid(value) && isAfter(value, this.parent.data_inicio);
      })
      .required("Obrigatório"),
  });

  useEffect(() => {
    fetchNiveis();
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  async function fetchNiveis() {
    const { data: response } = await api.get("utils/niveis");
    if (response.success) {
      setOptionsNiveis(state => {
        return response.data.map(nivel => {
          return { id: nivel.id, title: nivel.nome };
        });
      });
    }
  }

  async function updateFormacaoAnterior(data) {
    setLoading(true);
    const endpoint = `alunos/curriculos/formacoes-anteriores/${formData.id}`;
    const alunoId = aluno.id;
    data.aluno_id = alunoId;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      toastify("Formação salva com sucesso");
      fetchAluno(alunoId);
      onClose();
    }
  }

  function handleSubmit(data, { resetForm }) {
    updateFormacaoAnterior(data, resetForm);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  return (
    <ModalContent>
      {formData && (
        <Form onSubmit={handleSubmit} schema={schema} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  label="Nome do curso"
                  name="curso_nome"
                  value={formData.curso_nome || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Select
                  label="Nível"
                  name="nivel_id"
                  options={optionsNiveis}
                  value={formData.nivel_id || ""}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Input
                  label="Nome da escola"
                  name="empresa_nome"
                  value={formData.empresa_nome || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  type="date"
                  max="2099-01-01"
                  label="Data de início"
                  name="data_inicio"
                  value={formData.data_inicio || ""}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Input
                  type="date"
                  max="2099-01-01"
                  label="Data de término"
                  name="data_termino"
                  value={formData.data_termino || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
