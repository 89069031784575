import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, Button } from "@/components/Common";
import NavbarLogo from "./NavbarLogo";
import MobileMenu from "./MobileMenu";
import User from "./User";

import {
  Container,
  Wrapper,
  MenusArea,
  MenuHandler,
  Menu,
  MenuItem,
} from "./styles";

function Navbar({ type, history }) {
  const { authenticated, data: user } = useSelector(state => state.auth);

  function renderLoginButton() {

    const variant = type === "home" ? "integradorYellow-outline" : "integradorYellow-outline";

    if (!authenticated) {
      return (
        <Button variant={variant} to="/login">
          Entrar
        </Button>
      );
    }

    if (user.tipo === "administrador") {
      return (
        <Button variant={variant} to="/admin/indicadores/totais">
          Continuar
        </Button>
      );
    }

    if (user.tipo === "franqueado") {
      return (
        <Button variant={variant} to="/franqueado/vagas">
          Continuar
        </Button>
      );
    }

    return (
      <Button variant={variant} to="/perfil">
        Continuar
      </Button>
    );
  }

  const EstudantesHandler = <MenuHandler type={type}>Estudantes</MenuHandler>;
  const EstudantesMenu = (
    <Menu>
      <MenuItem>
        <Link to="/vagas">Vagas de Estágio</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/login">Entrar</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/cadastro-estudante">Cadastre-se</Link>
      </MenuItem>
    </Menu>
  );

  const EmpresasHandler = <MenuHandler type={type}>Empresas</MenuHandler>;
  const EmpresasMenu = (
    <Menu>
      <MenuItem>
        <Link to="/login">Entrar</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/cadastro-empresa">Cadastre-se</Link>
      </MenuItem>
    </Menu>
  );

  const EscolasHandler = <MenuHandler type={type}>Escolas</MenuHandler>;
  const EscolasMenu = (
    <Menu>
      <MenuItem>
        <Link to="/login">Entrar</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/cadastro-escola">Cadastre-se</Link>
      </MenuItem>
    </Menu>
  );

  function renderHomeAndLoginMenu() {
    if (!["home", "login"].includes(type)) {
      return <></>;
    }

    return (
      <>
        <MenusArea type={type}>
          {type === "login" && (
            <li>
              <Link to="/" className="inicio">
                Início
              </Link>
            </li>
          )}
          <li className="has-caret">
            <Dropdown handler={EstudantesHandler} content={EstudantesMenu} />
          </li>
          <li className="has-caret">
            <Dropdown handler={EmpresasHandler} content={EmpresasMenu} />
          </li>
          <li className="has-caret">
            <Dropdown handler={EscolasHandler} content={EscolasMenu} />
          </li>
        </MenusArea>
        {renderLoginButton()}
      </>
    );
  }

  function renderInternalMenu() {
    if (!["interna"].includes(type)) {
      return <></>;
    }

    return <User />;
  }

  return (
    <Container type={type}>
      {type !== "interna" && (
        <MobileMenu type={type} />
      )}
      <Wrapper>
        <NavbarLogo type={type} />
        {renderHomeAndLoginMenu()}
        {renderInternalMenu()}
      </Wrapper>
    </Container>
  );
}

export default withRouter(Navbar);
