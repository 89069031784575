import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  empresaCursoAddRequest: ["empresaId", "cursoId", "onSuccess"],
  empresaCursoRemoveRequest: ["empresaId", "cursoId", "onSuccess"],
  empresaCursoAddTodosRequest: ["empresaId", "onSuccess"],
  empresaCursoRemoveTodosRequest: ["empresaId", "onSuccess"],
  empresaCursoSuccess: [],
  empresaCursoFailure: ["error"],
});

export const EmpresaCursosTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
  cursoId: null,
};

// Reducers
export const addCursoRequest = (state, action) =>
  update(state, {
    loading: { $set: true },
    cursoId: { $set: action.cursoId },
    error: { $set: null }
  });
export const removeCursoRequest = (state, action) =>
  update(state, {
    loading: { $set: true },
    cursoId: { $set: action.cursoId },
    error: { $set: null }
  });
export const addTodosCursosRequest = state =>
  update(state, {
    loading: { $set: true },
    cursoId: { $set: "add-all" },
    error: { $set: null }
  });
  export const removeTodosCursosRequest = state =>
  update(state, {
    loading: { $set: true },
    cursoId: { $set: "remove-all" },
    error: { $set: null }
  });

export const success = state =>
  update(state, {
    loading: { $set: false },
    cursoId: { $set: null },
  });
export const failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    cursoId: { $set: null },
    error: { $set: action.error },
  });

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.EMPRESA_CURSO_ADD_REQUEST]: addCursoRequest,
  [Types.EMPRESA_CURSO_REMOVE_REQUEST]: removeCursoRequest,
  [Types.EMPRESA_CURSO_ADD_TODOS_REQUEST]: addTodosCursosRequest,
  [Types.EMPRESA_CURSO_REMOVE_TODOS_REQUEST]: removeTodosCursosRequest,
  [Types.EMPRESA_CURSO_SUCCESS]: success,
  [Types.EMPRESA_CURSO_FAILURE]: failure,
});
