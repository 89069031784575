import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 4px;
  /* o form interno me bugou legal
    setando uma altura máxima pra ele
   */
  form {
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
  }
`;

export const ModalBody = styled.div`
  overflow-y: scroll;
  width: 100%;
  padding: 40px;
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #b9b7cc;
  > * {
    margin-left: 20px;
  }
`;

export const H1 = styled.h1`
  align-self: center;
  margin-left: 35%;
  color: orange;
`;

export const Div = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const A = styled.a`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
`;
