import * as Yup from "yup";

const authSchema = Yup.object().shape({
  email: Yup.string()
    .email("E-mail inválido")
    .required("Obrigatório"),
  senha: Yup.string().required("Obrigatório"),
});

export {
  authSchema,
}
