import styled from "styled-components";

export const AvatarContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: -24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 110px;
  height: 110px;
  border: 2px solid #f1f1f1;
  border-radius: 55px;
  background-color: #fafafa;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const AvisoMensagem = styled.div`
  margin-bottom: 2rem;
`;
