import React, { useState } from "react";

const VagasContext = React.createContext([{}, () => {}]);

const VagasProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <VagasContext.Provider value={[state, setState]}>
      {props.children}
    </VagasContext.Provider>
  )
}

export { VagasContext, VagasProvider };
