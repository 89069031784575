import styled from "styled-components";
import { Card } from "@/components/Common";

export const Container = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 4px;
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: 40px;
  h1 {
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #B9B7CC;
  > * {
    margin-left: 20px;
  }
`;
