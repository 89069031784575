import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-easy-crop";
import { Button } from "@/components/Common";

import getCroppedImg from "@/utils/get-cropped-img";
import { ModalContent, ModalFooter } from "./styles";

export default function ModalCadastroAvatar({ onClose, onChange, ...rest }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const inputFile = useRef();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const triggerButton = () => {
    inputFile.current.click();
  };

  const handleFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]);
      setImageSrc(imageDataUrl);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  };

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const resetModalAndClose = () => {
    inputFile.current.value = "";
    setZoom(1);
    setImageSrc(null);
    onClose();
  };

  const saveCroppedImage = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    onChange(croppedImage);
    resetModalAndClose();
  };

  return (
    <ModalContent>
      <h2>Alterar imagem de avatar</h2>
      <input type="file" ref={inputFile} onChange={handleFileChange} />
      <Button variant="magenta" block onClick={triggerButton}>
        Selecionar imagem
      </Button>
      {imageSrc && (
        <>
          <div className="crop-container">
            <Cropper
              cropShape="round"
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              showGrid={false}
              aspect={1}
              cropSize={{ width: 200, height: 200 }}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="controls"></div>

          <ModalFooter>
            <Button
              variant="cyan"
              block
              onClick={saveCroppedImage}
            >
              Salvar
            </Button>
            <Button variant="cancel" block onClick={resetModalAndClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
}
