import { call, put } from "redux-saga/effects";

import SelectActions from "@/store/ducks/cidades/select-cidades";
import api from "@/services/api";

export function* request(action) {
  try {
    const { estadoId } = action;
    const url = `/cidades/estado/${estadoId}`;
    const { data: retorno } = yield call(api.get, url);
    if (retorno.success) {
      const data = retorno.data.map(c => {
        return { id: c.id, title: c.nome };
      });
      yield put(SelectActions.success(data));
    } else {
      yield put(SelectActions.failure("Erro ao obter dados da api"));
    }
  } catch (error) {
    yield put(SelectActions.failure("Erro ao obter dados da api"));
  }
}
