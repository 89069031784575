import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import ConsultaCnpjActions from "@/store/ducks/utils/consulta-cnpj";

const getAuthData = state => state.auth.data;

export function* consultaCnpj(action) {
  try {
    toastify("Consultando dados com base no seu CNPJ");
    const { cnpj } = action;
    const cnpjSanitazed = cnpj.replace(/[^\d]+/g, "");
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.get,
      `utils/cnpj/${cnpjSanitazed}`, makeOptions(authData)
    );

    if (retorno.success) {
      yield put(ConsultaCnpjActions.consultaCnpjSuccess(retorno.data));
    } else {
      yield put(ConsultaCnpjActions.consultaCnpjFailure(retorno.errors));
    }
  } catch (e) {
    yield put(ConsultaCnpjActions.consultaCnpjFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}


