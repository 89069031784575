import React, { useEffect } from "react";
import { Page, DashboardContainer } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Button } from "@/components/Common";
import { TceProvider } from "@/context/tces/tce";
import { useTce } from "@/hooks/tces";

import TCEContent from "@/components/TCE";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  VoltarContainer,
} from "./styles";

function _TCE({ history, match }) {
  const { fetchTce, tce } = useTce();

  useEffect(() => {
    const { id: tceId } = match.params;
    fetchTce(tceId);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {!tce && <FullPageLoader />}
      {tce && (
        <>
          <AdminMenu />
          <DashboardContainer>
            <PaineisContainer>
              <Content>
                <PainelPrincipal>
                  <VoltarContainer>
                    <Button
                      variant="jeans"
                      icon="arrow-left"
                      onClick={() => {
                        history.go(-1);
                      }}
                    >
                      {" "}
                      Voltar
                    </Button>
                  </VoltarContainer>
                  <TCEContent tce={tce} />
                </PainelPrincipal>
              </Content>
            </PaineisContainer>
          </DashboardContainer>
        </>
      )}
    </Page>
  );
}

export default function TCE({ history, match }) {
  return (
    <TceProvider>
      <_TCE history={history} match={match} />
    </TceProvider>
  );
}
