import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import CreateFranqueadoActions from "@/store/ducks/franqueados/create";

const getAuthData = state => state.auth.data;

export function* create(action) {
  try {
    const { data, history, pathname } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(
      api.post,
      `franqueados`,
      data,
      makeOptions(authData)
    );

    if (retorno.success) {
      yield put(CreateFranqueadoActions.createFranqueadoSuccess(retorno.data));

      if (Number(data.escola) === 1) {
        toastify("Escola criada com sucesso");
      } else {
        toastify("Franqueado criado com sucesso");
      }

      if (pathname !== undefined) {
        history.push(pathname);
      } else {
        history.push("/login");
      }

    } else {
      yield put(CreateFranqueadoActions.createFranqueadoFailure(retorno.errors));
      // exibindo um toast para cada erro
      [...Object.keys(retorno.errors)].forEach(e => {
        toastify(retorno.errors[e][0], "error");
      });
    }
  } catch (e) {
    yield put(
      CreateFranqueadoActions.createFranqueadoFailure({
        api_failure: "Não foi possível se comunicar com a API",
      })
    );
  }
}
