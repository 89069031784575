import React from "react";
import { Card } from "@/components/Common";
import {
  SectionFormContainer,
  Header,
  CardHeader,
  SubheaderContainer,
} from "./styles";

function SectionForm({
  headerTitle,
  headerSubtitle,
  formTitle,
  formSubtitle,
  children,
  ...rest
}) {
  const _headerTitle = headerTitle !== undefined ? headerTitle : "Cadastre-se";
  const _headerSubtitle =
    headerSubtitle !== undefined
      ? headerSubtitle
      : "Informações corretas e completas aumentam suas chances de contratação.";
  const _formTitle = formTitle !== undefined ? formTitle : undefined;
  const _formSubtitle = formSubtitle !== undefined ? formSubtitle : undefined;

  return (
    <SectionFormContainer>
      <Header>
        <h3>{_headerTitle}</h3>
        <p>{_headerSubtitle}</p>
      </Header>
      <Card shadow>
        <CardHeader>
          {_formTitle !== undefined && <h4>{_formTitle}</h4>}
          {_formSubtitle !== undefined && <p>{_formSubtitle}</p>}
        </CardHeader>
        {children}
      </Card>
    </SectionFormContainer>
  );
}

function SubHeader({ title }) {
  return (
    <SubheaderContainer>
      <h3>{title}</h3>
    </SubheaderContainer>
  );
}

export { SectionForm, SubHeader };
