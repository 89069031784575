import styled from "styled-components";
import { VagaCard as DefaultCard } from "../styles";

export const VagaCard = styled(DefaultCard)`
  cursor: pointer;
  div.body {
    h4.tipo {
      color: #f78764;
    }
  }
`;

export const Candidatos = styled.div.attrs({
  className: "candidato",
})`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #271946;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  transition: 300ms;
  svg {
    margin-right: 8px;
    color: #271946;
    transition: 300ms;
  }
`;

export const TotalResult = styled.p`
  font-size: 16px;
  color: #605f6a;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const BuscarVagas = styled.div`
  margin-bottom: 40px;
`;
