import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  direction: row;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;
