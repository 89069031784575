import React, { useState } from "react";

const AssinanteContext = React.createContext([{}, () => {}]);

const AssinanteProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <AssinanteContext.Provider value={[state, setState]}>
      {props.children}
    </AssinanteContext.Provider>
  )
}

export { AssinanteContext, AssinanteProvider };
