import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthActions from "@/store/ducks/auth";

export default function CheckToken({ history }) {
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.auth);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (authenticated && timer === null) {
      const t = setInterval(() => {
        dispatch(AuthActions.authCheckToken(history));
      }, 5 * 60 * 1000);
      setTimer(t);
    }
    if (!authenticated && timer !== null) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [authenticated, timer]);

  return <></>;
}
