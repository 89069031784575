import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  max-width: 640px;
  display: flex;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;
  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #a1a0a8;
  margin: 16px 0 24px 0;
`;

export const FormSubHeader = styled.div`
  text-align: center;
  padding: 10px 0;
  p {
    font-size: 14px;
  }
`;

export const Aviso = styled.div`
  padding: 16px;
  background-color: #fff3cd;
  color: #000;
  border-radius: 5px;
  margin-bottom: 24px;
`;
