import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { useThrottle } from "@/hooks";
import api, { makeOptions } from "@/services/api";
import queryString from "query-string";

import {
  Container,
  ValueContainer,
  ValueLabel,
  MenuHandler,
  InputContainer,
  OptionsContainer,
  Option,
  OptionsList,
  SearchAndListContainer,
  LoadingContainer,
} from "./styles";

export default function Implementation({
  endpoint,
  label,
  mapOptions,
  onChange,
  children,
  multiple,
  firstOption,
  relative,
  selectAll,
  searchParam = "search",
  query,
  value = [],
}) {
  const { data: user } = useSelector(state => state.auth);
  const [options, setOptions] = useState([]);
  const [optionsAll, setOptionsAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  // const [selectedValueText, setSelectedValueText] = useState(selectedText || "Selecione");
  const [listOpen, setListOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState();

  const [selectedOptions, setSelectedOptions] = useState(value);

  const searchAndListContainer = useRef();
  const optionsContainer = useRef();
  const inputContainer = useRef();
  const container = useRef();

  const tSearchText = useThrottle(searchText, 500);

  function getQuery() {
    const mergedQuery = {
      ...(query && query),
      ...(searchParam && tSearchText && { [searchParam]: tSearchText }),
      ...(page && { page }),
      // ...(queryTest && queryTest),
    };
    return queryString.stringify(mergedQuery);
  }

  //se for selectAll, precisamos da lista completa de cursos, sem paginacao
  async function fetchAllFromApi() {
    setLoading(true);
    const query = getQuery();

    const { data: response } = await api.get(
      `${endpoint}/all?${query}`,
      makeOptions(user)
    );

    setLoading(false);
    
    if (response.success) {
      setOptionsAll(mapOptions(response.data));
    }
  }

  async function fetchFromApi() {
    setLoading(true);
    const query = getQuery();

    const { data: response } = await api.get(
      `${endpoint}?${query}`,
      makeOptions(user)
    );

    setLoading(false);
    if (response.success) {
      const options = mapOptions(response.data);
      const paginator = response.paginator;
      setPaginator(state => {
        return { ...state, ...paginator };
      });

      if (page > 1) {
        setOptions(state => {
          let newOptions = [...state, ...options];
          if (firstOption) {
            newOptions = newOptions.filter(option => {
              return (
                option.id !== firstOption.id &&
                option.title !== firstOption.title
              );
            });
            newOptions.unshift(firstOption);
          }
          if(selectAll) {
            if(allSelected) {
              newOptions = [{id: 'remover', title: 'REMOVER TODOS'}, ...newOptions]
            } else {
              newOptions = [{id: 'todos', title: 'SELECIONAR TODOS'}, ...newOptions]
            }
          }
          return newOptions;
        });
      } else {
        setOptions(state => {
          let newOptions = options;
          if (firstOption) {
            newOptions.unshift(firstOption);
          }
          if(selectAll) {
            if(allSelected) {
              newOptions = [{id: 'remover', title: 'REMOVER TODOS'}, ...newOptions]
            } else {
              newOptions = [{id: 'todos', title: 'SELECIONAR TODOS'}, ...newOptions]
            }
          }
          return newOptions;
        });
      }
    }
  }

  function loadMore() {
    if (!loading && paginator.pages > page) {
      setPage(state => state + 1);
    }
  }

  function handleScroll(event) {
    const { scrollTop } = event.target;
    const searchAndListContainerHeight = event.target.getBoundingClientRect()
      .height;
    const optionsContainerHeight = optionsContainer.current.getBoundingClientRect()
      .height;
    const inputContainerHeiht = inputContainer.current.getBoundingClientRect()
      .height;

    const val1 = scrollTop + searchAndListContainerHeight;
    const val2 = optionsContainerHeight + inputContainerHeiht;

    if (val1 - 2 === val2) {
      loadMore();
    }
  }

  function handleClickOustide(event) {
    if (container.current && !container.current.contains(event.target)) {
      setListOpen(false);
    }
  }

  function handleChange(event) {
    event.persist();
    const { value } = event.target;
    setSearchText(value);
  }

  function handleSelectedValue(option) {
    if (multiple === undefined) {
      setSelectedOptions([option]);
    } else {
      //se for a opcao selecionar todos, adicionar todos os valores
      if(option.id === 'todos') {
        setSelectedOptions(state => {
          return [...optionsAll];
        });
        setAllSelected(state => {
          return true;
        })
      } else if(option.id === 'remover') {
        setSelectedOptions(state => {
          return [];
        });
        setAllSelected(state => {
          return false;
        })
      } else {
        // impedindo de inserir um item mais de uma vez
        const index = selectedOptions.findIndex(o => o.id === option.id);
        if (index === -1) {
          setSelectedOptions(state => {
            return [...state, ...[option]];
          });

          //caso tenha selecionado todos, marcar como allSelected
          if(selectedOptions.length + 1 === optionsAll.length) {
            setAllSelected(state => {
              return true;
            })
          }
        }

        
      }

    }
  }

  function handleRemoveSelectedOption(option) {
    const index = selectedOptions.findIndex(o => o.id === option.id);
    if (index !== -1) {
      const _selectedOptions = selectedOptions;
      _selectedOptions.splice(index, 1);
      setSelectedOptions(state => {
        return [..._selectedOptions];
      });
      setAllSelected(state => {
        return false;
      })
    }
  }

  function renderOptions() {
    return options.map(option => {
      return (
        <Option key={option.id} onClick={() => handleSelectedValue(option)}>
          {option.title}
        </Option>
      );
    });
  }

  function renderSelectedOptions() {
    // if (value.length == 0) return "";
    // if (value[0].title === undefined) return "";

    // if (!value) return <></>;

    if (multiple === undefined) {
      return selectedOptions
        .filter(option => option.hasOwnProperty("title"))
        .map(option => option.title)[0];
    }

    return selectedOptions
      .filter(option => option.hasOwnProperty("title"))
      .map(option => {
        return (
          <ValueLabel key={option.id}>
            {option.title}
            <button
              type="button"
              onClick={() => handleRemoveSelectedOption(option)}
            >
              &times;
            </button>
          </ValueLabel>
        );
      });
  }

  useEffect(() => {
    if (value.length) {
      setSelectedOptions(value);
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOustide);
    return () => {
      document.removeEventListener("nousedown", handleClickOustide);
    };
  }, []);

  useEffect(() => {
    searchAndListContainer.current.addEventListener("scroll", handleScroll);
    return () => {
      searchAndListContainer.current.removeEventListener(
        "scroll",
        handleScroll
      );
    };
  }, [searchAndListContainer, paginator]);

  useEffect(() => {
    if (page > 0) fetchFromApi();
    if (page === 0) setPage(1);
  }, [page]);

  useEffect(() => {
    setPage(1);
    setPaginator({ total: 0, pages: 1 });
    fetchFromApi();
    fetchAllFromApi();
  }, [tSearchText, query, allSelected]);

  useEffect(() => {
    fetchAllFromApi();
  },[])

  useEffect(() => {
    if (query) {
      setSelectedOptions([]);
    }
  }, [query]);

  useEffect(() => {
    onChange(selectedOptions);
    setListOpen(false);
  }, [selectedOptions]);

  return (
    <Container ref={container}>
      {label && <label>{label}</label>}
      {children}
      <ValueContainer multiple={multiple}>
        <>{renderSelectedOptions()}</>
        <MenuHandler onClick={() => setListOpen(!listOpen)} />
        {loading && listOpen && (
          <LoadingContainer>
            <PulseLoader size={4} />
          </LoadingContainer>
        )}
      </ValueContainer>

      <SearchAndListContainer
        open={listOpen || undefined}
        relative={relative}
        ref={searchAndListContainer}
      >
        <InputContainer ref={inputContainer}>
          <input placeholder="busca..." type="text" onChange={handleChange} />
        </InputContainer>
        <OptionsContainer ref={optionsContainer}>
          <OptionsList>{renderOptions()}</OptionsList>
        </OptionsContainer>
      </SearchAndListContainer>
    </Container>
  );
}
