import { call, put, select, all } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import CreateSupervisoresActions from "@/store/ducks/supervisores/create";
import ListSupervisoresActions from "@/store/ducks/supervisores/list";

const getAuthData = state => state.auth.data;

export function* create(action) {
  try {
    const { data, resetForm } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.post,
      `supervisores`, data, makeOptions(authData)
    );
    if (retorno.success) {
      yield all([
        put(CreateSupervisoresActions.createSupervisorSuccess(retorno.data)),
        put(ListSupervisoresActions.listSupervisorRequest(authData.empresa.id)),
      ]);
      resetForm();
      toastify("Supervisor criado com sucesso");
    } else {
      yield put(CreateSupervisoresActions.createSupervisorFailure(retorno.errors));
      // exibindo um toast para cada erro
      [...Object.keys(retorno.errors)].forEach(e => {
        toastify(retorno.errors[e][0], "error");
      });
    }
  } catch (e) {
    yield put(CreateSupervisoresActions.createSupervisorFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}
