import React from "react";

import { BubbleContainer } from "./styles";

export default function TabBubble({ count }) {
  return (
    <>
      {(count !== undefined && <BubbleContainer>{count}</BubbleContainer>) || (
        <></>
      )}
    </>
  );
}
