import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Schemas de validação
import { schemaStep1, schemaStep2 } from "./schemas";

// Redux
import CreateEmpresaActions from "@/store/ducks/franqueados/create";
import ConsultaCnpjActions from "@/store/ducks/utils/consulta-cnpj";
import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import SelectCidadesActions from "@/store/ducks/cidades/select-cidades";

// UI
import { Navbar } from "@/components/Blocks";
import { Button } from "@/components/Common";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import {
  Form,
  Scope,
  Input,
  InputMask,
  Select,
  SelectSearch,
  Stepper,
  Step,
} from "@/components/Form";
import { AdminMenu } from "@/components/Admin";
import { SubHeader } from "@/components/PageComponents/Cadastro";
import AceiteTermos from "@/components/AceiteTermos";
import { estados, tipos_pessoa } from "@/data";

import { SectionForm, Header } from "./styles";

export default function CadastroEmpresa({ history }) {
  // state
  const [formData, setFormData] = useState({
    escola: 0,
    endereco: {},
  });

  function mapOptionsCursos(data) {
    return data.map(d => {
      return { id: d.id, title: `${d.nome} (${d.estado.sigla})` };
    });
  }
  
  function handleCursoChange(array_cursos) {
    setFormData(state => {
      return { ...state, ...{ array_cursos } };
    });
  }
  
  const [formStep, setFormStep] = useState(1);
  const [aceite, setAceite] = useState(true);

  const [queryCursos, setQueryCursos] = useState({});

  // state do redux
  const createEmpresa = useSelector(state => state.createEmpresa);

  // Dispatcher
  const dispatch = useDispatch();

  // form handlers
  const _handleSubmit1 = data => _updateForm(data, 2);
  const _handleSubmit2 = data => _updateForm(data, 3);
  const _handleSubmit3 = data => _updateForm(data, "last");

  // gravar dados nos state formData e formStep
  // além de invocar o envio dos dados quando
  // estiver tudo ok
  const _updateForm = (data, step = null) => {
    setFormData(formData => {
      return { ...formData, ...data };
    });
    if (step !== null) {
      setFormStep(step);
    }
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, [name]: value };
    });
  }

  useEffect(() => {}, [createEmpresa, aceite]);

  useEffect(() => {
    if (formStep === "last") {
      dispatch(
        CreateEmpresaActions.createFranqueadoRequest(
          formData,
          history,
          "/franqueados/todos"
        )
      );
    }
  }, [formData, formStep, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(CreateEmpresaActions.createFranqueadoReset());
      dispatch(ConsultaCnpjActions.consultaCnpjReset());
      dispatch(ConsultaCepActions.consultaCepReset());
      dispatch(SelectCidadesActions.reset());
    };
  }, [dispatch]);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        <Header>
          <h4>Franqueados</h4>
          <h2>Cadastrar novo franqueado</h2>
          <div className="breadcrumbs">
            Fraqueados {">"} <span>Cadastrar novo franqueado</span>
          </div>
        </Header>
        <SectionForm>
          <Stepper>
            {/* Etapa 1 */}
            <Step active={formStep === 1} step="1/2">
              <Form
                onSubmit={_handleSubmit1}
                initialData={formData}
                schema={schemaStep1}
              >

                <Row hascols>
                  <Col>
                    <Input
                      label="Nome"
                      name="nome"
                      value={formData.nome|| ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col><InputMask type="cpf" label="CPF" name="cpf" /></Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Input
                      type="mail"
                      name="email"
                      label="E-mail de contato"
                      value={formData.email || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <InputMask
                      type="telefone"
                      name="telefone"
                      label="Telefone de contato"
                      value={formData.telefone || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col><InputMask type="decimal" label="Comissão (%)" name="comissao" value={formData.comissao || ""} /></Col>
                </Row>

                <Row>
                  <Col>
                    <SelectSearch
                      label="Cidades"
                      endpoint="cidades/todas"
                      searchParam="nome"
                      name="cidades"
                      mapOptions={mapOptionsCursos}
                      onChange={handleCursoChange}
                      value={formData.array_cursos}
                      query={queryCursos}
                      multiple
                      relative
                    />
                  </Col>
                </Row>

                <Row>
                  <Button variant="cyan" type="submit">
                    Continuar
                  </Button>
                </Row>
              </Form>
            </Step>
           
            {/* Step 2 */}
            <Step active={[2, "last"].includes(formStep)} step="2/2">
              <Form
                onSubmit={_handleSubmit3}
                initialData={formData}
                schema={schemaStep2}
              >
                <SubHeader title="Dados de acesso:" />

                <Scope path="usuario">
                  <Row hascols>
                    <Col>
                      <Input type="mail" label="E-mail" name="email" />
                    </Col>
                    <Col>
                      <Input
                        type="mail"
                        label="Confirmar e-mail"
                        name="email_c"
                      />
                    </Col>
                  </Row>
                  <Row hascols>
                    <Col>
                      <Input label="Senha" type="password" name="senha" />
                    </Col>
                    <Col>
                      <Input
                        label="Confirmar senha"
                        type="password"
                        name="senha_c"
                      />
                    </Col>
                  </Row>
                </Scope>

                <Row>
                  <Col>
                    <AceiteTermos onChange={checked => setAceite(checked)} />
                  </Col>
                </Row>

                <Row>
                  <Button
                    variant="tangerine"
                    icon="arrow-left"
                    onClick={() => setFormStep(1)}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="cyan"
                    type="submit"
                    disabled={!aceite || undefined}
                    loading={createEmpresa.loading}
                  >
                    Concluir
                  </Button>
                </Row>
              </Form>
            </Step>
          </Stepper>
        </SectionForm>
      </DashboardContainer>
    </Page>
  );
}
