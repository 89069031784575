import React, { useState } from "react";
import { useAluno } from "@/hooks/alunos";
import { cep } from "@/formatters";
import { FaPen } from "react-icons/fa";
import { Modal } from "@/components/Common";
import ModalEditEndereco from "./ModalEditEndereco";
import { Container, CircleButton } from "./styles";

export default function Endereco() {
  const { aluno } = useAluno();
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const { endereco } = aluno;

  return (
    <Container>
      <h4>Endereço:</h4>
      {endereco && (
        <p>
          {endereco.logradouro}, {endereco.numero}, {endereco.bairro}
          <br />
          {endereco.cidade.nome}, {endereco.cidade.estado.sigla}
          <br />
          CEP: {cep(endereco.cep)}
        </p>
      )}
      <div className="actions">
        <CircleButton onClick={() => setModalEditOpen(true)}>
          <FaPen size={10} />
        </CircleButton>
      </div>
      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditEndereco onClose={() => setModalEditOpen(false)} />
      </Modal>
    </Container>
  );
}
