import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Handler = styled.span`
  position: relative;
`;

export const Content = styled.div.attrs({
  className: "dropdown-content"
})`
  display: ${props => (props.open ? "flex" : "none")};
  position: absolute;
  right: -40px;
  top: 0px;
  pointer-events: none;
`;
