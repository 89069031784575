import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { AlunoContext } from "@/context/alunos/aluno";
import api, { makeOptions } from "@/services/api";

const useAluno = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(AlunoContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchAluno(alunoId) {
    _setLoading(true);
    const endpoint = `alunos/${alunoId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function updateAluno(alunoId, data) {
    _setLoading(true);
    const endpoint = `alunos/${alunoId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  return {
    fetchAluno,
    updateAluno,
    aluno: state.data,
    loading: state.loading,
  };
};

export { useAluno };
