import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { FaEllipsisV, FaPen, FaTrash } from "react-icons/fa";
import { useAluno } from "@/hooks/alunos";

import { Modal } from "@/components/Common";
import ModalEditIdioma from "./ModalEditIdioma";
import ModalDeleteEdioma from "./ModalDeleteIdioma";

import {
  Container,
  CircleButton,
  SmallCircleButton,
  ListITem,
  ListItemActions,
  MenuContainer,
} from "./styles";

export default function Idiomas() {
  const { aluno } = useAluno();
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const { data: user } = useSelector(state => state.auth);

  function openModal(idioma) {
    const data = {
      ...{ aluno_id: user.perfil_id },
      ...(idioma && { idioma_id: idioma.pivot.idioma_id }),
      ...(idioma && { proficiencia: idioma.pivot.proficiencia }),
    };
    setDataToUpdate(data);
    setModalOpen(true);
  }

  function handleDelete(id) {
    setIdToDelete(id);
    setModalDeleteOpen(true);
  }

  return (
    <Container>
      <div className="titulo">
        <h2>Idiomas:</h2>
        <CircleButton onClick={() => openModal()}>
          <FiPlus />
        </CircleButton>
      </div>
      <div className="content">
        <>
          {aluno.idiomas.map(idioma => {
            const { id, nome, pivot } = idioma;

            return (
              <ListITem key={id}>
                <h2>{nome}</h2>
                <p>{pivot.proficiencia}</p>
                <ListItemActions>
                  <SmallCircleButton onClick={() => openModal(idioma)}>
                    <FaPen size={10} />
                  </SmallCircleButton>
                  <SmallCircleButton danger onClick={() => handleDelete(idioma.id)}>
                    <FaTrash size={10} />
                  </SmallCircleButton>
                </ListItemActions>
              </ListITem>
            );
          })}
          <Modal
            open={modalDeleteOpen}
            onClose={() => setModalDeleteOpen(false)}
          >
            <ModalDeleteEdioma
              id={idToDelete}
              onClose={() => setModalDeleteOpen(false)}
            />
          </Modal>
        </>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalEditIdioma
          data={dataToUpdate}
          onClose={() => setModalOpen(false)}
        />
      </Modal>
    </Container>
  );
}
