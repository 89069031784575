import React, { useState, useEffect } from "react";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { Page, DashboardContainer} from "@/components/Grid";

import TCEContent from "./TCE";
import api from "@/services/api";
import { PaineisContainer, Content, PainelPrincipal } from "./styles";

export default function AssinarTCE({ match }) {
  const { tceId, codigo } = match.params;
  const [tce, setTce] = useState(null);

  useEffect(() => {
    fetchTCE(tceId);
  }, []);

  async function fetchTCE() {
    const endpoint = `assinante-tce/${tceId}/${codigo}`;
    const { data: response } = await api.get(endpoint);
    if (response.success) {
      setTce(() => {
        return response.data;
      });
    }
  }

  return (
    <Page>
      <Navbar type="login" />
      {!tce && <FullPageLoader />}
      {tce && (
        <DashboardContainer>
          <PaineisContainer>
            <Content>
              <PainelPrincipal>
                <TCEContent tce={tce} tceId={tceId} codigo={codigo} />
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}
