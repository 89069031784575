import React, { useEffect, useState } from "react";
import { ModalContent, ModalHeader } from "./styles";

import { PDFViewer } from "@react-pdf/renderer";
import Document from "../Document";

export default function ModalAddEntrevista({ onClose, aluno }) {
  const [viewerHeight, setViewerHeight] = useState(
    document.documentElement.clientHeight - 40
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function handleWindowResize(event) {
    setViewerHeight(state => {
      return document.documentElement.clientHeight - 40;
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <h4>Visualização de currículo</h4>
        <div className="actions">
          <button type="button" className="fechar" onClick={onClose}>
            Fechar
          </button>
        </div>
      </ModalHeader>
      <PDFViewer width="100%" height={viewerHeight}>
        <Document aluno={aluno} />
      </PDFViewer>
    </ModalContent>
  );
}
