import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  ListCandidato,
} from "@/components/Empresa/Vagas";

import Paginator, { Loading } from "@/components/Paginator";
import EventEmitter from "@/event-emitter";

import { GroupList } from "../styles";

function CandidatosFavoritos({ vagaId, history, onTotal }) {
  const [candidatos, setCandidatos] = useState([]);
  const [loadingCandidatos, setLoadingCantidatos] = useState(false);
  const [query] = useState({ filters: "favoritos" });

  const paginatorRef = useRef(null);

  function handleOnFetchComplete(data) {
    setCandidatos(data.data);
    if (onTotal) onTotal(data.paginator.total);
  }

  function handleOnLoadingCantidatos(loading) {
    setLoadingCantidatos(loading);
  }

  function renderCandidatosDaVaga() {
    return candidatos.map(candidato => {
      return (
        <ListCandidato
          key={candidato.id}
          data={candidato}
          canToggleFavorito={false}
          onClickMainAction={() => {
            history.push(`/vaga-estudante/${vagaId}/${candidato.id}`);
          }}
        />
      );
    });
  }

  const endpoint = `vagas/${vagaId}/candidatos`;

  useEffect(() => {
    if (paginatorRef && paginatorRef.current) {
      EventEmitter.subscribe("candidato-favorito-toggled", () => {
        setTimeout(function() {
          if (paginatorRef && paginatorRef.current) {
            paginatorRef.current.resetAndFetchData();
          }
        }, 2000);
      });
    }
  }, [paginatorRef]);

  return (
    <>
      <Loading loading={loadingCandidatos}>
        <GroupList>{renderCandidatosDaVaga()}</GroupList>
      </Loading>
      <Paginator
        endpoint={endpoint}
        onFetchComplete={handleOnFetchComplete}
        onLoading={handleOnLoadingCantidatos}
        query={query}
        ref={paginatorRef}
      />
    </>
  );
}

export default withRouter(CandidatosFavoritos);
