import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { ListCandidato } from "@/components/Empresa/Vagas";

import Paginator, { Loading } from "@/components/Paginator";

import { GroupList } from "../styles";

function Candidatos({ vagaId, history, onTotal }) {
  const [candidatos, setCandidatos] = useState([]);
  const [loadingCandidatos, setLoadingCantidatos] = useState(false);
  const [query] = useState({});

  function handleOnFetchComplete(data) {
    setCandidatos(data.data);
    if (onTotal) onTotal(data.paginator.total);
  }

  function handleOnLoadingCantidatos(loading) {
    setLoadingCantidatos(loading);
  }

  function renderCandidatosDaVaga() {
    return candidatos.map(candidato => {
      return (
        <ListCandidato
          key={candidato.id}
          data={candidato}
          onClickMainAction={() => {
            history.push(`/vaga-estudante/${vagaId}/${candidato.id}`);
          }}
        />
      );
    });
  }

  const endpoint = `vagas/${vagaId}/candidatos`;

  return (
    <>
      <Loading loading={loadingCandidatos}>
        <GroupList>{renderCandidatosDaVaga()}</GroupList>
      </Loading>

      <Paginator
        endpoint={endpoint}
        onFetchComplete={handleOnFetchComplete}
        onLoading={handleOnLoadingCantidatos}
        query={query}
      />
    </>
  );
}

export default withRouter(Candidatos);
