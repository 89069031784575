import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import AuthActions from "@/store/ducks/auth";

import { Navbar, Footer } from "@/components/Blocks";
import { Card, Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Page, Row, Col } from "@/components/Grid";
import { EntrarContainer, CardWrapper } from "./styles";

export default function NovaSenha({ history, match }) {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    const { recuperacao_senha } = match.params;
  }, [match]);

  const schema = Yup.object().shape({
    senha: Yup.string().required("Obrigatório"),
    senha_c: Yup.string()
      .required("Obrigatório")
      .test("senha-match", "Confirme sua senha", function(value) {
        return this.parent.senha === value;
      }),
  });

  function handleSubmit(data) {
    const { recuperacao_senha } = match.params;
    const form = { recuperacao_senha, senha: data.senha };
    dispatch(AuthActions.authRecover(form, history));
  }

  return (
    <Page>
      <Navbar type="login" />
      <EntrarContainer>
        <CardWrapper>
          <h1>Esqueci minha senha</h1>
          <p>Informe sua nova senha</p>
          <Card shadow>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input type="password" label="Nova senha:" name="senha" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="password"
                    label="Confirme a nova senha:"
                    name="senha_c"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    variant="magenta"
                    loading={auth.loading}
                    block
                  >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </CardWrapper>
      </EntrarContainer>
      <Footer />
    </Page>
  );
}
