import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { PulseLoader } from "react-spinners";
import { date } from "@/formatters";
import api, { makeOptions } from "@/services/api";
import EventEmitter from "@/event-emitter";

import {
  Container,
  Avatar,
  Tcolumn,
  IconColumn,
  Nome,
  Data,
  Label,
  Info,
  Button,
  Favorito,
} from "./styles";

export default function ListCandidato({
  data,
  onClickMainAction,
  canToggleFavorito = true,
}) {
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [favorito, setFavorito] = useState(data.favorito || false);

  async function handleToggleFavorito() {
    const endpoint = `vagas/${data.vaga_id}/favoritar/${data.id}`;
    setLoading(true);
    const { data: response } = await api.put(
      endpoint,
      {
        favorito: favorito ? 0 : 1,
      },
      makeOptions(user)
    );
    setLoading(false);
    if (response.success) {
      EventEmitter.dispatch("candidato-favorito-toggled");
      setFavorito(response.data.favorito);
    }
  }

  function handleOnClickMainAction() {
    if (onClickMainAction) {
      onClickMainAction();
    }
  }

  function renderFavoritoIcon() {
    if (loading) {
      return <PulseLoader size={8} color="#CE5F5F" />;
    }

    return (
      <Favorito onClick={handleToggleFavorito}>
        {favorito ? (
          <MdFavorite color="#CE5F5F" size={24} />
        ) : (
          <MdFavoriteBorder color="#8B8A95" size={24} />
        )}
      </Favorito>
    );
  }

  return (
    <Container>
      <Avatar url={data.imagem} />
      <Tcolumn>
        <Nome onClick={handleOnClickMainAction}>{data.nome}</Nome>
        <Data>Candidatou-se em {date(data.data_candidatura)}</Data>
      </Tcolumn>
      <Tcolumn className="menor">
        <Label>Curso:</Label>
        <Info>{data.curso.nome}</Info>
      </Tcolumn>
      <Tcolumn className="menor">
        <Label>Escola:</Label>
        <Info>{data.empresa.nome_exibicao}</Info>
      </Tcolumn>
      <IconColumn>
        {(canToggleFavorito && renderFavoritoIcon()) || (
          <MdFavorite color="#CE5F5F" size={24} />
        )}
      </IconColumn>
      <Button onClick={handleOnClickMainAction}>Ver perfil</Button>
    </Container>
  );
}
