import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import { useSupervisores } from "@/hooks/supervisores";
import SupervisoresItem from "./supervisores-item";

export default function SupervisoresList() {
  const { supervisores, loading, deleteSupervisor } = useSupervisores();
  const [deletingId, setDeletingId] = useState(null);

  function handleDeleteSupervisor(supervisorId) {
    setDeletingId(supervisorId);
    deleteSupervisor(supervisorId);
  }

  function renderListaSupervisores() {
    if (loading === true && supervisores.length === 0) {
      return <PulseLoader size={16} color={"#f78764"} />;
    }

    if (supervisores.length === 0) {
      return <></>;
    }

    return supervisores.map((s, index) => {
      return (
        <SupervisoresItem
          key={s.id}
          nome={s.nome}
          cargo={s.cargo}
          telefone={s.telefone}
          email={s.usuario.email}
          onDelete={() => handleDeleteSupervisor(s.id)}
          isDeleting={(deletingId === s.id && loading) || undefined}
        />
      );
    });
  }

  return <>{renderListaSupervisores()}</>;
}
