import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import CreateObservacaoActions from "@/store/ducks/empresas-observacoes/create";

const getAuthData = state => state.auth.data;

export function* create(action) {
  try {
    const { data, onSuccess } = action;
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.post,
      "empresas-observacoes",
      data,
      makeOptions(authData)
    );

    if (response.success) {
      toastify("Entrada no log criada com sucesso");
      if (onSuccess) onSuccess();
      yield put(CreateObservacaoActions.createObservacaoSuccess());
    } else {
      toastify(response.message);
      yield put(CreateObservacaoActions.createObservacaoFailure(response.message));
    }
  } catch (error) {
    toastify("Erro ao adicionar uma entrada ao log");
    yield put(CreateObservacaoActions.createObservacaoFailure("Erro ao adicionar uma entrada ao log"));
  }
}
