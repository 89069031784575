import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const PainelPrincipalTopo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PainelPrincipalTopoTitulo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px #d5d4e0 solid;
  margin-bottom: 48px;

  ul {
    display: flex;

    li {
      font-size: 14px;
      font-weight: 600;
      color: #a09faa;

      &.ativo {
        color: #271946;
      }
    }
    li + li {
      &::before {
        content: ">";
        padding: 0 8px;
      }
    }
  }
`;

export const TituloVaga = styled.h1`
  color: #2e2d38 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
`;

export const CidadeVaga = styled.h5`
  color: #9491b4;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 32px;
`;

export const ControllButons = styled.div`
  margin-bottom: 24px;
  .button-container:not(:first-child) {
    margin-left: 24px;
  }
  .share-container:not(:first-child) {
    margin-left: 24px;
  }

  @media screen and (max-width: 800px) {
    .button-container {
      display: block;
      button {
        width: 100%;
      }
    }
  }
`;

export const ContentBox = styled.div`
  h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #279cd9;
    letter-spacing: 2px;
    margin-bottom: 16px;
    font-weight: 700;
    &.empresa {
      color: #271946;
    }
  }
  margin-bottom: 32px;

  ul {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    list-style: disc;
    padding-left: 16px;

    span.beneficio-valor {
      font-weight: 600;
    }
  }
`;

export const ContentBoxRed = styled(ContentBox)`
  h4 {
    color: #ef3257;
  }
`;

export const ModalContent = styled.div`
  text-align: center;
  max-width: 480px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 4px;
  padding: 42px;
  padding-bottom: 24px;

  h2 {
    color: #271946;
    font-size: 24px;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const ModalFooter = styled.div`
  margin-top: 28px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
`;
