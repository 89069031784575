import React, { useState } from "react";

const EntrevistaContext = React.createContext([{}, () => {}]);

const EntrevistaProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <EntrevistaContext.Provider value={[state, setState]}>
      {props.children}
    </EntrevistaContext.Provider>
  )
}

export { EntrevistaContext, EntrevistaProvider };
