import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Paginator, { Loading } from "@/components/Paginator";
import { Row, Col } from "@/components/Grid";
import { Input, Select, Form } from "@/components/Form";
import { Button } from "@/components/Common";
import api from "@/services/api";

import EmpresaCursosActions from "@/store/ducks/empresas/cursos";

import CursoItem from "./CursoItem";
import { Label, ActionsContainer } from "./styles";

function ListarCursos({ empresaId }) {
  const dispatch = useDispatch();
  const datagridRef = useRef();

  const { loading: loadingCursoEmpresa, cursoId } = useSelector(
    state => state.cursoEmpresa
  );
  const [cursos, setCursos] = useState([]);
  const [cursosLoading, setCursosLoading] = useState(false);
  const [query, setQuery] = useState({
    nivel_id: "todos",
    status: "todos",
  });
  const [paginator, setPaginator] = useState(null);
  const [optionsNiveis, setOptionsNiveis] = useState([]);

  async function fetchNiveis() {
    const { data: response } = await api.get("utils/niveis");
    if (response.success) {
      setOptionsNiveis(() => {
        const options = response.data.map(nivel => {
          return { id: nivel.id, title: nivel.nome };
        });
        return [{ id: "todos", title: "Todos" }, ...options];
      });
    }
  }

  function handleFetchComplete(data) {
    setCursos(data.data);
    setPaginator(data.paginator);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function renderLista() {
    if (paginator && paginator.total === 0) {
      return (
        <Label>Não foram encontrados cursos com os parâmetros escolhidos</Label>
      );
    }
    return cursos.map(curso => {
      return <CursoItem key={curso.id} curso={curso} empresaId={empresaId} />;
    });
  }

  function handleAddAll() {
    dispatch(
      EmpresaCursosActions.empresaCursoAddTodosRequest(empresaId, function() {
        reloadDatagrid();
      })
    );
  }

  function handleRemoveAll() {
    dispatch(
      EmpresaCursosActions.empresaCursoRemoveTodosRequest(
        empresaId,
        function() {
          reloadDatagrid();
        }
      )
    );
  }

  function reloadDatagrid() {
    setCursos([]);
    setPaginator(null);
    if (datagridRef.current) {
      datagridRef.current.resetAndFetchData();
    }
  }

  useEffect(() => {
    fetchNiveis();
  }, []);

  return (
    <>
      <Form initialData={query}>
        <Row hascols>
          <Col>
            <Input
              name="nome"
              label="Curso"
              placeholder="Busque pelo nome do curso"
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Select
              label="Nível"
              name="nivel_id"
              options={optionsNiveis}
              onChange={handleChange}
              value={query.nivel_id}
            />
          </Col>
          <Col>
            <Select
              label="Status"
              name="status"
              options={[
                { id: "todos", title: "Todos" },
                { id: "ativos", title: "Cursos ativos" },
                { id: "inativos", title: "Cursos inativos" },
              ]}
              onChange={handleChange}
              value={query.status}
            />
          </Col>
        </Row>
      </Form>

      <ActionsContainer>
        <Button
          variant="cyan"
          disabled={cursosLoading || loadingCursoEmpresa}
          loading={loadingCursoEmpresa && cursoId === "add-all"}
          onClick={handleAddAll}
          >
          Adicionar todos
        </Button>
        <Button
          variant="magenta"
          disabled={cursosLoading || loadingCursoEmpresa}
          loading={loadingCursoEmpresa && cursoId === "remove-all"}
          onClick={handleRemoveAll}
        >
          Remover todos
        </Button>
      </ActionsContainer>

      <Loading loading={cursosLoading}>
        {renderLista()}
        <Paginator
          ref={datagridRef}
          endpoint={`empresas/${empresaId}/cursos`}
          query={query}
          onFetchComplete={handleFetchComplete}
          onLoading={l => setCursosLoading(l)}
        />
      </Loading>
    </>
  );
}

export default withRouter(ListarCursos);
