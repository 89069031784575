import React, { useState } from "react";
import { format } from "date-fns";
import { FiPlus } from "react-icons/fi";
import { FaPen, FaTrash } from "react-icons/fa";

import { useAluno } from "@/hooks/alunos";
import { Modal } from "@/components/Common";

import ModalEditFormacaoAtual from "./ModalEditFormacaoAtual";
import ModalAddFormacaoAnterior from "./ModalAddFormacaoAnterior";
import ModalEditFormacaoAnterior from "./ModalEditFormacaoAnterior";
import ModalDeleteFormacaoAnterior from "./ModalDeleteFormacaoAnterior";

import {
  CircleButton,
  SmallCircleButton,
  Container,
  EscolaAtual,
  Avatar,
  FormacaoAnterior,
  FormacoesAnteriores,
} from "./styles";

import avatarDefaultEscola from "@/assets/images/avatar-default-escola.png";

export default function Formacoes() {
  const { aluno } = useAluno();
  // formação atual
  const [modalEditAtualOpen, setModalEditAtualOpen] = useState(false);
  // adicionar e editar formações anteriores
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

  const [dataToUpdate, setDataToUpdate] = useState({});
  const [formacaoAnterior, setFormacaoAnterior] = useState({});

  const [idToDelete, setIdToDelete] = useState(null);

  function updateFormacaoAnterior(formacao) {
    setFormacaoAnterior(() => {
      return formacao;
    });
    setModalEditOpen(true);
  }

  function updateFormacaoAtual() {
    const formacaoAtual = {
      nivel_id: aluno.curso.nivel.id,
      curso_id: [{ id: aluno.curso.id, title: aluno.curso.nome }],
      empresa_id: [
        { id: aluno.empresa.id, title: aluno.empresa.nome_exibicao },
      ],
      data_inicio: aluno.data_inicio,
      data_conclusao: aluno.data_conclusao,
    };
    setDataToUpdate(formacaoAtual);
    setTimeout(() => {
      setModalEditAtualOpen(true);
    }, 200);
  }

  function renderFormacaoAtual() {
    const dataInicio = format(
      new Date(`${aluno.data_inicio} 00:00:00`),
      "MM/yyyy"
    );
    const dataTermino = format(
      new Date(`${aluno.data_conclusao} 00:00:00`),
      "MM/yyyy"
    );

    return (
      <EscolaAtual>
        <Avatar src={aluno.empresa.imagem} />
        <div className="info">
          <div className="info-actions">
            <SmallCircleButton onClick={() => updateFormacaoAtual()}>
              <FaPen size={10} />
            </SmallCircleButton>
          </div>

          <div className="info-titulo">
            <h2>{aluno.curso.titulo}</h2>
            {aluno.empresa.nome_exibicao}
          </div>

          <div className="info-datas">
            <div>
              <span>Início</span> {dataInicio}
            </div>
            <div>
              <span>Previsão de término</span> {dataTermino}
            </div>
          </div>
        </div>
      </EscolaAtual>
    );
  }

  function renderFormacoesAnteriores() {
    function handleDelete(id) {
      setIdToDelete(id);
      setModalDeleteOpen(true);
    }

    return (
      <FormacoesAnteriores>
        <>
          {aluno.formacoes_anteriores.map(f => {
            const dataInicio = format(new Date(f.data_inicio), "MM/yyyy");
            const dataTermino = format(new Date(f.data_termino), "MM/yyyy");

            return (
              <FormacaoAnterior key={f.id}>
                <Avatar src={avatarDefaultEscola} />
                <div className="info">
                  <h2>{f.curso_nome}</h2>
                  {f.empresa_nome}
                  <div className="info-datas">
                    <div>
                      <span>Início</span> {dataInicio}
                    </div>
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  </div>
                  <div className="info-actions">
                    <SmallCircleButton
                      onClick={() => updateFormacaoAnterior(f)}
                    >
                      <FaPen size={10} />
                    </SmallCircleButton>
                    <SmallCircleButton
                      danger
                      onClick={() => handleDelete(f.id)}
                    >
                      <FaTrash size={10} />
                    </SmallCircleButton>
                  </div>
                </div>
              </FormacaoAnterior>
            );
          })}
          <Modal
            open={modalDeleteOpen}
            onClose={() => setModalDeleteOpen(false)}
          >
            <ModalDeleteFormacaoAnterior
              id={idToDelete}
              onClose={() => setModalDeleteOpen(false)}
            />
          </Modal>
        </>
      </FormacoesAnteriores>
    );
  }

  return (
    <Container>
      <div className="titulo">
        <h2>Formação acadêmica: </h2>
        <CircleButton onClick={() => setModalAddOpen(true)}>
          <FiPlus />
        </CircleButton>
      </div>
      <div className="content">
        <>
          {renderFormacaoAtual()}
          {renderFormacoesAnteriores()}
        </>
      </div>

      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)}>
        <ModalAddFormacaoAnterior onClose={() => setModalAddOpen(false)} />
      </Modal>

      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditFormacaoAnterior
          data={formacaoAnterior}
          onClose={() => setModalEditOpen(false)}
        />
      </Modal>

      <Modal
        open={modalEditAtualOpen}
        onClose={() => setModalEditAtualOpen(false)}
      >
        <ModalEditFormacaoAtual
          data={dataToUpdate}
          onClose={() => setModalEditAtualOpen(false)}
        />
      </Modal>
    </Container>
  );
}
