import styled from "styled-components";

export const CardContainer = styled.div`
  border-radius: 5px;
  background-color: #fff;
  padding: 54px;
  height: 100%;

  @media screen and (max-width: 800px) {
    padding: 2rem;
  }

  ${props => ({ flex }) => {
    return flex !== undefined
      ? `
      display: flex;
      flex-direction: column;
      `
      : `
      display: block;
      `;
  }};

  ${props => ({ shadow }) => {
    return shadow !== undefined
      ? `box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08)`
      : ``;
  }};
`;
