import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import DeleteEmpresaActions from "@/store/ducks/empresas/delete";

const getAuthData = state => state.auth.data;

export function* remove(action) {
  const { id, history, modo } = action;
  try {
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.delete,
      `empresas/${id}`,
      makeOptions(authData)
    );
    if (response.success) {
      if (modo === "empresa") {
        history.push("/empresas/todas");
        toastify("Empresa removida com sucesso");
      } else {
        history.push("/escolas/todas");
        toastify("Escola removida com sucesso");
      }
      yield put(DeleteEmpresaActions.deleteEmpresaSuccess());
    } else {
      toastify(response.message, "error");
      yield put(DeleteEmpresaActions.deleteEmpresaFailure(response.message));
    }
  } catch (error) {
    if (modo === "empresa") {
      toastify("Erro ao tentar deletar empresa", "error");
    } else {
      toastify("Erro ao tentar deletar escola", "error");
    }
    yield put(
      DeleteEmpresaActions.deleteEmnpresaFailure(
        "Erro ao tentar deletar empresa"
      )
    );
  }
}
