import styled from "styled-components";

export const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
`;

export const Checkbox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const Error = styled.span`
  position: absolute;
  top: 0px;
  right: 0;
  color: #DB4C81;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin-right: 12px;
`;

export const DiaContainer = styled.div`
  font-weight: 600;
`;

export const Erro = styled.div`
  color: #DB4C81;
`;
