import styled from "styled-components";

import iconArrowLeftLight from "@/assets/images/icons/arrow-left-light.png";
import iconArrowLeftDark from "@/assets/images/icons/arrow-left-dark.png";
import iconArrowLeftCyan from "@/assets/images/icons/arrow-left-cyan.png";
import iconArrowLeftMagenta from "@/assets/images/icons/arrow-left-magenta.png";
import iconArrowLeftTangerine from "@/assets/images/icons/arrow-left-tangerine.png";
import iconArrowLeftDeepblue from "@/assets/images/icons/arrow-left-deepblue.png";

import iconForwardLight from "@/assets/images/icons/forward-light.png";
import iconForwardDark from "@/assets/images/icons/forward-dark.png";
import iconForwardCyan from "@/assets/images/icons/forward-cyan.png";
import iconForwardMagenta from "@/assets/images/icons/forward-magenta.png";
import iconForwardTangerine from "@/assets/images/icons/forward-tangerine.png";
import iconForwardDeepblue from "@/assets/images/icons/forward-deepblue.png";

export const Container = styled.span.attrs({
  className: "button-container",
})`
  button,
  a {
    transition: 300ms ease;

    &:hover {
      ${props => ({ variant }) => {
        const [_color, _outline] = variant.split("-");

        if (_outline !== undefined && _color === "light") {
          return `background: ${theme.colors[_color]}; color: ${theme.colors.deepblue};`;
        }

        if (_outline !== undefined && _color !== "light") {
          return `background: ${theme.colors[_color]}; color: ${theme.colors.light};`;
        }

        return `opacity: 0.7;`;
      }}
    }

    background-color: ${props => ({ variant }) => {
      const [_color, _outline] = variant.split("-");
      if (_outline !== undefined || _color === "cancel") return "transparent";
      return theme.colors[_color];
    }};
    border-color: ${props => ({ variant }) => {
      const [_color, _outline] = variant.split("-");
      if (_color === "cancel") return "transparent";
      return theme.colors[_color];
    }};
    color: ${props => ({ variant }) => {
      const [_color, _outline] = variant.split("-");
      if (_color === "cancel") return theme.colors.deepblue;
      if (_outline !== undefined) return theme.colors[_color];
      return theme.colors.light;
    }};

    text-align: center;
    width: ${props => (props.block ? "100%" : "auto")};
    border-width: 2px;
    border-style: solid;
    padding: 14px 30px;
    text-decoration: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 17px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;

    ${props => ({ icon, block, variant }) => {
      if (icon === undefined) return ``;

      const [_color, _outline] = variant.split("-");

      let _iconName;

      if (_outline === undefined || _color === "light") {
        _iconName = `${icon}-light`;
      } else {
        _iconName = `${icon}-${_color}`;
      }

      const _icon = theme.icons[_iconName];

      return `
        background-image: url(${_icon});
        background-repeat: no-repeat;
        background-position: center left 20px;
        padding-left: ${block ? "20px" : "50px"};
      `;
    }}

    ${props => ({ disabled }) => {
      if (disabled !== undefined)
        return `
          cursor: no-drop;
          opacity: .8;
        `;
    }}
  }

  button {
    display: ${props => (props.block ? "block" : "initial")};
  }

  a {
    display: ${props => (props.block ? "block" : "inline-block")};
  }
`;

export const theme = {
  colors: {
    dark: "#414141",
    jeans: "#7294A5",
    light: "#FFFFFF",
    cyan: "#3FBCDB",
    magenta: "#DB4C81",
    tangerine: "#FF987F",
    deepblue: "#2B0C75",
    red: "#c0392b",
    integradorBlue: "#4d65b3",
    integradorYellow: "#fbca01",
  },
  icons: {
    // arrow-left
    "arrow-left-light": iconArrowLeftLight,
    "arrow-left-dark": iconArrowLeftDark,
    "arrow-left-cyan": iconArrowLeftCyan,
    "arrow-left-magenta": iconArrowLeftMagenta,
    "arrow-left-tangerine": iconArrowLeftTangerine,
    "arrow-left-deepblue": iconArrowLeftDeepblue,
    // forward
    "forward-light": iconForwardLight,
    "forward-dark": iconForwardDark,
    "forward-cyan": iconForwardCyan,
    "forward-magenta": iconForwardMagenta,
    "forward-tangerine": iconForwardTangerine,
    "forward-deepblue": iconForwardDeepblue,
  },
};
