import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { useTce } from "@/hooks/tces";
import { ModalContent, ModalFooter } from "./styles";
import { checkMinhaVez } from "../check-minha-vez";
import { format, addDays } from "date-fns";

export default function ModalAceite({ onClose, tce, onAceiteComplete }) {
  const { data: user } = useSelector(state => state.auth);
  const { aceitarTce, loading } = useTce();
  const [formData] = useState({});
  // const [errorCoordenadores, setErrorCoordenadores] = useState(null);

  const minhaVez = checkMinhaVez(tce, user);
  const showDatas =
    minhaVez === 0 &&
    tce.status.descricao === "Aguardando aceite da empresa concedente";

  const schema = showDatas
    ? Yup.object().shape({
        vigencia_inicio: Yup.date()
          // .min(
          //   addDays(new Date(), 0),
          //   "A data deve ser pelo menos 1 dia a frente"
          // )
          .typeError("Data inválida")
          .required("Obrigatório"),
      })
    : Yup.object().shape({});

  useEffect(() => {
    if (!loading) {
      onClose();
    }
  }, [loading]);

  async function handleSubmit(data = {}) {
    if (!showDatas) {
      aceitarTce(tce.id, {}, onAceiteComplete);
    }

    if (showDatas) {
      aceitarTce(
        tce.id,
        {
          vigencia_inicio: format(data.vigencia_inicio, "yyyy-MM-dd"),
        },
        onAceiteComplete
      );
    }
  }

  function renderDatas() {
    return showDatas ? (
      <Row>
        <Col>
          <Input
            type="date"
            max="2099-01-01"
            // min={format(addDays(new Date(), 1), "yyyy-MM-dd")}
            label="Data de início de vigência do contrato"
            name="vigencia_inicio"
          />
        </Col>
      </Row>
    ) : (
      <></>
    );
  }

  return (
    <ModalContent>
      <h2>Tem certeza de que deseja aceitar este contrato?</h2>
      <p>
        VOCÊ ESTARÁ ASSINANDO ELETRÔNICAMENTE ESTE DOCUMENTO, DE ACORDO COM OS
        ARTIGOS 3º, 10º E 18º DA LEI 13.874/19 - DECLARAÇÃO DE DIREITOS DE
        LIBERDADE ECONÔMICA E DE ACORDO COM A MEDIDA PROVISÓRIA 2.200-2/01
      </p>
      <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
        <>{renderDatas()}</>
        <ModalFooter>
          <Button
            type="submit"
            block
            variant="tangerine"
            loading={loading || undefined}
          >
            Aceitar
          </Button>
          <Button
            type="button"
            block
            variant="cancel"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
