import React, { useState, useEffect, useCallback } from "react";
import api from "@/services/api";
import { Container, Iframe } from "./styles";

export default function Documento({ tceId, height }) {
  const [pdfData, setPdfData] = useState(null);

  const fetchPdfCB = useCallback(async () => {
    const { data } = await api.get(`files/tce-pdf/${btoa(tceId)}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    setPdfData({ PDF: file, displayPDF: fileUrl });
  }, [tceId, setPdfData]);

  useEffect(() => {
    fetchPdfCB();
  }, [fetchPdfCB]);

  return (
    <Container >
      {pdfData && <Iframe height={height} src={pdfData.displayPDF}></Iframe>}
    </Container>
  );
}
