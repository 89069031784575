import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";
import { Row, Col } from "@/components/Grid";
import { Input } from "@/components/Form";
import Paginator, { Loading } from "@/components/Paginator";

import EventEmitter from "@/event-emitter";

import {
  VagaContainer,
  Candidatos,
  TotalResult,
  BuscarVagas,
  Entrevistas,
} from "./styles";

function ListarVagas({ type = "abertas", entrevista, history }) {
  const [vagas, setVagas] = useState([]);
  const [loadingVagas, setLoadingVagas] = useState(false);
  const [totalVagas, setTotalVagas] = useState(null);
  const [query, setQuery] = useState({ count: 3 });
  const paginatorRef = useRef();

  useEffect(() => {
    EventEmitter.subscribe("vaga-added", event =>
      handlePaginatorResetAndFetchData(event)
    );
  }, []);

  function handlePaginatorResetAndFetchData(event) {
    if (paginatorRef.current) {
      paginatorRef.current.resetAndFetchData();
    }
  }

  function handleOnFetchComplete(data) {
    setVagas(data.data);
    setTotalVagas(data.paginator.total);
  }

  function handleOnLoading(loading) {
    setLoadingVagas(loading);
  }

  function handleOpenVaga(vagaId) {
    history.push(`/vaga/${vagaId}`);
  }

  function renderVagas() {
    return (
      <>
        {totalVagas != null && (
          <TotalResult>{totalVagas} vagas cadastradas</TotalResult>
        )}
        <Loading loading={loadingVagas}>
          <Row hascols>
            {vagas.map(vaga => {
              return (
                <Col xl="1/3" lg="1/2" md="1" sm="1" key={vaga.id}>
                  <VagaContainer
                    type={type}
                    onClick={() => handleOpenVaga(vaga.id)}
                  >
                    <div className="body">
                      <h4 className="tipo">
                        {type === "fechadas"
                          ? "Vaga finalizada"
                          : "Vaga aberta"}
                      </h4>
                      <h3 className="titulo">{vaga.titulo}</h3>
                      <h5 className="local">
                        {vaga.cidade.nome}/{vaga.cidade.estado.sigla}
                      </h5>
                    </div>
                    <div className="footer">
                      {entrevista ? (
                        <Entrevistas>
                          <FaChevronCircleRight />
                          16 Entrevistas
                        </Entrevistas>
                      ) : (
                        <Candidatos candidatos={false}>
                          <FaChevronCircleRight />
                          16 Candidatos
                        </Candidatos>
                      )}
                    </div>
                  </VagaContainer>
                </Col>
              );
            })}
          </Row>
        </Loading>
      </>
    );
  }

  return (
    <>
      <BuscarVagas>
        <Row hascols>
          <Col>
            <Input
              name="buscar"
              label="Buscar vagas"
              placeholder="Digite o nome da vaga"
              rounded
            />
          </Col>
        </Row>
      </BuscarVagas>

      {renderVagas()}
      <Paginator
        endpoint="vagas"
        onFetchComplete={handleOnFetchComplete}
        onLoading={handleOnLoading}
        query={query}
        ref={paginatorRef}
      />
    </>
  );
}

export default withRouter(ListarVagas);
