import styled from "styled-components";

import caretBlack from "@/assets/images/icons/caret-black.png";
import caretWhite from "@/assets/images/icons/caret-white.png";

const containerColors = {
  bgLogin: "#000",
  bgInterno: "#000",
  bgHome: "transparent",
};

const menuColors = {
  handlerColorHome: "#4d65b3",
  handlerColorLogin: "#FFF",
};

export const Container = styled.div`
  background-color: ${props => {
    switch (props.type) {
      case "home":
        return containerColors.bgHome;
      case "login":
        return containerColors.bgLogin;
      default:
        return containerColors.bgInterno;
    }
  }};
  width: 100%;
  height: auto;
  height: ${props =>
    ["home", "login"].includes(props.type) ? "85px" : "55px"};
  margin-bottom: ${props =>
    ["home", "login"].includes(props.type) ? "-85px" : "-55px"};
  display: flex;
  align-items: center;
  position: ${props => (props.type == "home" ? "relative" : "sticky")};
  top: 0;
  z-index: 99;
`;

export const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Menus

export const MenusArea = styled.ul`
  display: flex;
  margin-right: ${props => (props.type === "home" ? "auto" : 0)};
  margin-bottom: 0;
  padding: 0 20px;
  > li {
    list-style-type: none;
    padding-right: 15px;
    &.has-caret {
      ${props =>
        props.type === "home"
          ? `background-image: url(${caretBlack})`
          : `background-image: url(${caretWhite})`};
      background-position: center right 1px;
      background-repeat: no-repeat;
    }
    &:not(:last-child) {
      margin-right: 34px;
    }
    a {
      font-size: 16px;
      color: ${menuColors.handlerColorLogin};
      text-decoration: none;
      transition: 300ms ease;

      &.inicio {
        text-transform: uppercase;
        font-weight: 600;

        &:hover {
          color: #f78764;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const MenuHandler = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: ${props =>
    props.type === "home"
      ? menuColors.handlerColorHome
      : menuColors.handlerColorLogin};
  cursor: pointer;
  transition: 300ms ease;

  &:hover {
    color: #000;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 20px 18px;
  border-radius: 0 0 6px 6px;
  margin-bottom: 0;
  background-color: #fff;
  width: 200px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  margin-top: 35px;
  pointer-events: all;

  &::after {
    position: absolute;
    top: -10px;
    right: 20px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
  }
`;

export const MenuItem = styled.li`
  list-style-type: none;
  text-align: right;
  padding: 12px 0;
  border-bottom: 1px #ddd solid;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }

  a {
    text-decoration: none;
    color: #000 !important;
    font-size: 16px;
    font-weight: 600;
    transition: 300ms ease;

    &:hover {
      color: #4d65b3 !important;
    }
  }
`;
