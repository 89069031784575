import React from "react";
import { Navbar } from "@/components/Blocks";

import PDFfile from "./dummy.pdf";

import { Page, Header, HeaderTitle, TermosContent } from "./styles";

export default function TermosDeUso() {
  return (
    <Page>
      <Navbar type="login" />
      <Header>
        <HeaderTitle>Termos de Uso</HeaderTitle>
      </Header>
      <TermosContent>
      <object
          data={PDFfile}
          type="application/pdf"
          width="1000px"
          height="1000px"
        >
          <p>
            Clique aqui para baixar{" "}
            <a href={PDFfile} download>
              os termos de uso!
            </a>
          </p>
        </object>
      </TermosContent>
    </Page>
  );
}
