import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from "@/components/Common";
import { useTce } from "@/hooks/tces";
import { usePrevious } from "@/hooks";

import { ModalContent, ModalFooter } from "./styles";

export default function ModalSolicitarDesligamento({ tce, onClose }) {
  const { data: user } = useSelector(state => state.auth);
  const { solicitarDesligamento, loading } = useTce();
  const prevLoading = usePrevious(loading);

  const [possiveisSolicitantes, setPossiveisSolicitantes] = useState([]);

  function handleSolicitar(solicitante) {
    solicitarDesligamento(tce.id, { solicitante });
  }

  const setPossiveisSolicitantesCB = useCallback(() => {
    const lista = [];

    if (user.tipo === "aluno") lista.push("contratado");

    if (
      user.tipo === "funcionario" &&
      user.empresa.id === tce.contrato.contratado_escola.id
    ) {
      lista.push("contratado_escola");
    }
    if (
      user.tipo === "funcionario" &&
      user.empresa.id === tce.contrato.contratante.id
    ) {
      lista.push("contratante");
    }

    setPossiveisSolicitantes(lista);
  }, [setPossiveisSolicitantes, user]);

  useEffect(() => {
    if (prevLoading && !loading) onClose();
  }, [prevLoading, loading, onClose]);

  useEffect(() => {
    if (tce) {
      setPossiveisSolicitantesCB();
    }
  }, [setPossiveisSolicitantesCB, tce]);

  return (
    <ModalContent>
      <h2>Solicitação de desligamento</h2>
      <p>Deseja mesmo solicitar o desligamento deste estágio?</p>
      <ModalFooter>
        <Button block variant="cancel" onClick={() => onClose()}>
          Cancelar
        </Button>
        {(possiveisSolicitantes.length > 1 && (
          <>
            <Button
              block
              variant="magenta"
              onClick={() => handleSolicitar("contratante")}
              loading={loading || undefined}
            >
              Solicitar desligamento como contratante
            </Button>
            <Button
              block
              variant="magenta"
              onClick={() => handleSolicitar("contratado_escola")}
              loading={loading || undefined}
            >
              Solicitar desligamento como representante da escola do estudante
            </Button>
          </>
        )) || (
          <Button
            block
            variant="magenta"
            onClick={() => handleSolicitar(possiveisSolicitantes[0])}
            loading={loading || undefined}
          >
            Solicitar desligamento
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  );
}
