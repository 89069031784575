import styled from "styled-components";

export const AceiteContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin-right: 12px;
`;

export const Message = styled.div`
  span {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  max-width: 600px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 4px;
  padding: 42px;

  h2 {
    color: #271946;
    font-size: 24px;
    margin-bottom: 24px;
  }
`;
