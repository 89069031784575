import styled from "styled-components";

export const Aluno = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  padding: 24px;
  .coluna {
    &-avatar {
      flex: 0 0 60px;
    }
    &-nome {
      flex: 0 0 240px;
      font-size: 16;
      font-weight: 600;
      color: #423B8D;
    }
    &-curso {
      h4 {
        color: #271946;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;

      }
      flex: 1 0 200px;
      font-size: 16px;
    }
    &-actions {
      margin-left: auto;
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  /* width: 48px; */
  height: 48px;
  transition: 300ms ease;

  span {
    color: #279CD9;
    font-size: 14px;
    padding-left: 6px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

export const Avatar = styled.div.attrs({
  className: "avatar",
})`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 36px;
  margin-right: 8px;
  transition: 300ms ease;
  background-color: #CCC;
  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;
`;
