import React from "react";
import { Button } from "@/components/Common";
import { useTce } from "@/hooks/tces";
import { Container, Assinante } from "./styles";
import toastify from "@/services/toastify";

export default function AssinantesEscola({ tce }) {
  const { assinanteReenviarEmail, assinanteRemover, loading } = useTce();

  function renderAssinantes() {
    const { assinantes_escola } = tce;

    if (assinantes_escola.length === 0) {
      return (
        <p>Adicione assinantes clicando em "Adicionar outro assinante".</p>
      );
    }

    return assinantes_escola.map(assinante_escola => {
      let status = "Aguardando";
      if (assinante_escola.data_assinatura != null) status = "Aprovado";
      if (assinante_escola.deleted_at != null) status = "Reprovado";

      const { assinante, codigo } = assinante_escola;

      const linkAssinatura = `https://integrador.app/assinante-aceitar-tce/${tce.id}/${codigo}`;
      
      const handleCopyClick = async () => {
        try {
          await navigator.clipboard.writeText(linkAssinatura);
          toastify("Link copiado para a área de transferência");
          // setIsCopied(true);
        } catch (err) {
          console.error('Erro ao copiar para a área de transferência: ', err);
        }
      };

      return (
        <Assinante status={status}>
          <div className="coluna coluna-nome">
            <h4>
              {assinante.nome} {assinante.sobrenome}
            </h4>
            <p>{assinante.email}</p>
          </div>
          <div className="coluna coluna-status">
            <h4>Status</h4>
            <p>{status}</p>
          </div>
          <div className="coluna coluna-actions">
            <Button
              variant="magenta"
              disabled={status !== "Aguardando"}
              loading={loading || undefined}
              onClick={() => {
                assinanteRemover(assinante_escola.id);
              }}
            >
              Remover
            </Button>
            <Button
              variant="cyan"
              disabled={status !== "Aguardando"}
              loading={loading || undefined}
              onClick={() => {
                assinanteReenviarEmail(tce.id, assinante_escola);
              }}
            >
              Reenviar
            </Button>
            <Button
              variant="cyan"
              disabled={status !== "Aguardando"}
              loading={loading || undefined}
              onClick={() => {
                handleCopyClick();
              }}
            >
              Copiar link
            </Button>
          </div>
        </Assinante>
      );
    });
  }

  return (
    <Container>
      <h2>Aprovação dos assinantes da escola</h2>
      {renderAssinantes()}
    </Container>
  );
}
