import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  border-bottom: 1px solid #B9B7CC;
  h4 {
    color: #F78764;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  h2 {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 14px;
  }
  .breadcrumbs {
    color: #A09FAA;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 28px;
    span {
      color: #279CD9;
    }
  }
`;

export const FiltroBasico = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  .main {
    flex: 1 1 100%;
  }
  .mais {
    flex: 1 1 124px;
  }
`;

export const FilterButtonContainer = styled.div.attrs({
  className: "filter-button-container"
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const Totais = styled.div`
  margin-bottom: 24px;
  span {
    font-weight: 600;
  }
`;

export const EmpresaListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 90px;
  padding: 0 32px;

  .coluna span {
    font-weight: 600;
  }

  .coluna {
    &-nome {
      flex: 0 1 250px;
      h4 {
        color: #423B8D;
        font-size: 16px;
      }
    }
    &-responsavel {
      flex: 0 1 250px;
      h4 {
        color: #271946;
        font-size: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    &-local {
      flex: 0 1 200px;
      h4 {
        color: #F78764;
        font-size: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    &-status {
      flex: 0 1 200px;
      text-transform: capitalize;
      h4 {
        color: #F78764;
        font-size: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    &-actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding: 20px;
    .coluna {
      &-nome, &-responsavel, &-local, &-status, &-actions {
        flex: 1 0 100%;
        padding: 8px 0;
      }
      &-actions {
        margin-left: 0px;
        align-self: stretch;
        justify-content: space-between;
      }
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  /* width: 48px; */
  height: 48px;
  transition: 300ms ease;

  span {
    color: #279CD9;
    font-size: 14px;
    padding-left: 6px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    transform: scale(0.9);
  }
`;
