import * as Yup from "yup";

const createSchema = Yup.object().shape({
  nome: Yup.string().required("Obrigatório"),
  cargo: Yup.string().required("Obrigatório"),
  cpf: Yup.string().required("Obrigatório"),
  telefone: Yup.string().required("Obrigatório"),
  usuario: Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    email_c: Yup.string()
      .email("E-mail inválido")
      .test("email-match", "Confirme seu email", function(value) {
        return this.parent.email === value;
      }),
    // senha: Yup.string().required("Obrigatório"),
    // senha_c: Yup.string()
    //   .required("Obrigatório")
    //   .test("senha-match", "Confirme sua senha", function(value) {
    //     return this.parent.senha === value;
    //   }),
  }),
});

export { createSchema };
