import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FiMapPin, FiBriefcase } from "react-icons/fi";
import { Modal } from "@/components/Common";
import { useAluno } from "@/hooks/alunos";
import { ModalEditAvatar } from "@/components/Aluno";
import ModalContato from "@/components/Empresa/ModalContato";
import { SidebarContainer, Avatar } from "./styles";

export default function Sidebar() {
  const { data: user } = useSelector(state => state.auth);
  const { aluno, loading } = useAluno();
  const [modalEditAvatarOpen, setModalEditAvatarOpen] = useState(false);
  const [modalShowFaleConosco, setModalShowFaleConosco] = useState(false);

  function souEu() {
    return user.tipo === "aluno" && user.perfil_id === aluno.id;
  }

  return (
    <SidebarContainer>
      {aluno && (
        <>
          <Avatar
            editable={souEu()}
            src={aluno.imagem}
            onClick={() => {
              if (souEu()) {
                setModalEditAvatarOpen(true);
              }
            }}
          />
          <h2>{aluno.nome}</h2>
          <div className="actions">
            <button
              type="button"
              className="editar"
              onClick={() => setModalShowFaleConosco(true)}
            >
              Fale Conosco
            </button>
          </div>
          <div className="info">
            <h5>
              <FiMapPin />
              {aluno.endereco.cidade.nome} /{" "}
              {aluno.endereco.cidade.estado.sigla}
            </h5>
            <h5>
              <FiBriefcase />
              {aluno.curso.titulo}
            </h5>
          </div>
          <Modal
            open={modalEditAvatarOpen}
            onClose={() => setModalEditAvatarOpen(false)}
          >
            <ModalEditAvatar onClose={() => setModalEditAvatarOpen(false)} />
          </Modal>
          <Modal
            open={modalShowFaleConosco}
            onClose={() => setModalShowFaleConosco(false)}
          >
            <ModalContato OnClose={() => setModalShowFaleConosco(false)} />
          </Modal>
        </>
      )}
    </SidebarContainer>
  );
}
