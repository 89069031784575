import React from "react";
import { Route, Redirect } from "react-router-dom";

import EscolaAlunos from "@/pages/Escola/Alunos/Alunos";
import AdminAlunos from "@/pages/Admin/Alunos/Alunos";
import Login from "@/pages/Auth/Login";

export default function AlunosRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch (true) {
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      Component = EscolaAlunos;
      break;
    case tipo === "administrador": Component = AdminAlunos;
      break;
    default: Component = Login; // enviar para uma rota neutra para evitar problemas
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
