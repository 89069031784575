import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Schemas de validação
import { schemaStep1, schemaStep2, schemaStep3 } from "./schemas";

// Redux
import CreateEmpresaActions from "@/store/ducks/empresas/create";
import ConsultaCnpjActions from "@/store/ducks/utils/consulta-cnpj";
import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import SelectCidadesActions from "@/store/ducks/cidades/select-cidades";

// UI
import { Navbar, Footer } from "@/components/Blocks";
import { Button } from "@/components/Common";
import { Page, Row, Col } from "@/components/Grid";
import {
  Form,
  Scope,
  Input,
  InputMask,
  Select,
  Stepper,
  Step,
} from "@/components/Form";
import { SectionForm, SubHeader } from "@/components/PageComponents/Cadastro";
import AceiteTermos from "@/components/AceiteTermos";
import Autorizo from "@/components/Autorizo";
import { estados, tipos_pessoa } from "@/data";

export default function CadastroEmpresa({ history }) {
  // state
  const [formData, setFormData] = useState({
    escola: 0,
    endereco: {},
  });
  const [formStep, setFormStep] = useState(1);
  const [tipoPessoa, setTipoPessoa] = useState("J");
  const [aceite, setAceite] = useState(false);
  const [autorizo, setAutorizo] = useState(false);
  const [inputCnpj, setInputCnpj] = useState(null);
  const [estadoId, setEstadoId] = useState(null);

  // options para o select de UF
  const options_estados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });

  // state do redux
  const createEmpresa = useSelector(state => state.createEmpresa);
  const consultaCnpj = useSelector(state => state.consultaCnpj);
  const consultaCep = useSelector(state => state.consultaCep);
  const { data: cidades, loading: cidadesLoading } = useSelector(
    state => state.selectCidades
  );

  // Dispatcher
  const dispatch = useDispatch();

  // form handlers
  const _handleSubmit1 = data => _updateForm(data, 2);
  const _handleSubmit2 = data => _updateForm(data, 3);
  const _handleSubmit3 = data => _updateForm(data, "last");

  // gravar dados nos state formData e formStep
  // além de invocar o envio dos dados quando
  // estiver tudo ok
  const _updateForm = (data, step = null) => {
    setFormData(formData => {
      return { ...formData, ...data };
    });
    if (step !== null) {
      setFormStep(step);
    }
  };

  // inicia a function _fetchCidade
  const _handleEstadoChanged = event => {
    event.persist();
    setEstadoId(event.target.value);
  };

  // inicia a request para obter os dados da empresa
  const _handleCnpjChanged = event => {
    const { value } = event.target;

    if (value.length === 18) {
      setInputCnpj(value);
    }

    if (value.length === 18 && inputCnpj !== value) {
      dispatch(ConsultaCnpjActions.consultaCnpjRequest(value));
    }
  };

  // inicia a request para obter o endereço para a empresa
  const _handleCepChanged = event => {
    const { value } = event.target;
    if (value.length === 9) {
      dispatch(ConsultaCepActions.consultaCepRequest(value));
    }
  };

  // atualiza o state tipoPessoa
  const _handleTipoChanged = event => {
    event.persist();
    setTipoPessoa(event.target.value);
  };

  const _formSubtitle = () => {
    switch (formStep) {
      case 1:
        return "Insira os dados de sua empresa";
      case 2:
        return "Insira os dados sobre a localidade";
      case 3:
        return "Insira os dados do representante";
    }
  };

  function handleEnderecoOnChange(event) {
    const { name, value } = event.target;
    const param = name.split(".")[1];

    setFormData(state => {
      const endereco = { ...state.endereco, ...{ [param]: value } };
      return { ...state, ...{ endereco } };
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, [name]: value };
    });
  }

  const consultaCnpjResultCB = useCallback(() => {
    if (consultaCnpj.error === null && consultaCnpj.data !== null) {
      const {
        atuacao,
        cnae,
        razao_social,
        nome_fantasia,
        endereco,
        telefone: responsavel_legal_telefone,
        responsavel_legal: responsavel_legal_nome,
      } = consultaCnpj.data;

      const {
        estado_id,
        cidade_id,
        logradouro,
        bairro,
        numero,
        complemento,
        cep,
      } = endereco;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        ...(atuacao && { atuacao }),
        ...(cnae && { cnae }),
        ...(razao_social && { razao_social }),
        ...(nome_fantasia && { nome_fantasia }),
        ...(nome_fantasia && { nome_exibicao: nome_fantasia }),
        ...(responsavel_legal_telefone && { responsavel_legal_telefone }),
        ...(responsavel_legal_nome && { responsavel_legal_nome }),
        ...{ endereco: enderecoFragment },
      };
      setEstadoId(estado_id);
      setFormData(state => ({ ...state, ...dataToUpdate }));
    }
  }, [consultaCnpj, setFormData, setEstadoId]);

  const consultaCepResultCB = useCallback(() => {
    if (consultaCep.error === null && consultaCep.data !== null) {
      const {
        bairro,
        cep,
        cidade_id,
        complemento,
        estado_id,
        logradouro,
        numero,
      } = consultaCep.data;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        endereco: enderecoFragment,
      };
      setEstadoId(estado_id);
      setFormData(state => ({ ...state, ...dataToUpdate }));
    }
  }, [consultaCep, setFormData, setEstadoId]);

  const fetchCidadesCB = useCallback(() => {
    if (estadoId) {
      dispatch(SelectCidadesActions.request(estadoId));
    }
  }, [estadoId, dispatch]);

  useEffect(() => {
    consultaCnpjResultCB();
  }, [consultaCnpjResultCB]);

  useEffect(() => {
    consultaCepResultCB();
  }, [consultaCepResultCB]);

  useEffect(() => {
    fetchCidadesCB();
  }, [fetchCidadesCB]);

  useEffect(() => {
    if (formStep === "last") {
      dispatch(CreateEmpresaActions.createEmpresaRequest(formData, history));
    }
  }, [formData, formStep, dispatch]);

  useEffect(() => {}, [createEmpresa, cidadesLoading, aceite]);

  useEffect(() => {
    return () => {
      dispatch(CreateEmpresaActions.createEmpresaReset());
      dispatch(ConsultaCnpjActions.consultaCnpjReset());
      dispatch(ConsultaCepActions.consultaCepReset());
      dispatch(SelectCidadesActions.reset());
    };
  }, []);

  return (
    <Page>
      <Navbar type="login" />
      <SectionForm
        headerSubtitle="Preencha as informações de sua empresa"
        formTitle="Cadastro de empresas"
        formSubtitle={_formSubtitle()}
      >
        <Stepper>
          {/* Etapa 1 */}
          <Step active={formStep === 1} step="1/3">
            <Form
              onSubmit={_handleSubmit1}
              initialData={formData}
              schema={schemaStep1}
            >
              <Row hascols>
                <Col>
                  <Select
                    label="Tipo"
                    name="tipo_pessoa"
                    options={tipos_pessoa}
                    onChange={_handleTipoChanged}
                  />
                </Col>
                <Col>
                  {[tipoPessoa, formData.tipo_pessoa].includes("J") ? (
                    <InputMask
                      type="cnpj"
                      label="CNPJ"
                      name="documento"
                      onChange={_handleCnpjChanged}
                    />
                  ) : (
                    <InputMask type="cpf" label="CPF" name="documento" />
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    label="Nome de exibição"
                    name="nome_exibicao"
                    value={formData.nome_exibicao || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              {[tipoPessoa, formData.tipo_pessoa].includes("J") && (
                <>
                  <Row hascols>
                    <Col>
                      <Input
                        label="Razão social"
                        name="razao_social"
                        value={formData.razao_social || ""}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Nome fantasia"
                        name="nome_fantasia"
                        value={formData.nome_fantasia || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {[tipoPessoa, formData.tipo_pessoa].includes("F") && (
                <Row>
                  <Col>
                    <Input
                      label="Registro profissional"
                      name="registro_profissional"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              )}

              <Row hascols>
                <Col>
                  <Input
                    label="Inscrição estadual"
                    name="inscricao_estadual"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Input
                    label="Inscrição municipal"
                    name="inscricao_municipal"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row
                hascols={
                  [tipoPessoa, formData.tipo_pessoa].includes("J") || undefined
                }
              >
                <Col>
                  <Input
                    label="Área de atuação"
                    name="atuacao"
                    value={formData.atuacao || ""}
                    onChange={handleChange}
                  />
                </Col>
                {[tipoPessoa, formData.tipo_pessoa].includes("J") && (
                  <Col>
                    <Input
                      label="CNAE"
                      name="cnae"
                      value={formData.cnae || ""}
                      onChange={handleChange}
                    />
                  </Col>
                )}
              </Row>

              <Row hascols>
                <Col>
                  <Input
                    type="mail"
                    name="email"
                    label="E-mail de contato"
                    value={formData.email || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <InputMask
                    type="telefone"
                    name="telefone"
                    label="Telefone de contato"
                    value={formData.telefone || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    multiline
                    showLength
                    maxLength={500}
                    label="Apresentação"
                    name="apresentacao"
                    placeholder="Uma breve apresentação sobre sua empresa"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row>
                <Button variant="cyan" type="submit">
                  Continuar
                </Button>
              </Row>
            </Form>
          </Step>
          {/* Etapa 2 */}
          <Step active={formStep === 2} step="2/3">
            <Form
              onSubmit={_handleSubmit2}
              initialData={formData}
              schema={schemaStep2}
            >
              <Scope path="endereco">
                <Row hascols>
                  <Col md="2/6">
                    <InputMask
                      type="cep"
                      label="CEP"
                      name="cep"
                      onChange={e => {
                        _handleCepChanged(e);
                        handleEnderecoOnChange(e);
                      }}
                      value={formData.endereco.cep || ""}
                    />
                  </Col>
                  <Col md="1/6">
                    <Select
                      label="UF"
                      name="estado"
                      onChange={e => {
                        _handleEstadoChanged(e);
                        handleEnderecoOnChange(e);
                      }}
                      options={options_estados}
                      value={formData.endereco.estado || ""}
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Cidade"
                      name="cidade_id"
                      options={cidades}
                      loading={cidadesLoading}
                      value={formData.endereco.cidade_id || ""}
                      onChange={handleEnderecoOnChange}
                    />
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Input
                      label="Logradouro"
                      name="logradouro"
                      value={formData.endereco.logradouro || ""}
                      onChange={handleEnderecoOnChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Número"
                      name="numero"
                      value={formData.endereco.numero || ""}
                      onChange={handleEnderecoOnChange}
                    />
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Input
                      label="Complemento"
                      name="complemento"
                      value={formData.endereco.complemento || ""}
                      onChange={handleEnderecoOnChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Bairro"
                      name="bairro"
                      value={formData.endereco.bairro || ""}
                      onChange={handleEnderecoOnChange}
                    />
                  </Col>
                </Row>
              </Scope>

              <Row>
                <Button
                  variant="tangerine"
                  icon="arrow-left"
                  onClick={() => setFormStep(1)}
                >
                  Voltar
                </Button>
                <Button variant="cyan" type="submit">
                  Continuar
                </Button>
              </Row>
            </Form>
          </Step>
          {/* Step 3 */}
          <Step active={[3, "last"].includes(formStep)} step="3/3">
            <Form
              onSubmit={_handleSubmit3}
              initialData={formData}
              schema={schemaStep3}
            >
              <Row>
                <Col>
                  <Input
                    label="Nome do representante legal"
                    placeholder="Nome completo"
                    name="responsavel_legal_nome"
                    value={formData.responsavel_legal_nome || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row hascols>
                <Col>
                  <Input
                    type="mail"
                    label="E-mail do representante legal"
                    name="responsavel_legal_email"
                    value={formData.responsavel_legal_email || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <InputMask
                    label="Telefone do representante legal"
                    type="telefone"
                    name="responsavel_legal_telefone"
                    value={formData.responsavel_legal_telefone || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <SubHeader title="Dados de acesso:" />

              <Scope path="usuario">
                <Row hascols>
                  <Col>
                    <Input type="mail" label="E-mail" name="email" />
                  </Col>
                  <Col>
                    <Input
                      type="mail"
                      label="Confirmar e-mail"
                      name="email_c"
                    />
                  </Col>
                </Row>
              </Scope>

              <Row>
                <Col>
                  <AceiteTermos onChange={checked => setAceite(checked)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Autorizo onChange={checked => setAutorizo(checked)} />
                </Col>
              </Row>

              <Row>
                <Button
                  variant="tangerine"
                  icon="arrow-left"
                  onClick={() => setFormStep(2)}
                >
                  Voltar
                </Button>
                <Button
                  variant="cyan"
                  type="submit"
                  disabled={!aceite || !autorizo || undefined}
                  loading={createEmpresa.loading}
                >
                  Concluir
                </Button>
              </Row>
            </Form>
          </Step>
        </Stepper>
      </SectionForm>
      <Footer />
    </Page>
  );
}
