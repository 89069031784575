import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 0;
  border-bottom: 1px #d5d4e0 solid;
  margin-bottom: 8px;
`;

export const Line1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Aluno = styled.div`
  flex: 1;
  display: flex;
  max-width: 320px;
`;

export const Avatar = styled.div.attrs({
  className: "avatar",
})`
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 36px;
  margin-right: 8px;
  transition: 300ms ease;
  background-color: #CCC;
  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Nome = styled.h4`
  font-size: 18px;
  font-weight: 600;
  color: #423b8d;
  transition: 300ms ease;
`;

export const Curso = styled.small`
  font-size: 16px;
  font-weight: 400;
  color: #605f6a;
  transition: 300ms ease;
`;

export const Data = styled.div`
  flex: 1;
  max-width: 180px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #605f6a;

  span {
    font-weight: 600;
    color: #000;
  }
`;



export const Controles = styled.div`
  flex: 1;
  display: flex;
  max-width: 350px;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonReagenda = styled.button`
  background-color: transparent;
  border: 1px #271946 solid;
  padding: 10px 16px;
  color: #271946;
  font-size: 16px;
  font-weight: 600;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const ButtonContratar = styled.button`
  background-color: #f78764;
  padding: 10px 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const Endereco = styled.div`
  h4 {
    color: #423b8d;
  }
  margin-left: 80px;
`;
