import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { VagaProvider } from "@/context/vagas/vaga";
import { useEmpresa } from "@/hooks/empresas";
import { useVaga } from "@/hooks/vagas";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Modal, Button } from "@/components/Common";
import { Sidebar as EscolaSidebar } from "@/components/Escola";
import { Sidebar as EmpresaSidebar } from "@/components/Empresa";
import { SubMenu } from "@/components/Aluno";
import Share from "@/components/Share";

import { moeda } from "@/formatters";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import {
  PaineisContainer,
  Content,
  MenuInterno,
  PainelPrincipal,
  PainelPrincipalTopo,
  PainelPrincipalTopoTitulo,
  Breadcrumbs,
  TituloVaga,
  CidadeVaga,
  ContentBox,
  ContentBoxRed,
  ModalContent,
  ModalFooter,
  ControllButons,
} from "./styles";

function _Vaga({ match }) {
  const { data: user } = useSelector(state => state.auth);
  const [vagaId, setVagaId] = useState(null);
  const { vaga, fetchVaga } = useVaga();
  const { empresa, fetchEmpresa } = useEmpresa();
  const [modalCandidatar, setModalCandidatar] = useState(false);
  const [loadingCandidatar, setLoadingCantidatar] = useState(false);
  const [isCanditadato, setIsCandidatado] = useState(false);

  async function checarCandidatura(vagaId) {
    setLoadingCantidatar(true);
    const endpoint = `vagas/${vagaId}/candidatura/${user.perfil_id}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    setLoadingCantidatar(false);
    if (response.success) {
      setIsCandidatado(response.data.candidatado);
    }
  }

  async function handleCandidatar() {
    setLoadingCantidatar(true);
    const endpoint = `vagas/${vagaId}/candidatura/${user.perfil_id}`;
    const { data: response } = await api.put(endpoint, null, makeOptions(user));
    setLoadingCantidatar(false);
    if (response.success) {
      setModalCandidatar(false);
      setIsCandidatado(state => !state);
    } else {
      setModalCandidatar(false);
      toastify(response.message, "error");
    }
  }

  function renderSidebar() {
    const { escola } = empresa;
    if (escola === 1) return <EscolaSidebar />;
    if (escola === 0) return <EmpresaSidebar />;
  }

  function renderTopo() {
    const { titulo, cidade } = vaga;

    return (
      <PainelPrincipalTopo>
        <PainelPrincipalTopoTitulo>
          <h1>Detalhes da vaga</h1>
        </PainelPrincipalTopoTitulo>
        <Breadcrumbs>
          <ul>
            <li>Vagas de estágio</li>
            <li className="ativo">Detalhes</li>
          </ul>
        </Breadcrumbs>
        <TituloVaga>{titulo}</TituloVaga>
        <CidadeVaga>
          {cidade.nome}/{cidade.estado.sigla}
        </CidadeVaga>
      </PainelPrincipalTopo>
    );
  }

  function renderControllButtons() {
    return (
      <ControllButons>
        <Button
          type="button"
          variant={isCanditadato ? "cyan" : "tangerine"}
          onClick={() => setModalCandidatar(true)}
          loading={loadingCandidatar || undefined}
          disabled={isCanditadato} // por enquanto vamos impedir de clicar se já estiver candidatado
        >
          {isCanditadato
            ? "Você já se candidatou a esta vaga"
            : "Candidatar-se a esta vaga"}
        </Button>
        <Share url={`vaga/${vagaId}`}>
          <Button type="button">Compartilhar</Button>
        </Share>
      </ControllButons>
    );
  }

  function renderSobreEmpresa() {
    const { apresentacao } = empresa;
    return (
      <ContentBox>
        <h4 className="empresa">Sobre a empresa</h4>
        <p>{apresentacao}</p>
      </ContentBox>
    );
  }

  function renderExclusivoPCD() {
    const { pcd } = vaga;

    if (Number(pcd) === 1) {
      return (
        <ContentBoxRed>
          <h4>Esta vaga é exclusiva para PCD</h4>
        </ContentBoxRed>
      );
    }
  }

  function renderCargaHoraria() {
    const { carga_horaria_semanal } = vaga;
    return (
      <ContentBox>
        <h4>Carga horária semanal</h4>
        <p>{carga_horaria_semanal.horas} horas</p>
      </ContentBox>
    );
  }

  function renderRemuneracao() {
    const { remuneracao } = vaga;
    return (
      <ContentBox>
        <h4>Remuneração mensal</h4>
        <p>{moeda(Number(remuneracao))}</p>
      </ContentBox>
    );
  }

  function renderAuxilioTransporte() {
    const { auxilio_transporte } = vaga;
    return (
      <ContentBox>
        <h4>Auxílio transporte</h4>
        <p>{moeda(Number(auxilio_transporte))}</p>
      </ContentBox>
    );
  }

  function renderDescricao() {
    const { descricao } = vaga;
    return (
      <ContentBox>
        <h4>Descrição</h4>
        <p>{descricao}</p>
      </ContentBox>
    );
  }

  function renderAtividades() {
    const { atividades } = vaga;
    const atividadesList = atividades.length ? (
      atividades.map((atividade, index) => {
        return <li key={index}>{atividade}</li>;
      })
    ) : (
      <li>Não definidas</li>
    );
    return (
      <ContentBox>
        <h4>Atividades</h4>
        <ul>{atividadesList}</ul>
      </ContentBox>
    );
  }

  function renderCursos() {
    const { cursos } = vaga;
    const cursosList = cursos.length ? (
      cursos.map((curso, index) => {
        return (
          <li key={index}>
            {curso.nome} ({curso.nivel.nome})
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );
    return (
      <ContentBox>
        <h4>Cursos</h4>
        <ul>{cursosList}</ul>
      </ContentBox>
    );
  }

  function renderBeneficios() {
    const { beneficios } = vaga;
    const beneficiosList = beneficios.length ? (
      beneficios.map((beneficio, index) => {
        const beneficioValor =
          beneficio.valor > 0 ? (
            <span className="beneficio-valor"> {moeda(beneficio.valor)}</span>
          ) : (
            <></>
          );

        return (
          <li key={index}>
            {beneficio.descricao}
            {beneficioValor}
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );

    return (
      <ContentBox>
        <h4>Benefícios</h4>
        <ul>{beneficiosList}</ul>
      </ContentBox>
    );
  }

  function renderModalCandidatar() {
    return (
      <Modal open={modalCandidatar} onClose={() => setModalCandidatar(false)}>
        <ModalContent>
          <h2>
            {isCanditadato
              ? "Remover sua candidatura a esta vaga?"
              : "Confirmar sua candidatura à esta vaga?"}
          </h2>
          <ModalFooter>
            <Button
              type="button"
              block
              variant="tangerine"
              loading={loadingCandidatar || undefined}
              onClick={handleCandidatar}
            >
              {isCanditadato
                ? "Confirmar remoção da candidatura"
                : "Confirmar candidatura"}
            </Button>
            <Button
              type="button"
              block
              variant="cancel"
              onClick={() => setModalCandidatar(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  const onMatchParamsCB = useCallback(
    id => {
      setVagaId(id);
      fetchVaga(id);
      checarCandidatura(id);
    },
    [setVagaId, fetchVaga, checarCandidatura]
  );

  const fetchEmpresaCB = useCallback(
    empresaId => {
      fetchEmpresa(empresaId);
    },
    [fetchEmpresa]
  );

  useEffect(() => {
    const { id } = match.params;
    onMatchParamsCB(id);
  }, [match]);

  useEffect(() => {
    if (vaga) {
      const { empresa_id } = vaga;
      fetchEmpresaCB(empresa_id);
    }
  }, [vaga]);

  return (
    <Page>
      <Navbar type="interna" />
      {(!empresa || !vaga) && <FullPageLoader />}
      {empresa && vaga && (
        <DashboardContainer>
          <PaineisContainer>
            {renderSidebar()}
            <Content>
              <MenuInterno>
                <SubMenu type="vagas" />
              </MenuInterno>
              <PainelPrincipal>
                {renderTopo()}
                {vaga.cancelamento_data != null ? (
                  <h2>Esta vaga foi cancelada</h2>
                ) : (
                  <>
                    {renderControllButtons()}
                    {renderSobreEmpresa()}
                    {renderExclusivoPCD()}
                    {renderCargaHoraria()}
                    {renderRemuneracao()}
                    {renderAuxilioTransporte()}
                    {renderDescricao()}
                    {renderCursos()}
                    {renderAtividades()}
                    {renderBeneficios()}
                  </>
                )}
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
      {renderModalCandidatar()}
    </Page>
  );
}

export default function Vaga({ history, match }) {
  return (
    <EmpresaProvider>
      <VagaProvider>
        <_Vaga history={history} match={match} />
      </VagaProvider>
    </EmpresaProvider>
  );
}
