import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { VagaContext } from "@/context/vagas/vaga";
import { VagasContext } from "@/context/vagas/vagas";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import EventEmitter from "@/event-emitter";

const useVaga = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(VagaContext);
  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchVaga(vagaId) {
    _setLoading(true);
    const endpoint = `vagas/${vagaId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    } else {
      toastify(response.message, "error");
    }
  }

  async function fetchVagaAsGuest(vagaId) {
    _setLoading(true);
    const endpoint = `convidado-vagas/${vagaId}`;
    const { data: response } = await api.get(endpoint);
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    } else {
      toastify(response.message, "error");
    }
  }

  async function createVaga(data) {
    _setLoading(true);
    const endpoint = `vagas`;
    const { data: response } = await api.post(
      endpoint,
      data,
      makeOptions(user)
    );
    _setLoading(false);
    if (response.success) {
      EventEmitter.dispatch("vaga-added", null);
      toastify("Vaga criada com sucesso");
      _setData(response.data);
    }
  }

  async function updateVaga(vagaId, data) {
    _setLoading(true);
    const endpoint = `vagas/${vagaId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Vaga atualizada com sucesso");
      _setData(response.data);
    }
  }

  async function cancelarVaga(vagaId, onComplete) {
    _setLoading(true);
    const endpoint = `vagas/${vagaId}/cancelar`;
    const { data: response } = await api.put(endpoint, {}, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Vaga cancelada com sucesso");
      onComplete();
    } else {
      toastify("Não foi possível cancelar esta vaga");
    }
  }

  async function deleteVaga(vagaId) {
    _setLoading(true);
    const endpoint = `vagas/${vagaId}`;
    const { data: response } = await api.delete(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
    }
  }

  return {
    fetchVaga,
    fetchVagaAsGuest,
    createVaga,
    updateVaga,
    cancelarVaga,
    deleteVaga,
    vaga: state.data,
    loading: state.loading,
  };
};

const useVagas = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(VagasContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchVagas() {
    _setLoading(true);
    const endpoint = `vagas`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  return {
    fetchVagas,
    vagas: state.data,
    loading: state.loading,
  };
};

export { useVaga, useVagas };
