import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { FiTrash2 } from "react-icons/fi";
import { Input } from "@/components/Form";
import {
  ListContainer,
  ListItemContainer,
  ButtonDelete,
  ButtonAdd,
  Error
} from "./styles";

export default function Atividades({ value, onChange, error }) {
  const [atividades, setAtividades] = useState(value || []);

  useEffect(() => {
    if (onChange) {
      onChange(atividades);
    }
  }, [atividades]);

  function handleChangeAtividade(event, index) {
    const { value } = event.target;
    setAtividades(state => {
      return update(state, {
        [index]: { $set: value },
      });
    });
  }

  function handleAddAtividade() {
    const atividadesIncompletas = atividades.filter(a => a === "");
    if (atividadesIncompletas.length === 0 && atividades.length < 5) {
      setAtividades(state => {
        return [...state, ...[""]];
      });
    }
  }

  function handleDeleteAtividade(index) {
    setAtividades(state => {
      return update(state, {
        $splice: [[index, 1]]
      });
    });
  }

  function renderList() {
    return atividades.map((atividade, index) => {
      return (
        <ListItemContainer key={index}>
          <Input
            name="desc"
            placeholder="Nome da atividade"
            value={atividade}
            onChange={e => handleChangeAtividade(e, index)}
            maxLength={50}
          />
          <ButtonDelete onClick={(e) => handleDeleteAtividade(index)}>
            <FiTrash2 />
          </ButtonDelete>
        </ListItemContainer>
      );
    });
  }

  return (
    <ListContainer>
      <h3>Atividades da vaga:</h3>
      {error && <Error>{error}</Error>}
      {renderList()}
      <ButtonAdd onClick={handleAddAtividade}>+ Adicionar atividade</ButtonAdd>
    </ListContainer>
  );
}
