import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    request: [],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  {
    prefix: "INDICADORES_TOTAIS_GERAIS_",
  }
);

export const IndicadoresTotaisGeraisTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

export const request = state =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

export const success = (state, action) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

export const failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const reset = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});
