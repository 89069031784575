import React, { useState, useEffect, useRef } from "react";
import { FaChevronCircleRight, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

import Paginator, { Loading } from "@/components/Paginator";
import { date } from "@/formatters";
import { ListItem, ActionButton, DownloadButton, Label, ActionsContainer, ModalContent, ModalBody } from "./styles";
import { Modal, Button } from "@/components/Common";
import { ModalAddAssinante, ModalEditAssinante } from "@/components/Escola";
import { useAssinante } from "@/hooks/assinantes";

import EventEmitter from "@/event-emitter";

function ListarAssinantes({ history }) {
  const [assinantes, setAssinantes] = useState([]);
  const { data: user } = useSelector(state => state.auth);
  const [query] = useState({
    empresa_id: user.empresa.id,
  });
  const { deleteAssinante } = useAssinante();
  const [paginator, setPaginator] = useState(null);
  const [loadingAssinantes, setLoadingAssinantes] = useState(false);
  const [modalAssinanteOpen, setModalAssinanteOpen] = useState(false);
  const [modalEditAssinanteOpen, setModalEditAssinanteOpen] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [assinanteEdit, setAssinanteEdit] = useState([]);
  const [assinanteDelete, setAssinanteDelete] = useState(0);
  const paginatorRef = useRef();

  useEffect(() => {
    EventEmitter.subscribe("assinante-added", event =>
      handlePaginatorResetAndFetchData(event)
    );
  }, []);
  
  function handlePaginatorResetAndFetchData(event) {
    if (paginatorRef.current) {
      paginatorRef.current.resetAndFetchData();
    }
  }

  function handleOnFetchComplete(data) {
    setAssinantes(data.data);
    setPaginator(data.paginator);
  }

  function handleModalEditAssinanteOpen(assinante) {
    setAssinanteEdit(assinante);
    setModalEditAssinanteOpen(true);
  }

  function handleShowModalDelete(assinanteId) {
    setAssinanteDelete(assinanteId);
    setShowModalDelete(true);
  }

  async function handleDelete(assinanteId) {
    setLoadingDelete(true);
    await deleteAssinante(assinanteId);
    setLoadingDelete(false);
    setShowModalDelete(false);
  }

  function renderAssinantes() {
    if (assinantes.length > 0) {
      return assinantes.map(assinante => {
        const { id, nome, sobrenome, email, cargo } = assinante;

        return (
            <ListItem key={id}>
              <div className="coluna coluna-nome">
                <h5>Nome</h5>
                <h4>{nome} {sobrenome}</h4>
              </div>

              <div className="coluna coluna-nome">
                <h5>Email</h5>
                <h4>{email}</h4>
              </div>

              <div className="coluna coluna-cargo">
                <h5>Cargo</h5>
                <h4>{cargo}</h4>
              </div>

              <div className="coluna coluna-actions">
                <ActionButton
                  onClick={() => handleModalEditAssinanteOpen(assinante)}
                >
                  <FaChevronCircleRight color="#271946" size={14} />{" "}
                  <span>Editar</span>
                </ActionButton>
                <ActionButton
                  onClick={() => handleShowModalDelete(id)}
                >
                  <FaTrash color="#271946" size={14} />{" "}
                  <span>Excluir</span>
                </ActionButton>
              </div>
            </ListItem>
        );
      });
    } else {
      return <Label>Ainda não existem assinantes cadastrados</Label>;
    }
  }

  return (
    <>
      <ActionsContainer>
        <Button
          variant="cyan"
          disabled={loadingAssinantes}
          loading={loadingAssinantes}
          onClick={() => setModalAssinanteOpen(true)}
          >
          Adicionar assinante
        </Button>
      </ActionsContainer>
      <Loading loading={loadingAssinantes}>
          {paginator && renderAssinantes()}
          <Paginator
            endpoint="assinantes"
            query={query}
            onFetchComplete={handleOnFetchComplete}
            onLoading={l => setLoadingAssinantes(l)}
            ref={paginatorRef}
          />
          <Modal
            open={modalAssinanteOpen}
            onClose={() => setModalAssinanteOpen(false)}
          >
            <ModalAddAssinante onClose={() => setModalAssinanteOpen(false)} />
          </Modal>
          <Modal
            open={modalEditAssinanteOpen}
            onClose={() => setModalEditAssinanteOpen(false)}
          >
            <ModalEditAssinante onClose={() => setModalEditAssinanteOpen(false) } assinante={assinanteEdit} />
          </Modal>
          <Modal open={showModalDelete} onClose={() => setShowModalDelete(false)}>
            <ModalContent>
              <ModalBody>
                <h1>Tem certeza de que deseja remover este assinante?</h1>
                <Button variant="magenta" 
                block 
                onClick={() => handleDelete(assinanteDelete)} 
                loading={loadingDelete || undefined}>
                  Confirmar remoção
                </Button>
                <Button
                  variant="cancel"
                  block
                  onClick={() => setShowModalDelete(false)}
                >
                  Cancelar
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
      </Loading>
    </>
  );
}

export default withRouter(ListarAssinantes);