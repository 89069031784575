import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import SelectCidadesActions from "@/store/ducks/cidades/select-cidades";

import { estados, tipos_pessoa } from "@/data";
import { useEmpresa } from "@/hooks/empresas";
import { Button } from "@/components/Common";
import { Form, Scope, Input, InputMask, Select } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditPerfil({ onClose }) {
  const { data: user } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const { empresa, loading, updateEmpresa } = useEmpresa();

  const [formData, setFormData] = useState({
    escola: 0,
    endereco: {
      cep: "",
    },
  });
  const [estadoId, setEstadoId] = useState(null);

  const consultaCep = useSelector(state => state.consultaCep);
  const { data: cidades, loading: cidadesLoading } = useSelector(
    state => state.selectCidades
  );

  const options_estados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });

  const schema = Yup.object().shape({
    ...(Number(empresa.escola) === 0 && {
      tipo_pessoa: Yup.string().required("Obrigatório"),
    }),
    documento: Yup.string().required("Obrigatório"),
    atuacao: Yup.string().required("Obrigatório"),
    nome_exibicao: Yup.string().required("Obrigatório"),
    apresentacao: Yup.string().required("Obrigatório"),
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    telefone: Yup.string().required("Obrigatório"),
    ...(Number(empresa.escola) === 1 && {
      informacao_tce: Yup.string().required("Obrigatório"),
    }),
    inscricao_estadual: Yup.string(),
    inscricao_municipal: Yup.string(),

    // dados para pessoa jurídica
    razao_social: Yup.string().when("tipo_pessoa", {
      is: value => value === "J",
      then: Yup.string().required("Obrigatório"),
    }),
    nome_fantasia: Yup.string().when("tipo_pessoa", {
      is: value => value === "J",
      then: Yup.string().required("Obrigatório"),
    }),
    cnae: Yup.string().when("tipo_pessoa", {
      is: value => value === "J",
      then: Yup.string().required("Obrigatório"),
    }),

    // dados para pessoa física
    registro_profissional: Yup.string().when("tipo_pessoa", {
      is: value => value === "F",
      then: Yup.string().required("Obrigatório"),
    }),

    // Representante legal
    responsavel_legal_nome: Yup.string().required("Obrigatório"),
    responsavel_legal_telefone: Yup.string().required("Obrigatório"),
    responsavel_legal_email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    ...(Number(empresa.escola) === 1 && {
      responsavel_legal_cargo: Yup.string().required("Obrigatório"),
    }),

    // Endereço
    endereco: Yup.object().shape({
      cep: Yup.string().required("Obrigatório"),
      logradouro: Yup.string().required("Obrigatório"),
      numero: Yup.string(),
      complemento: Yup.string(),
      bairro: Yup.string().required("Obrigatório"),
      cidade_id: Yup.string().required("Obrigatório"),
    }),
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleEnderecoChange(event) {
    const { name, value } = event.target;
    const param = name.split(".")[1];

    if (param === "cep" && value.length === 9) {
      dispatch(ConsultaCepActions.consultaCepRequest(value));
    }

    setFormData(state => {
      const endereco = { ...state.endereco, ...{ [param]: value } };
      return { ...state, ...{ endereco } };
    });
  }

  function handleSubmit(data) {
    // Manipulando dados antes de enviar para a api
    if (Number(empresa.escola) === 1) {
      Object.assign(data, {
        tipo_pessoa: "J",
      });
    }

    // anulando campos exclusivos de pessoa jurídica
    if (data.tipo_pessoa === "F") {
      Object.assign(data, {
        // caso a empresa esteja mudando de PJ para PF, vamos nos certificar
        // de remover os caracteres extras
        documento: `${data.documento}`.replace(/[^0-9]/, "").substring(0, 11),
        razao_social: "",
        nome_fantasia: "",
        cnae: "",
      });
    }

    // anulando campos exclusivos de pessoa física
    if (data.tipo_pessoa === "J") {
      Object.assign(data, {
        registro_profissional: "",
      });
    }

    updateEmpresa(user.empresa.id, data);
  }

  const setFormDataCB = useCallback(() => {
    if (empresa) {
      const {
        tipo_pessoa,
        razao_social,
        nome_fantasia,
        cnae,
        registro_profissional,
        inscricao_estadual,
        inscricao_municipal,
        documento,
        escola,
        atuacao,
        nome_exibicao,
        apresentacao,
        responsavel_legal_nome,
        responsavel_legal_telefone,
        responsavel_legal_email,
        responsavel_legal_cargo,
        email,
        telefone,
        informacao_tce,
        endereco,
      } = empresa;

      const enderecoFragment = {
        ...endereco,
        ...{ estado: endereco.cidade.estado.id },
      };

      const data = {
        tipo_pessoa,
        razao_social,
        nome_fantasia,
        cnae,
        registro_profissional,
        inscricao_estadual,
        inscricao_municipal,
        documento,
        escola,
        atuacao,
        nome_exibicao,
        apresentacao,
        responsavel_legal_nome,
        responsavel_legal_telefone,
        responsavel_legal_email,
        responsavel_legal_cargo,
        email,
        telefone,
        informacao_tce,
        endereco: enderecoFragment,
      };

      setEstadoId(endereco.cidade.estado.id);
      setFormData(data);
    }
  }, [empresa, setFormData, setEstadoId]);

  const consultaCepResultCB = useCallback(() => {
    if (consultaCep.error === null && consultaCep.data !== null) {
      const {
        bairro,
        cep,
        cidade_id,
        complemento,
        estado_id,
        logradouro,
        numero,
      } = consultaCep.data;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        endereco: enderecoFragment,
      };
      setEstadoId(estado_id);
      setFormData(state => ({ ...state, ...dataToUpdate }));
    }
  }, [consultaCep, setFormData, setEstadoId]);

  const fetchCidadesCB = useCallback(() => {
    if (estadoId) {
      dispatch(SelectCidadesActions.request(estadoId));
    }
  }, [estadoId, dispatch]);

  useEffect(() => {
    setFormDataCB();
  }, [setFormDataCB]);

  useEffect(() => {
    consultaCepResultCB();
  }, [consultaCepResultCB]);

  useEffect(() => {
    fetchCidadesCB();
  }, [fetchCidadesCB]);

  useEffect(() => {
    if (!loading) onClose();
  }, [loading]);

  useEffect(() => {
    return () => {
      dispatch(ConsultaCepActions.consultaCepReset());
      dispatch(SelectCidadesActions.reset());
    };
  }, [dispatch]);

  return (
    <ModalContent>
      {formData && (
        <>
          <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
            <ModalBody>
              <Row hascols>
                {Number(formData.escola) === 0 && (
                  <Col>
                    <Select
                      label="Tipo"
                      name="tipo_pessoa"
                      options={tipos_pessoa}
                      value={formData.tipo_pessoa || ""}
                      onChange={handleChange}
                    />
                  </Col>
                )}
                <Col>
                  {(formData.tipo_pessoa === "J" && (
                    <InputMask
                      type="cnpj"
                      label="CNPJ"
                      name="documento"
                      value={formData.documento || ""}
                      onChange={handleChange}
                    />
                  )) || (
                    <InputMask
                      type="cpf"
                      label="CPF"
                      name="documento"
                      value={formData.documento || ""}
                      onChange={handleChange}
                    />
                  )}
                </Col>
              </Row>

              {formData.tipo_pessoa === "J" && (
                <Row hascols>
                  <Col>
                    <Input
                      label="Razão social"
                      name="razao_social"
                      value={formData.razao_social || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Nome fantasia"
                      name="nome_fantasia"
                      value={formData.nome_fantasia || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              )}

              <Row hascols>
                <Col>
                  <Input
                    label="Inscrição estadual"
                    name="inscricao_estadual"
                    value={formData.inscricao_estadual || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Input
                    label="Inscrição municipal"
                    name="inscricao_municipal"
                    value={formData.inscricao_municipal || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              {formData.tipo_pessoa === "F" && (
                <Row>
                  <Col>
                    <Input
                      label="Registro profissional"
                      name="registro_profissional"
                      value={formData.registro_profissional || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              )}

              <Row hascols>
                <Col>
                  <Input
                    label="Atuação"
                    name="atuacao"
                    value={formData.atuacao || ""}
                    onChange={handleChange}
                  />
                </Col>
                {formData.tipo_pessoa === "J" && (
                  <Col>
                    <Input
                      label="CNAE"
                      name="cnae"
                      value={formData.cnae || ""}
                      onChange={handleChange}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col>
                  <Input
                    label="Nome de exibição"
                    name="nome_exibicao"
                    value={formData.nome_exibicao || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    multiline
                    showLength
                    maxLength={500}
                    label="Apresentação"
                    name="apresentacao"
                    value={formData.apresentacao || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row hascols>
                <Col>
                  <Input
                    type="mail"
                    name="email"
                    label="E-mail de contato"
                    value={formData.email || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <InputMask
                    type="telefone"
                    name="telefone"
                    label="Telefone de contato"
                    value={formData.telefone || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    label="Nome do representante legal"
                    name="responsavel_legal_nome"
                    value={formData.responsavel_legal_nome || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row hascols>
                <Col>
                  <InputMask
                    type="telefone"
                    label="Telefone do representante legal"
                    name="responsavel_legal_telefone"
                    value={formData.responsavel_legal_telefone || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Input
                    label="E-mail do representante legal"
                    name="responsavel_legal_email"
                    type="mail"
                    value={formData.responsavel_legal_email || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              {Number(formData.escola) === 1 && (
                <>
                  <Row>
                    <Col>
                      <Input
                        label="Cargo do representante legal"
                        placeholder="Cargo do representante"
                        name="responsavel_legal_cargo"
                        value={formData.responsavel_legal_cargo || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        multiline
                        showLength
                        maxLength={500}
                        label="Informação complementar do contrato"
                        name="informacao_tce"
                        placeholder="Informação complementar do contrato"
                        value={formData.informacao_tce || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Scope path="endereco">
                <Row hascols>
                  <Col md="2/6">
                    <InputMask
                      type="cep"
                      label="CEP"
                      name="cep"
                      value={formData.endereco.cep || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                  <Col md="1/6">
                    <Select
                      label="UF"
                      name="estado"
                      options={options_estados}
                      value={formData.endereco.estado || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Cidade"
                      name="cidade_id"
                      options={cidades}
                      loading={cidadesLoading}
                      value={formData.endereco.cidade_id || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Input
                      label="Logradouro"
                      name="logradouro"
                      value={formData.endereco.logradouro || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Número"
                      name="numero"
                      value={formData.endereco.numero || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                </Row>

                <Row hascols>
                  <Col>
                    <Input
                      label="Complemento"
                      name="complemento"
                      value={formData.endereco.complemento || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Bairro"
                      name="bairro"
                      value={formData.endereco.bairro || ""}
                      onChange={handleEnderecoChange}
                    />
                  </Col>
                </Row>
              </Scope>
            </ModalBody>

            <ModalFooter>
              <Button variant="cancel" onClick={onClose}>
                CANCELAR
              </Button>
              <Button
                type="submit"
                variant="tangerine"
                loading={loading || undefined}
              >
                SALVAR ALTERAÇÕES
              </Button>
            </ModalFooter>
          </Form>
        </>
      )}
    </ModalContent>
  );
}
