import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parse, format } from "date-fns";
import * as Yup from "yup";
import { FiPlus, FiTrash2 } from "react-icons/fi";

import { Row, Col } from "@/components/Grid";
import { Form, Input } from "@/components/Form";
import Paginator, { Loading } from "@/components/Paginator";
import { Button, Modal } from "@/components/Common";

import CreateObservacaoActions from "@/store/ducks/empresas-observacoes/create";
import DeleteObservacaoActions from "@/store/ducks/empresas-observacoes/delete";

import {
  Container,
  ContainerHeader,
  CircleButton,
  Label,
  Observacao,
  ModalContent,
  ModalDeleteContent,
  ModalBody,
  ModalDeleteBody,
  ModalFooter,
} from "./styles";

export default function EmpresaObservacoes({ empresaId }) {
  const schema = Yup.object().shape({
    conteudo: Yup.string().required("Obrigatório"),
  });
  const dispatch = useDispatch();
  const { loading: loadingCreate } = useSelector(
    state => state.empresaCreateObservacao
  );
  const { loading: loadingDelete } = useSelector(
    state => state.empresaDeleteObservacao
  );
  const paginatorRef = useRef();

  const [observacoes, setObservacoes] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [paginatorLoading, setPaginatorLoading] = useState(false);
  const [query, setQuery] = useState({ empresa_id: empresaId });
  const [idToDelete, setIdToDelete] = useState(null);

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

  function handleFetchComplete(data) {
    setObservacoes(data.data);
    setPaginator(data.paginator);
  }

  function renderObservacoes() {
    if (paginator && paginator.total === 0) {
      return (
        <Label>Não existem entradas no log de contatos com esta empresa</Label>
      );
    }

    if (observacoes.length) {
      return observacoes.map(o => {
        const dataHora = format(
          parse(o.created_at, "yyyy-MM-dd HH:mm:ss", new Date()),
          "dd/MM/yyyy 'às' HH:mm"
        );

        return (
          <Observacao key={o.id}>
            <div className="coluna coluna-conteudo">
              <h4>{dataHora}</h4>
              <p>{o.conteudo}</p>
            </div>
            <div className="coluna coluna-actions">
              <button
                onClick={() => {
                  setIdToDelete(o.id);
                  setModalDeleteOpen(true);
                }}
              >
                <FiTrash2 color="#f78764" />
              </button>
            </div>
          </Observacao>
        );
      });
    }
  }

  function handleSubmit(data, { resetForm }) {
    dispatch(
      CreateObservacaoActions.createObservacaoRequest(
        {
          ...data,
          ...{ empresa_id: empresaId },
        },
        function() {
          resetForm();
          setModalAddOpen(false);
          if (paginatorRef.current) {
            paginatorRef.current.resetAndFetchData();
          }
        }
      )
    );
  }

  function handleDelete() {
    dispatch(
      DeleteObservacaoActions.deleteObservacaoRequest(idToDelete, function() {
        setModalDeleteOpen(false);
        setIdToDelete(null);
        if (paginatorRef.current) {
          paginatorRef.current.resetAndFetchData();
        }
      })
    );
  }

  return (
    <Container>
      <ContainerHeader>
        <div className="coluna coluna-titulo">
          <h4>Log de contatos com a empresa</h4>
        </div>
        <div className="coluna coluna-actions">
          <CircleButton onClick={() => setModalAddOpen(true)}>
            <FiPlus />
          </CircleButton>
        </div>
      </ContainerHeader>
      <Loading loading={paginatorLoading}>
        {renderObservacoes()}
        <Paginator
          endpoint="empresas-observacoes"
          query={query}
          onFetchComplete={handleFetchComplete}
          onLoading={l => setPaginatorLoading(l)}
          ref={paginatorRef}
        />
      </Loading>

      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)}>
        <ModalContent>
          <Form schema={schema} onSubmit={handleSubmit}>
            <ModalBody>
              <Row>
                <Col>
                  <Input multiline name="conteudo" label="Conteúdo" />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button variant="cancel" onClick={() => setModalAddOpen(false)}>
                CANCELAR
              </Button>
              <Button
                type="submit"
                variant="tangerine"
                loading={loadingCreate || undefined}
              >
                SALVAR
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>

      <Modal open={modalDeleteOpen} onClose={() => setModalDeleteOpen(false)}>
        <ModalDeleteContent>
          <ModalDeleteBody>
            <h2>Remover esta entrega do log?</h2>
            <Button
              variant="magenta"
              block
              onClick={handleDelete}
              loading={loadingDelete || undefined}
            >
              Confirmar remoção
            </Button>
            <Button
              variant="cancel"
              block
              onClick={() => setModalDeleteOpen(false)}
            >
              Cancelar
            </Button>
          </ModalDeleteBody>
        </ModalDeleteContent>
      </Modal>
    </Container>
  );
}
