import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  listSupervisorRequest: ["empresa_id"],
  listSupervisorSuccess: ["data"],
  listSupervisorFailure: ["error"],
  listSupervisorReset: [],
});

export const listSupervisorTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

// Reducers
export const listSupervisorRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const listSupervisorSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const listSupervisorFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const listSupervisorReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LIST_SUPERVISOR_REQUEST]: listSupervisorRequest,
  [Types.LIST_SUPERVISOR_SUCCESS]: listSupervisorSuccess,
  [Types.LIST_SUPERVISOR_FAILURE]: listSupervisorFailure,
  [Types.LIST_SUPERVISOR_RESET]: listSupervisorReset,
});
