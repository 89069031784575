import styled from "styled-components";

export const ShareContainer = styled.span.attrs({
  className: "share-container",
})`
  position: relative;
`;

export const ShareButtons = styled.div`
  display: ${props => (props.show ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  height: 52px;

  @media screen and (min-width: 1101px) {
    position: absolute;
    left: calc(100% + 20px);
    top: -16px;
  }

  @media screen and (max-width: 1100px) {
    position: relative;
    width: 100%;
    justify-content: center;
  }
`;

export const ShareButton = styled.a`
  display: block;
  margin: 0px 4px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  &.facebook {
    color: #4267B2;
  }
  &.twitter {
    color: #1da1f2;
  }
  &.whatsapp {
    color: #1ebea5;
  }
`;

export const ClipboardButton = styled.button`
  display: block;
  margin: 0px 4px;
  opacity: 0.8;
  color: #271946;
  background: transparent;
  position: relative;
  &:hover {
    opacity: 1;
  }
`;

export const ClipboardInput = styled.input`
  position: absolute;
  left: -30000px;
`;
