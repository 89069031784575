import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import LogoIA from "@/assets/images/logos/footer.png";
import { Modal } from "@/components/Common";
import ModalContato from "@/components/Empresa/ModalContato";

import {
  Container,
  Content,
  Logo,
  WrapLinks,
  Title,
  Social,
  TitleSocial,
} from "./styles";

export default function Footer() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <Content>
        <Logo>
          <img src={LogoIA} alt="Integrador" />
          <p>
            Acreditamos que a inclusão do jovem no mercado de trabalho é uma das
            formas de criar oportunidades e mudar vidas.
          </p>
        </Logo>
        <WrapLinks>
          <Title>Menu</Title>
          <Link to="/">Sobre nós</Link>
          <Link to="/" onClick={() => setShowModal(true)}>
            Fale Conosco
          </Link>
          <Link to="/termos">Termos de uso</Link>
        </WrapLinks>
        <WrapLinks>
          <Title>Estudantes</Title>
          <Link to="/">Vagas de Estágio</Link>
          <Link to="/login">Entrar</Link>
          <Link to="/cadastro-estudante">Cadastre-se</Link>
        </WrapLinks>
        <WrapLinks>
          <Title>Empresas</Title>
          <Link to="/login">Entrar</Link>
          <Link to="/cadastro-empresa">Cadastre-se</Link>
        </WrapLinks>
        <WrapLinks>
          <Title>Escola</Title>
          <Link to="/login">Entrar</Link>
          <Link to="/cadastro-escola">Cadastre-se</Link>
        </WrapLinks>
      </Content>
      <Content>
        <p>© 2018-2019 Integrador.app - Todos os direitos reservados.</p>
        <Social>
          <TitleSocial>Siga nossas redes:</TitleSocial>
          <a href="https://www.facebook.com/integradorapp/" target="_blank">
            <FaFacebookSquare color="#fff" />
          </a>
          <a href="https://www.instagram.com/integrador.app/" target="_blank">
            <FaInstagram color="#fff" />
          </a>
          <a
            href="https://www.linkedin.com/company/integrador-app"
            target="_blank"
          >
            <FaLinkedin color="#fff" />
          </a>
        </Social>
        <Modal open={showModal}>
          <ModalContato OnClose={() => setShowModal(false)} />
        </Modal>
      </Content>
    </Container>
  );
}
