import styled from "styled-components";

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const SmallCircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px #b9b7cc solid;
  color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
    border-color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  }
`;

export const Container = styled.div.attrs({
  className: "dados-pessoais",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #b9b7cc;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ListItem = styled.div`
  margin-top: 32px;
  padding-right: 16px;
  position: relative;
  overflow: hidden;
  flex-basis: calc(100% / 3);

  @media screen and (max-width: 1400px) {
    flex-basis: 50%;
  }

  @media screen and (max-width: 1100px) {
    flex-basis: 100%;
  }

  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
  }
  p.cargo {
    font-size: 18px;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  p.descricao {
    font-size: 16px;
  }

  div.actions {
    position: absolute;
    top: 0;
    right: 16px;
    button:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:hover {
    div.actions div.menu {
      opacity: 1;
    }
  }
`;
export const MenuContainer = styled.div.attrs({
  className: "menu-container",
})`
  position: absolute;
  top: 0;
  right: 46px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 4px;
  min-width: 150px;
  text-align: right;

  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
  .option {
    color: #423b8d;
    /* font-weight: bold; */
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    &-danger {
      color: #f78764;
    }
  }
`;
