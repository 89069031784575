import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDatePicker, {
  registerLocale,
} from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useField } from "@rocketseat/unform";
import { InputContainer } from "../styles";

registerLocale("ptBr", ptBR);

export default function DatePicker({
  type,
  name,
  label,
  onChange,
  value,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selected, setSelected] = useState(defaultValue);

  const [custom, setCustom] = useState({
    dateFormat: "dd/MM/yyyy",
  });

  useEffect(() => {
    switch (type) {
      case "mes":
        setCustom(() => ({
          dateFormat: "MM/yyyy",
          showMonthYearPicker: true,
        }));
        break;
      default:
        setCustom(() => ({
          dateFormat: "dd/MM/yyyy",
        }));
    }
  }, [type]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "props.selected",
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  function handleChange(date) {
    setSelected(date);
    if (onChange) onChange(date);
  }

  const onValueChanged = useCallback(() => {
    if (value) {
      setSelected(value);
    }
  }, [value, setSelected]);

  useEffect(() => {
    onValueChanged();
  }, [onValueChanged]);

  return (
    <InputContainer label={label}>
      {label && <label>{label}</label>}
      <ReactDatePicker
        name={fieldName}
        selected={selected}
        onChange={handleChange}
        locale="ptBr"
        minDate={new Date("2009-01-01")}
        maxDate={new Date("2099-01-01")}
        ref={ref}
        {...custom}
        {...rest}
      />
      {error && <span>{error}</span>}
    </InputContainer>
  );
}
