import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  deleteObservacaoRequest: ["id", "onSuccess"],
  deleteObservacaoSuccess: [""],
  deleteObservacaoFailure: ["error"],
});

export const deleteObservacaoTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const deleteObservacaoRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const deleteObservacaoSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const deleteObservacaoFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_OBSERVACAO_REQUEST]: deleteObservacaoRequest,
  [Types.DELETE_OBSERVACAO_SUCCESS]: deleteObservacaoSuccess,
  [Types.DELETE_OBSERVACAO_FAILURE]: deleteObservacaoFailure,
});
