import styled from "styled-components";
import bgImage from "@/assets/images/vagas-hero.png";

export const Hero = styled.div`
  height: 205px;
  background-color: #854C76;
  margin: 85px auto 0 auto;
  max-width: 1440px;
  width: 100%;
  background-image: url(${bgImage});
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
  }
`;

export const Main = styled.main`
  margin: 0 auto;
  padding: 55px 0;
  width: 100%;
  max-width: 1100px;

  select {
    background-color: transparent;
  }
  .input-container {
    input {
      background-color: transparent;
    }
  }
`;
