import styled from "styled-components";

export const GroupButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const ButtonFinaliza = styled.button`
  display: flex;
  padding: 14px 24px;
  background-color: #271946;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 16px;
  transition: 300ms ease;
  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const ButtonCancelar = styled.button`
  display: flex;
  padding: 14px 24px;
  background-color: transparent;
  color: #271946;
  border-radius: 4px;
  border: 1px #271946 solid;
  font-size: 18px;
  font-weight: 600;
  transition: 300ms ease;
  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 4px;
  h1 {
    text-align: center;
  }
  form {
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
  }
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 40px;
  span {
    font-weight: 600;
  }
  p {
    margin: 12px 0;
  }
  ul {
    li {
      margin-bottom: 8px;
      list-style: decimal;
      margin-left: 2rem;
    }
  }
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #B9B7CC;
  > * {
    margin-left: 20px;
  }
`;
