import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";

import { Checkbox, IconContainer, TextContainer } from "./styles";

export default function CheckConcordo({ value = 0, label = "", onChange }) {

  function handleClick() {
    if (onChange) {
      const newValue = value === 1 ? 0 : 1;
      onChange(newValue);
    }
  }

  return (
    <Checkbox onClick={handleClick}>
      <IconContainer>
        {value === 1 && <FaCheck color="#888888" size={12} />}
      </IconContainer>
      <TextContainer>{label}</TextContainer>
    </Checkbox>
  );
}
