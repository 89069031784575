import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";

const getAuthData = state => state.auth.data;

export function* consultaCep(action) {
  try {
    toastify("Consultando dados com base no seu CEP");
    const { cep } = action;
    const cepSanitazed = cep.replace(/[^\d]+/g, "");
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.get,
      `utils/cep/${cepSanitazed}`, makeOptions(authData)
    );

    if (retorno.success) {
      yield put(ConsultaCepActions.consultaCepSuccess(retorno.data));
    } else {
      yield put(ConsultaCepActions.consultaCepFailure(retorno.errors));
    }
  } catch (e) {
    yield put(ConsultaCepActions.consultaCepFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}


