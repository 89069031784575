import styled from "styled-components";

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const SmallCircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px #b9b7cc solid;
  color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
    border-color: ${({ danger }) => (danger ? "#f78764" : "#279cd9")};
  }
`;

export const Container = styled.div.attrs({
  className: "dados-pessoais",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #b9b7cc;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
`;

export const Avatar = styled.div.attrs({
  className: "formacao-avatar",
})`
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #ffffff;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
`;

export const EscolaAtual = styled.div.attrs({
  className: "escola-atual",
})`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }

  div.info {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    position: relative;

    div.info-titulo {
      h2 {
        color: #423b8d;
        font-size: 18px;
      }
    }

    div.info-datas {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }

    div.info-actions {
      position: absolute;
      top: 16px;
      right: -15px;
      div.menu {
        opacity: 0;
        color: #707070;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
    }

    &:hover {
      div.info-actions div.menu {
        opacity: 1;
      }
    }
  }
`;

export const FormacoesAnteriores = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
`;

export const FormacaoAnterior = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  margin-bottom: 32px;
  width: calc(100% / 3);

  @media screen and (max-width: 1600px) {
    width: 50%;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  div.info {
    position: relative;
    padding: 0 16px;
    flex: 1 1 100%;
    h2 {
      color: #423b8d;
      font-size: 18px;
      font-weight: 600;
    }

    div.info-datas {
      margin-top: 16px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }

    div.info-actions {
      position: absolute;
      top: 0;
      right: 0;
      button:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &:hover {
      div.info-actions div.menu {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 800px) {
    div.formacao-avatar {
      display: none;
    }
  }
`;

export const MenuContainer = styled.div.attrs({
  className: "menu-container",
})`
  position: absolute;
  top: 0;
  right: 46px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 4px;
  min-width: 150px;
  text-align: right;

  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
  .option {
    color: #423b8d;
    /* font-weight: bold; */
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    &-danger {
      color: #f78764;
    }
  }
`;
