import React from "react";
import { Route, Redirect } from "react-router-dom";

import EmpresaSupervisores from "@/pages/Empresa/Supervisores";
import EscolaSupervisores from "@/pages/Escola/Supervisores";

export default function SupervisoresRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch(true) {
    case ["funcionario"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaSupervisores;
      break;
    case ["funcionario"].includes(tipo) && empresa.is_escola:
      Component = EscolaSupervisores;
      break;
    default: Component = <div>Esta página não existe</div>
  }

  return <Route { ...rest } render={ props=> <Component { ...props } /> } />

}
