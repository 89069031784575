import React from "react";
import { PulseLoader } from "react-spinners";
import { Select as RocketSelect } from "@rocketseat/unform";
import { SelectContainer } from "../styles";

export default function Select({
  value,
  label,
  options,
  rounded,
  loading,
  ...rest
}) {
  return (
    <SelectContainer rounded={rounded !== undefined}>
      {label !== undefined && <label>{label}</label>}
      {![false, undefined].includes(loading) && (
        <PulseLoader size={5} color={"#000000"} />
      )}
      <RocketSelect options={options} value={value} {...rest} />
    </SelectContainer>
  );
}
