import { all, takeLatest } from "redux-saga/effects";

// auth
import { authTypes } from "@/store/ducks/auth";
import {
  login,
  logout,
  fetchMe,
  gerarRecover,
  recover,
  assumirControle,
  abandonarControle,
  checkToken,
} from "@/store/sagas/auth";
import { AuthEditarCredenciaisTypes } from "@/store/ducks/auth/editar-credenciais";
import { updateRequest as AuthEditarCredenciaisUpdateRequest } from "@/store/sagas/auth/editar-credenciais";
// empresas
import { viewEmpresaTypes } from "@/store/ducks/empresas/view";
import { createEmpresaTypes } from "@/store/ducks/empresas/create";
import { updateEmpresaTypes } from "@/store/ducks/empresas/update";
import { deleteEmpresaTypes } from "@/store/ducks/empresas/delete";
import { EmpresaCursosTypes } from "@/store/ducks/empresas/cursos";

import { createFranqueadoTypes } from "@/store/ducks/franqueados/create";

import { view as viewEmpresa } from "@/store/sagas/empresas/view";
import { create as createEmpresa } from "@/store/sagas/empresas/create";
import { update as updateEmpresa } from "@/store/sagas/empresas/update";
import { remove as deleteEmpresa } from "@/store/sagas/empresas/delete";
import {
  addCurso as addCursoEmpresa,
  removeCurso as removeCursoEmpresa,
  addTodosCursos as addTodosCursosEmpresa,
  removeTodosCursos as removeTodosCursosEmpresa,
} from "@/store/sagas/empresas/cursos";

//franqueados
import { create as createFranqueado } from "@/store/sagas/franqueados/create";

// observacoes
import { createObservacaoTypes as empresaCreateObservacaoTypes } from "@/store/ducks/empresas-observacoes/create";
import { deleteObservacaoTypes as empresaDeleteObservacaoTypes } from "@/store/ducks/empresas-observacoes/delete";
import { create as empresaObservacaoCreate } from "@/store/sagas/empresas-observacoes/create";
import { remove as empresaObservacaoRemove } from "@/store/sagas/empresas-observacoes/delete";
// supervisores
import { listSupervisorTypes } from "@/store/ducks/supervisores/list";
import { createSupervisorTypes } from "@/store/ducks/supervisores/create";
import { deleteSupervisorTypes } from "@/store/ducks/supervisores/delete";
import { list as listSupervisor } from "@/store/sagas/supervisores/list";
import { create as createSupervisor } from "@/store/sagas/supervisores/create";
import { remove as deleteSupervisor } from "@/store/sagas/supervisores/delete";
// paises
import { listPaisesTypes } from "@/store/ducks/paises/list";
import { list as listPaises } from "@/store/sagas/paises/list";
// utils
import { consultaCnpjTypes } from "@/store/ducks/utils/consulta-cnpj";
import { consultaCnpj } from "@/store/sagas/utils/consulta-cnpj";
import { consultaCepTypes } from "@/store/ducks/utils/consulta-cep";
import { consultaCep } from "@/store/sagas/utils/consulta-cep";
// alunos
import { deleteAlunoTypes } from "@/store/ducks/alunos/delete";
import { remove as alunoDelete } from "@/store/sagas/alunos/delete";
// tces
import { TceAddCoordenadorTypes } from "../ducks/tces/add-coordenador";
import { tceAddCoordenador } from "./tces/add-coordenador";

// planilhas
import { PlanilhaTcesTypes } from "../ducks/planilhas/tces";
import { getLink as planilhaTcesGetLink } from "./planilhas/tces";

import { PlanilhaAlunosTypes } from "../ducks/planilhas/alunos";
import { getLink as planilhaAlunosGetLink } from "./planilhas/alunos";

import { PlanilhaEmpresasTypes } from "../ducks/planilhas/empresas";
import { getLink as planilhaEmpresasGetLink } from "./planilhas/empresas";

import { PlanilhaEscolasTypes } from "../ducks/planilhas/escolas";
import { getLink as planilhaEscolasGetLink } from "./planilhas/escolas";

import { PlanilhaSugestoesEscolasTypes } from "../ducks/planilhas/sugestoes-escolas";
import { getLink as planilhaSugestoesEscolasGetLink } from "./planilhas/sugestoes-escolas";

// select cidades
import { SelectCidadesTypes } from "../ducks/cidades/select-cidades";
import { request as selectCidadesRequest } from "./cidades/select-cidades";

// indicadores
import { IndicadoresTotaisGeraisTypes } from "../ducks/indicadores/totais-gerais";
import { request as indicadoresTotaisGeraisRequest } from "./indicadores/totais-gerais";

import { IndicadoresTotaisPorPeriodoTypes } from "../ducks/indicadores/totais-por-periodo";
import { request as indicadoresTotaisPorPeriodoRequest } from "./indicadores/totais-por-periodo";

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest(authTypes.AUTH_REQUEST, login),
    takeLatest(authTypes.AUTH_LOGOUT, logout),
    takeLatest(authTypes.AUTH_FETCH_ME, fetchMe),
    takeLatest(authTypes.AUTH_GERAR_RECOVER_REQUEST, gerarRecover),
    takeLatest(authTypes.AUTH_RECOVER, recover),
    takeLatest(authTypes.AUTH_CHECK_TOKEN, checkToken),
    takeLatest(authTypes.AUTH_ASSUMIR_CONTROLE_REQUEST, assumirControle),
    takeLatest(authTypes.AUTH_ABANDONAR_CONTROLE, abandonarControle),
    // editar credenciais
    takeLatest(
      AuthEditarCredenciaisTypes.UPDATE_REQUEST,
      AuthEditarCredenciaisUpdateRequest
    ),
    // empresas
    takeLatest(viewEmpresaTypes.VIEW_EMPRESA_REQUEST, viewEmpresa),
    takeLatest(createEmpresaTypes.CREATE_EMPRESA_REQUEST, createEmpresa),
    takeLatest(updateEmpresaTypes.UPDATE_EMPRESA_REQUEST, updateEmpresa),
    takeLatest(deleteEmpresaTypes.DELETE_EMPRESA_REQUEST, deleteEmpresa),

    // franqueados
    // takeLatest(viewFranqueadoTypes.VIEW_FRANQUEADO_REQUEST, viewFranqueado),
    takeLatest(createFranqueadoTypes.CREATE_FRANQUEADO_REQUEST, createFranqueado),
    // takeLatest(updateFranqueadoTypes.UPDATE_FRANQUEADO_REQUEST, updateFranqueado),
    // takeLatest(deleteFranqueadoTypes.DELETE_FRANQUEADO_REQUEST, deleteFranqueado),

    // empresas cursos
    takeLatest(EmpresaCursosTypes.EMPRESA_CURSO_ADD_REQUEST, addCursoEmpresa),
    takeLatest(
      EmpresaCursosTypes.EMPRESA_CURSO_REMOVE_REQUEST,
      removeCursoEmpresa
    ),
    takeLatest(
      EmpresaCursosTypes.EMPRESA_CURSO_ADD_TODOS_REQUEST,
      addTodosCursosEmpresa
    ),
    takeLatest(
      EmpresaCursosTypes.EMPRESA_CURSO_REMOVE_TODOS_REQUEST,
      removeTodosCursosEmpresa
    ),

    // empresas observacoes
    takeLatest(
      empresaCreateObservacaoTypes.CREATE_OBSERVACAO_REQUEST,
      empresaObservacaoCreate
    ),
    takeLatest(
      empresaDeleteObservacaoTypes.DELETE_OBSERVACAO_REQUEST,
      empresaObservacaoRemove
    ),
    // supervisores
    takeLatest(listSupervisorTypes.LIST_SUPERVISOR_REQUEST, listSupervisor),
    takeLatest(
      createSupervisorTypes.CREATE_SUPERVISOR_REQUEST,
      createSupervisor
    ),
    takeLatest(
      deleteSupervisorTypes.DELETE_SUPERVISOR_REQUEST,
      deleteSupervisor
    ),
    // paises
    takeLatest(listPaisesTypes.LIST_PAISES_REQUEST, listPaises),
    // utils
    takeLatest(consultaCnpjTypes.CONSULTA_CNPJ_REQUEST, consultaCnpj),
    takeLatest(consultaCepTypes.CONSULTA_CEP_REQUEST, consultaCep),
    // alunos
    takeLatest(deleteAlunoTypes.DELETE_ALUNO_REQUEST, alunoDelete),

    // tces
    takeLatest(TceAddCoordenadorTypes.REQUEST, tceAddCoordenador),

    // planilhas
    takeLatest(PlanilhaTcesTypes.REQUEST, planilhaTcesGetLink),
    takeLatest(PlanilhaAlunosTypes.REQUEST, planilhaAlunosGetLink),
    takeLatest(PlanilhaEmpresasTypes.REQUEST, planilhaEmpresasGetLink),
    takeLatest(PlanilhaEscolasTypes.REQUEST, planilhaEscolasGetLink),
    takeLatest(
      PlanilhaSugestoesEscolasTypes.REQUEST,
      planilhaSugestoesEscolasGetLink
    ),

    // select cidades
    takeLatest(SelectCidadesTypes.REQUEST, selectCidadesRequest),

    // indicadores
    takeLatest(
      IndicadoresTotaisGeraisTypes.REQUEST,
      indicadoresTotaisGeraisRequest
    ),
    takeLatest(
      IndicadoresTotaisPorPeriodoTypes.REQUEST,
      indicadoresTotaisPorPeriodoRequest
    ),
  ]);
}
