import styled from "styled-components";

export const ModalContent = styled.div`
  text-align: center;
  max-width: 480px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 4px;
  padding: 42px;
  padding-bottom: 24px;

  h2 {
    color: #271946;
    font-size: 24px;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const ModalFooter = styled.div`
  margin-top: 28px;
  flex-direction: column;
  align-items: center;
  .button-container:not(:last-of-type) {
    display: block;
    margin-bottom: 16px;
  }
`;
