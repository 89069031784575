import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";
import { Row, Col } from "@/components/Grid";
import { Input, Select } from "@/components/Form";
import Paginator, { Loading } from "@/components/Paginator";

import { Filtros, VagaContainer, Entrevistas } from "./styles";

function ListarEntrevistasPassadas({ history }) {
  const [vagas, setVagas] = useState([]);
  const [loadingVagas, setLoadingVagas] = useState(false);
  const [query, setQuery] = useState({ count: 9, filters: "" });

  const statusOptions = [
    { id: "", title: "Todas" },
    { id: "abertas", title: "Abertas" },
    { id: "finalizadas", title: "Finalizadas" },
  ];

  function handleOnFetchComplete(data) {
    setVagas(data.data);
  }

  function handleOnLoading(loading) {
    setLoadingVagas(loading);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function handleStatusFilter(event) {
    const { value } = event.target;
    setQuery(state => {
      return { ...state, ...{ filters: value } };
    });
  }

  function renderFiltros() {
    return (
      <Filtros>
        <Row hascols>
          <Col>
            <Input
              name="vaga_titulo"
              onChange={handleOnChange}
              label="Título da vaga:"
            />
          </Col>
          <Col>
            <Select
              name="vaga_status"
              onChange={handleStatusFilter}
              label="Status:"
              options={statusOptions}
            />
          </Col>
        </Row>
      </Filtros>
    );
  }

  function renderVagas() {
    const vagasList = vagas.map(vaga => {
      const { id, quantidade, titulo, entrevistas_count } = vaga;

      return (
        <Col xl="1/3" lg="1/2" md="1" sm="1" key={id}>
          <VagaContainer
            type={quantidade === 0 ? "fechadas" : "abertas"}
            onClick={() => {
              history.push(`/vaga/${id}`);
            }}
          >
            <div className="body">
              <h4 className="tipo">
                {quantidade === 0 ? "Vaga finalizada" : "Vaga aberta"}
              </h4>
              <h3 className="titulo">{titulo}</h3>
              <h5 className="local">
                {vaga.cidade.nome}/{vaga.cidade.estado.sigla}
              </h5>
            </div>
            <div className="footer">
              <Entrevistas>
                <FaChevronCircleRight />
                {entrevistas_count} Entrevista{entrevistas_count > 1 && "s"}
              </Entrevistas>
            </div>
          </VagaContainer>
        </Col>
      );
    });

    return <Row hascols>{vagasList}</Row>;
  }

  return (
    <>
      <Loading loading={loadingVagas}>
        {renderFiltros()}
        {renderVagas()}
        <Paginator
          endpoint="vagas/entrevistas-expiradas"
          onFetchComplete={handleOnFetchComplete}
          onLoading={handleOnLoading}
          query={query}
        />
      </Loading>
    </>
  );
}

export default withRouter(ListarEntrevistasPassadas);
