import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiPlus } from "react-icons/fi";

import { EmpresaProvider } from "@/context/empresas/empresa";
import { VagaProvider } from "@/context/vagas/vaga";
import { VagasProvider } from "@/context/vagas/vagas";
import { useEmpresa } from "@/hooks/empresas";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Modal, Button } from "@/components/Common";
import { Sidebar, SubMenu as SubmenuEmpresa } from "@/components/Empresa";
import { SubMenu as SubmenuEscola } from "@/components/Escola";

import {
  ModalAddVaga,
  ListarVagasAnteriores,
  ListarVagasAbertas,
} from "@/components/Empresa/Vagas";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
  PainelPrincipalTopo,
  CircleButton,
  AvisoSupervisores,
} from "./styles";

function _Vagas() {
  const { data: user } = useSelector(state => state.auth);
  const [modalAddVaga, setModalAddVaga] = useState(false);
  const { fetchEmpresa, empresa } = useEmpresa();

  useEffect(() => {
    fetchEmpresa(user.empresa.id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {(empresa.escola && <SubmenuEscola type="vagas" />) || (
                  <SubmenuEmpresa type="vagas" />
                )}
              </MenuInterno>
              <PainelPrincipal>
                <PainelPrincipalTopo>
                  <h1>Vagas cadastradas</h1>
                  {(empresa.supervisores && empresa.supervisores.length && (
                    <Button variant="cyan" onClick={() => setModalAddVaga(true)}>Abrir vaga</Button>
                  )) || (
                    <AvisoSupervisores>
                      É necessário ter ao menos um supervisor cadastrado para a criação de uma vaga. Vá para o <Link to="/supervisores">Cadastro de supervisores</Link>
                    </AvisoSupervisores>
                  )}
                </PainelPrincipalTopo>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Vagas abertas</Tab>
                    <Tab>Vagas anteriores</Tab>
                  </TabList>
                  <TabPanel>
                    <ListarVagasAbertas />
                  </TabPanel>
                  <TabPanel>
                    <ListarVagasAnteriores />
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
      <Modal open={modalAddVaga} onClose={() => setModalAddVaga(false)}>
        <ModalAddVaga onClose={() => setModalAddVaga(false)} />
      </Modal>
    </Page>
  );
}

export default function Vagas() {
  return (
    <EmpresaProvider>
      <VagasProvider>
        <VagaProvider>
          <_Vagas />
        </VagaProvider>
      </VagasProvider>
    </EmpresaProvider>
  );
}
