import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { FiMapPin, FiBriefcase } from "react-icons/fi";
import { FaDownload, FaPrint } from "react-icons/fa";
import { BlobProvider } from "@react-pdf/renderer";
import { osName } from "react-device-detect";

import { useAluno } from "@/hooks/alunos";
import { useEmpresa } from "@/hooks/empresas";
import { AlunoProvider } from "@/context/alunos/aluno";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { EntrevistaProvider } from "@/context/entrevistas/entrevista";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { FullPageLoader, Button, Modal } from "@/components/Common";
import { Navbar } from "@/components/Blocks";
import { SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";
import { Sidebar } from "@/components/Aluno";

import Api, { makeOptions } from "@/services/api";
import { date, telefone, cep } from "@/formatters";
import EventEmitter from "@/event-emitter";

import RedesSociais from "./RedesSociais";
import ModalContratar from "./ModalContratar";
import ModalAddEntrevista from "@/components/Empresa/Entrevistas/ModalAddEntrevista";

import {
  PaineisContainer,
  VoltarContainer,
  Content,
  MenuInterno,
  PainelPrincipal,
  TopoInfo,
  VagaControllerWrapper,
  VagaController,
  CantAddEntrevistaMessage,
  CantContratarMessage,
  SectionContainer,
  Avatar,
  EscolaAtual,
  FormacoesAnteriores,
  FormacaoAnterior,
  ExperienciasProfissionais,
  ExperienciaProfissional,
  CursosComplementares,
  CursoComplementar,
  AtividadesComplementares,
  AtividadeComplementar,
  Idiomas,
  Idioma,
} from "./styles";

import { Document, Viewer } from "@/components/Curriculo";

function _VagaPerfilAluno({ match, history }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchAluno, aluno, loading } = useAluno();
  const { fetchEmpresa, empresa, loading: loadingEmpresa } = useEmpresa();
  const [vagaId, setVagaId] = useState(null);
  const [alunoId, setAlunoId] = useState(null);
  const [modalCurriculo, setModalCurriculo] = useState(false);

  /**
   * true = pode criar entrevista com o candidato
   * false = não pode criar uma entrevista porque já existe uma
   *    entrevista para o candidato e a mesma ainda não expirou.
   *    Adicionalmente, gravaremos na constante entrevistaAtiva a data e hora da entrevista
   * null = aguarde a resposta da api
   */
  const [canAddEntrevista, setCanAddEntrevista] = useState(null);
  const [entrevistaAtiva, setEntrevistaAtiva] = useState(null);
  const [modalAddEntrevista, setModalAddEntrevista] = useState(false);

  /**
   * true = pode contratar (criar TCE)
   * false = não pode contratar
   * null = aguarde a resposta da api
   */
  const [canContratar, setCanContratar] = useState(null);
  const [motivoCantContratar, setMotivoCantContratar] = useState(null);

  const [modalContratar, setModalContratar] = useState(false);

  /**
   * Buscar a entrevista ativa (caso exista) deste aluno para esta vaga
   * Uma entrevista é dada como ativa caso sua data e hora ainda não tenham passado
   */
  async function fetchEntrevistaAtiva() {
    const endpoint = `entrevistas?filters=naoExpiradas&aluno_id=${alunoId}&vaga_id=${vagaId}`;
    setCanAddEntrevista(null);
    const { data: response } = await Api.get(endpoint, makeOptions(user));
    if (response.success) {
      if (response.data.length > 0) {
        setCanAddEntrevista(false);
        setEntrevistaAtiva(state => {
          const { data, hora } = response.data[0];
          return { data, hora };
        });
      } else {
        setCanAddEntrevista(true);
      }
    }
  }

  // Verificar a viabilidade de contratação do aluno para esta vaga
  async function checarViabilidade() {
    const endpoint = `tces/checar-viabilidade/${alunoId}/${vagaId}`;
    setCanContratar(null);
    const { data: response } = await Api.get(endpoint, makeOptions(user));
    if (response.success) {
      setCanContratar(true);
    } else {
      setCanContratar(false);
      setMotivoCantContratar(response.errors.join(","));
    }
  }

  function renderVoltar() {
    return (
      <VoltarContainer>
        <Button
          variant="jeans"
          icon="arrow-left"
          onClick={() => {
            history.go(-1);
          }}
        >
          {" "}
          Voltar
        </Button>
      </VoltarContainer>
    );
  }

  function renderTopo() {
    const local = `${aluno.endereco.cidade.nome}, ${aluno.endereco.cidade.estado.sigla}`;

    return (
      <>
        <h1>{aluno.nome}</h1>
        <Row hascols>
          <Col lg="1/2" md="1">
            <TopoInfo>
              <div className="info">
                <ul>
                  <li>
                    <FiMapPin />
                    {local}
                  </li>
                  <li>
                    <FiBriefcase />
                    {aluno.curso.titulo}
                  </li>
                </ul>
              </div>
            </TopoInfo>
          </Col>
          <Col lg="1/2" sm="1">
            <RedesSociais
              redes={{
                facebook: aluno.facebook,
                twitter: aluno.twitter,
                instagram: aluno.instagram,
                linkedin: aluno.linkedin,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }

  function renderVagaController() {
    return (
      <VagaControllerWrapper>
        <VagaController>
          <div className="coluna coluna-1">
            <Button
              type="button"
              variant="tangerine"
              disabled={[null, false].includes(canAddEntrevista) || undefined}
              onClick={() => setModalAddEntrevista(true)}
            >
              Entrevistar
            </Button>
            <Button
              type="button"
              variant="cyan"
              disabled={[null, false].includes(canContratar) || undefined}
              onClick={() => setModalContratar(true)}
            >
              Contratar
            </Button>
          </div>
          <div className="coluna coluna-2">
            <BlobProvider document={<Document aluno={aluno} />}>
              {({ url }) => (
                <Button to={url} externalLink download="curriculo.pdf">
                  <FaDownload size={13} /> Baixar Currículo
                </Button>
              )}
            </BlobProvider>
            {!["android"].includes(osName.toLowerCase()) && (
              <Button type="button" onClick={() => setModalCurriculo(true)}>
                <FaPrint size={13} /> Imprimir
              </Button>
            )}
          </div>
        </VagaController>
        {canAddEntrevista === false && entrevistaAtiva !== null && (
          <CantAddEntrevistaMessage>
            Já existe uma entrevista agendada com este candidato para{" "}
            {date(entrevistaAtiva.data)} às{" "}
            {entrevistaAtiva.hora.substring(0, 5)}
          </CantAddEntrevistaMessage>
        )}
        {canContratar === false && motivoCantContratar !== null && (
          <CantContratarMessage>{motivoCantContratar}</CantContratarMessage>
        )}
      </VagaControllerWrapper>
    );
  }

  function renderDadosPessoais() {
    const { endereco, pcd } = aluno;

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Dados pessoais:</h2>
        </div>
        <Row hascols>
          <Col lg="1/3" md="1" sm="1">
            <div className="dados-pessoais-box">
              <h4>Sobre</h4>
              <p>
                Data de nascimento: {date(aluno.data_nascimento)}
                <br />
                País de origem: {aluno.pais.nome}
                <br />
                Sexo: {aluno.sexo === "F" ? "FEMININO" : "MASCULINO"}
                <br />
                Portador de deficiência? <br />
                {aluno.pcd !== null ? `Código PCD: ${aluno.pcd}` : "NÃO"}
              </p>
            </div>
          </Col>
          <Col lg="1/3" md="1" sm="1">
            <div className="dados-pessoais-box">
              <h4>Endereço</h4>
              {endereco && (
                <p>
                  {endereco.logradouro}, {endereco.numero}, {endereco.bairro}
                  <br />
                  {endereco.cidade.nome}, {endereco.cidade.estado.sigla}
                  <br />
                  CEP: {cep(endereco.cep)}
                </p>
              )}
            </div>
          </Col>
          <Col lg="1/3" md="1" sm="1">
            <div className="dados-pessoais-box">
              <h4>Contato</h4>
              <p>
                {telefone(aluno.telefone)}
                <br />
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="dados-pessoais-box">
              <h4>Portador de deficiência?</h4>
              <p>{pcd !== null ? `Código PCD: ${pcd}` : "NÃO"}</p>
            </div>
          </Col>
        </Row>
      </SectionContainer>
    );
  }

  function renderFormacaoAcademica() {
    function renderFormacaoAtual() {
      const dataInicio = format(new Date(aluno.data_inicio), "MM/yyyy");
      const dataTermino = format(new Date(aluno.data_conclusao), "MM/yyyy");

      return (
        <EscolaAtual>
          <Avatar src={aluno.empresa.imagem} />
          <div className="info">
            <div className="info-titulo">
              <h2>{aluno.curso.titulo}</h2>
              {aluno.empresa.nome_exibicao}
            </div>

            <div className="info-datas">
              <div>
                <span>Início</span> {dataInicio}
              </div>
              <div>
                <span>Previsão de término</span> {dataTermino}
              </div>
            </div>
          </div>
        </EscolaAtual>
      );
    }

    function renderFormacoesAnteriores() {
      return (
        <FormacoesAnteriores>
          {aluno.formacoes_anteriores.map(f => {
            const dataInicio = format(new Date(f.data_inicio), "MM/yyyy");
            const dataTermino = format(new Date(f.data_termino), "MM/yyyy");

            return (
              <FormacaoAnterior key={f.id}>
                <div className="info">
                  <h2>{f.curso_nome}</h2>
                  {f.empresa_nome}
                  <div className="info-datas">
                    <div>
                      <span>Início</span> {dataInicio}
                    </div>
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  </div>
                </div>
              </FormacaoAnterior>
            );
          })}
        </FormacoesAnteriores>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Formação acadêmica:</h2>
        </div>
        {renderFormacaoAtual()}
        {renderFormacoesAnteriores()}
      </SectionContainer>
    );
  }

  function renderExperienciasProfissionais() {
    function renderLista() {
      return (
        <ExperienciasProfissionais>
          {aluno.experiencias_profissionais.map(e => {
            const dataInicio = format(new Date(e.data_inicio), "MM/yyyy");
            const dataTermino = e.data_termino
              ? format(new Date(e.data_termino), "MM/yyyy")
              : null;
            return (
              <ExperienciaProfissional key={e.id}>
                <h2>{e.empresa_nome}</h2>
                <p className="cargo">{e.cargo}</p>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {e.atividades && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{e.atividades}</p>
                  </>
                )}
              </ExperienciaProfissional>
            );
          })}
        </ExperienciasProfissionais>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Experiências profissionais:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderCursosComplementares() {
    function renderLista() {
      return (
        <CursosComplementares>
          {aluno.cursos_complementares.map(c => {
            const dataInicio = format(new Date(c.data_inicio), "MM/yyyy");
            const dataTermino = c.data_termino
              ? format(new Date(c.data_termino), "MM/yyyy")
              : null;

            return (
              <CursoComplementar key={c.id}>
                <h2>{c.curso_nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {c.curso_descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{c.curso_descricao}</p>
                  </>
                )}
              </CursoComplementar>
            );
          })}
        </CursosComplementares>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Cursos complementares:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderAtividadesComplementares() {
    function renderLista() {
      return (
        <AtividadesComplementares>
          {aluno.atividades_complementares.map(a => {
            const dataInicio = format(new Date(a.data_inicio), "MM/yyyy");
            const dataTermino = a.data_termino
              ? format(new Date(a.data_termino), "MM/yyyy")
              : null;

            return (
              <AtividadeComplementar key={a.id}>
                <h2>{a.nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {a.descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{a.descricao}</p>
                  </>
                )}
              </AtividadeComplementar>
            );
          })}
        </AtividadesComplementares>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Atividades complementares:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderIdiomas() {
    function renderLista() {
      return (
        <Idiomas>
          {aluno.idiomas.map(idioma => {
            const { id, nome, pivot } = idioma;
            return (
              <Idioma key={id}>
                <h2>{nome}</h2>
                <p>{pivot.proficiencia}</p>
              </Idioma>
            );
          })}
        </Idiomas>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Idiomas:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderSubmenu() {
    const { empresa } = user;
    if (empresa.is_escola) {
      return <SubmenuEscola type="vagas" />;
    } else {
      return <SubmenuEmpresa type="vagas" />;
    }
  }

  function handleContratarComplete() {
    const location = {
      pathname: "/perfil",
      state: { tab: "TCEs" },
    };
    history.push(location);
  }

  useEffect(() => {
    const { vagaId, alunoId } = match.params;
    fetchAluno(alunoId);
    setVagaId(vagaId);
    setAlunoId(alunoId);
    fetchEmpresa(user.empresa.id);
  }, []);

  useEffect(() => {
    if (alunoId && vagaId) {
      fetchEntrevistaAtiva();
      checarViabilidade();
      EventEmitter.subscribe("entrevista-added", fetchEntrevistaAtiva);
    }
  }, [vagaId, alunoId]);

  return (
    <Page>
      <Navbar type="interna" />
      {(!aluno || !empresa) && <FullPageLoader />}
      {aluno && empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>{renderSubmenu()}</MenuInterno>
              <PainelPrincipal>
                <>
                  {renderVoltar()}
                  {renderTopo()}
                  {renderVagaController()}
                  {renderDadosPessoais()}
                  {renderFormacaoAcademica()}
                  {renderExperienciasProfissionais()}
                  {renderCursosComplementares()}
                  {renderAtividadesComplementares()}
                  {renderIdiomas()}
                </>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
          <Modal
            open={modalCurriculo}
            onClose={() => setModalCurriculo(false)}
            padding={"0"}
          >
            <Viewer aluno={aluno} onClose={() => setModalCurriculo(false)} />
          </Modal>
          <Modal
            open={modalAddEntrevista}
            onClose={() => setModalAddEntrevista(false)}
          >
            <ModalAddEntrevista
              vagaId={vagaId}
              alunoId={alunoId}
              endereco={empresa.endereco}
              onClose={() => setModalAddEntrevista(false)}
            />
          </Modal>
          <Modal open={modalContratar} onClose={() => setModalContratar(false)}>
            <ModalContratar
              valor_contrato={empresa.valor_contrato}
              alunoId={alunoId}
              vagaId={vagaId}
              onComplete={handleContratarComplete}
              onClose={() => setModalContratar(false)}
            />
          </Modal>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function VagaPerfilAluno({ match, history }) {
  return (
    <EntrevistaProvider>
      <EmpresaProvider>
        <AlunoProvider>
          <_VagaPerfilAluno match={match} history={history} />
        </AlunoProvider>
      </EmpresaProvider>
    </EntrevistaProvider>
  );
}
