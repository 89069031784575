import styled from "styled-components";

export const VagaCard = styled.div`
  width: 100%;
  height: 100%;
  min-height: 256px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 32px;
  background-color: #f5f5f5;
  text-align: left;
  transition: 300ms;

  &:hover {
    color: #f78764;
    div.candidato,
    div.detalhes,
    div.entrevistas {
      transform: translateX(2px);
      svg {
        color: #f78764;
        transform: translateX(3px);
      }
    }
  }

  div.body {
    h4.tipo {
      color: #f78764;
      letter-spacing: 1.5px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
    h5.area {
      font-size: 12px;
      margin-bottom: 12px;
    }
    h3.titulo {
      color: #2e2d38;
      font-size: 18px;
      font-weight: 400;
    }
    h5.remuneracao {
      margin-bottom: 12px;
      font-weight: 400;
    }
    h5.local {
      color: #9491b4;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    ul.cursos {
      margin-top: 12px;
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
  div.footer {
    display: flex;
    flex-direction: column;
  }
`;
