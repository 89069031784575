import React from "react";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";
import { format } from "date-fns";

import { telefone, cep, date } from "@/formatters";

export default function DocumentCurriculo({ aluno }) {
  function renderViewNome() {
    return (
      <View style={s.ViewNome}>
        <View style={s.ViewNome.Title}>
          <Text>{aluno.nome}</Text>
        </View>
      </View>
    );
  }

  function renderInfoBoxes() {
    const { endereco } = aluno;

    return (
      <View style={s.ViewInfoBoxes}>
        <View style={s.ViewInfoBoxes.Box}>
          <Text style={s.ViewInfoBoxes.Box.Title}>Sobre:</Text>
          <Text>DATA DE NASCIMENTO: {date(aluno.data_nascimento)}</Text>
          <Text>PAíS DE ORIGEM: {aluno.pais.nome}</Text>
          <Text>SEXO: {aluno.sexo === "F" ? "FEMININO" : "MASCULINO"}</Text>
          <Text>PORTADOR DE DEFICIÊNCIA?</Text>
          <Text>{aluno.pcd !== null ? `Código PCD: ${aluno.pcd}` : "NÃO"}</Text>
        </View>
        <View style={s.ViewInfoBoxes.Box}>
          <Text style={s.ViewInfoBoxes.Box.Title}>Endereço:</Text>
          <Text>
            {endereco.logradouro}, {endereco.numero}, {endereco.bairro}
          </Text>
          <Text>
            {endereco.cidade.nome}, {endereco.cidade.estado.sigla}
          </Text>
          <Text>CEP: {cep(endereco.cep)}</Text>
        </View>
        <View style={s.ViewInfoBoxes.Box}>
          <Text style={s.ViewInfoBoxes.Box.Title}>Contato:</Text>
          <Text>TELEFONE: {telefone(aluno.telefone)}</Text>
          <Text>E-MAIL: {aluno.usuario.email}</Text>
        </View>
      </View>
    );
  }

  function renderFormacaoAtual() {
    const style = s.ViewFormacaoAtual;

    const dataInicio = format(new Date(aluno.data_inicio), "MM/yyyy");
    const dataTermino = format(new Date(aluno.data_conclusao), "MM/yyyy");

    return (
      <View style={style}>
        <View style={style.Title}>
          <Text>Formação Acadêmica</Text>
        </View>
        <View>
          <Text style={style.Curso}>{aluno.curso.titulo}</Text>
          <Text style={style.Escola}>{aluno.empresa.nome_exibicao}</Text>
        </View>
        <View style={style.Datas}>
          <Text style={style.Datas.Data}>
            <Text style={style.Datas.Data.Span}>INÍCIO: </Text>
            {dataInicio}
          </Text>
          <Text style={style.Datas.Data}>
            <Text style={style.Datas.Data.Span}>PREVISÃO DE TÉRMINO: </Text>
            {dataTermino}
          </Text>
        </View>
      </View>
    );
  }

  function renderExperienciasProfissionais() {
    if (!aluno.experiencias_profissionais.length) return <View />;

    const style = s.ViewExperienciasProfissionais;

    function renderLista() {
      return aluno.experiencias_profissionais.map(e => {
        const dataInicio = format(new Date(e.data_inicio), "MM/yyyy");
        const dataTermino = e.data_termino
          ? format(new Date(e.data_termino), "MM/yyyy")
          : null;

        const itemStyle = s.ViewExperienciasProfissionais.Body.Item;

        return (
          <View style={itemStyle} key={e.id}>
            <Text style={itemStyle.Empresa}>{e.empresa_nome}</Text>
            <Text style={itemStyle.Cargo}>{e.cargo}</Text>
            <View style={itemStyle.Datas}>
              <Text style={itemStyle.Datas.Data}>
                <Text style={itemStyle.Datas.Data.Span}>INÍCIO: </Text>
                {dataInicio}
              </Text>
              {dataTermino && (
                <Text style={itemStyle.Datas.Data}>
                  <Text style={itemStyle.Datas.Data.Span}>TÉRMINO: </Text>
                  {dataTermino}
                </Text>
              )}
            </View>
            {e.atividades && (
              <View style={itemStyle.Descricao}>
                <Text style={itemStyle.Descricao.Title}>DESCRIÇÃO:</Text>
                <Text>{e.atividades}</Text>
              </View>
            )}
          </View>
        );
      });
    }

    return (
      <View style={style} wrap={false}>
        <View style={style.Title}>
          <Text>Experiências Profissionais</Text>
        </View>
        <View style={style.Body}>{renderLista()}</View>
      </View>
    );
  }

  function renderCursosComplementares() {
    if (!aluno.cursos_complementares.length) return <View />;

    const style = s.ViewCursosComplementares;

    function renderLista() {
      return aluno.cursos_complementares.map(c => {
        const dataInicio = format(new Date(c.data_inicio), "MM/yyyy");
        const dataTermino = c.data_termino
          ? format(new Date(c.data_termino), "MM/yyyy")
          : null;

        const itemStyle = s.ViewCursosComplementares.Body.Item;

        return (
          <View style={itemStyle} key={c.id}>
            <Text style={itemStyle.Curso}>{c.curso_nome}</Text>
            <View style={itemStyle.Datas}>
              <Text style={itemStyle.Datas.Data}>
                <Text style={itemStyle.Datas.Data.Span}>INÍCIO: </Text>
                {dataInicio}
              </Text>
              {dataTermino && (
                <Text style={itemStyle.Datas.Data}>
                  <Text style={itemStyle.Datas.Data.Span}>TÉRMINO: </Text>
                  {dataTermino}
                </Text>
              )}
            </View>
            {c.curso_descricao && (
              <View style={itemStyle.Descricao}>
                <Text style={itemStyle.Descricao.Title}>DESCRIÇÃO:</Text>
                <Text>{c.curso_descricao}</Text>
              </View>
            )}
          </View>
        );
      });
    }

    return (
      <View style={style} wrap={false}>
        <View style={style.Title}>
          <Text>Cursos Complementares</Text>
        </View>
        <View style={style.Body}>{renderLista()}</View>
      </View>
    );
  }

  function renderAtividadesComplementares() {
    if (!aluno.atividades_complementares.length) return <View />;

    const style = s.ViewAtividadesComplementares;

    function renderLista() {
      return aluno.atividades_complementares.map(a => {
        const dataInicio = format(new Date(a.data_inicio), "MM/yyyy");
        const dataTermino = a.data_termino
          ? format(new Date(a.data_termino), "MM/yyyy")
          : null;

        const itemStyle = s.ViewAtividadesComplementares.Body.Item;

        return (
          <View style={itemStyle} key={a.id}>
            <Text style={itemStyle.Atividade}>{a.nome}</Text>
            <View style={itemStyle.Datas}>
              <Text style={itemStyle.Datas.Data}>
                <Text style={itemStyle.Datas.Data.Span}>INÍCIO: </Text>
                {dataInicio}
              </Text>
              {dataTermino && (
                <Text style={itemStyle.Datas.Data}>
                  <Text style={itemStyle.Datas.Data.Span}>TÉRMINO: </Text>
                  {dataTermino}
                </Text>
              )}
            </View>
            {a.descricao && (
              <View style={itemStyle.Descricao}>
                <Text style={itemStyle.Descricao.Title}>DESCRIÇÃO:</Text>
                <Text>{a.descricao}</Text>
              </View>
            )}
          </View>
        );
      });
    }

    return (
      <View style={style} wrap={false}>
        <View style={style.Title}>
          <Text>Atividades Complementares</Text>
        </View>
        <View style={style.Body}>{renderLista()}</View>
      </View>
    );
  }

  function renderIdiomas() {
    if (!aluno.idiomas.length) return <View />;

    const style = s.ViewIdiomas;

    function renderLista() {
      return aluno.idiomas.map(i => {
        const { id, nome, pivot } = i;
        const itemStyle = s.ViewIdiomas.Body.Item;
        return (
          <View style={itemStyle} key={id}>
            <Text style={itemStyle.Idioma}>{nome}</Text>
            <Text style={itemStyle.Proficiencia}>{pivot.proficiencia}</Text>
          </View>
        );
      });
    }

    return (
      <View style={style}>
        <View style={style.Title}>
          <Text>Idiomas</Text>
        </View>
        <View style={style.Body}>{renderLista()}</View>
      </View>
    );
  }

  return (
    <Document>
      <Page size="A4" style={s.Page}>
        {renderViewNome()}
        {renderInfoBoxes()}
        {renderFormacaoAtual()}
        {renderExperienciasProfissionais()}
        {renderCursosComplementares()}
        {renderAtividadesComplementares()}
        {renderIdiomas()}
      </Page>
    </Document>
  );
}

// Styles

const s = StyleSheet.create({
  Page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    fontSize: 9,
  },
  get ViewGeneric() {
    return {
      marginBottom: 32,
      Title: {
        paddingBottom: 8,
        marginBottom: 16,
        borderBottomWidth: 1,
        borderBottomColor: "#D3D3D3",
        borderBottomStyle: "solid",
        fontSize: 16,
        fontWeight: "bold",
      },
    };
  },
  get ViewNome() {
    return {
      ...this.ViewGeneric,
      Title: {
        ...this.ViewGeneric.Title,
        fontSize: 24,
      },
    };
  },
  get ViewInfoBoxes() {
    return {
      ...this.ViewGeneric,
      display: "flex",
      flexDirection: "row",
      Box: {
        width: "33%",
        paddingRight: 16,
        Title: {
          fontSize: 9,
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 8,
        },
      },
    };
  },
  get ViewFormacaoAtual() {
    return {
      ...this.ViewGeneric,
      Curso: {
        fontWeight: "bold",
        marginBottom: 4,
      },
      Escola: {
        color: "#605F6A",
      },
      Datas: {
        marginTop: 8,
        display: "flex",
        flexDirection: "row",
        Data: {
          marginRight: 16,
          Span: {
            fontWeight: "bold",
            marginRight: 8,
          },
        },
      },
    };
  },
  get ViewExperienciasProfissionais() {
    return {
      ...this.ViewGeneric,
      Body: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        Item: {
          width: "50%",
          paddingRight: 16,
          marginBottom: 16,
          Empresa: {
            fontWeight: "bold",
            marginBottom: 4,
          },
          Cargo: {
            color: "#605F6A",
          },
          Datas: {
            ...this.ViewFormacaoAtual.Datas,
          },
          Descricao: {
            marginTop: 16,
            Title: {
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#605F6A",
              marginBottom: 8,
            },
          },
        },
      },
    };
  },
  get ViewCursosComplementares() {
    return {
      ...this.ViewGeneric,
      Body: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        Item: {
          width: "50%",
          paddingRight: 16,
          marginBottom: 16,
          Curso: {
            fontWeight: "bold",
          },
          Datas: {
            ...this.ViewFormacaoAtual.Datas,
          },
          Descricao: {
            marginTop: 16,
            Title: {
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#605F6A",
              marginBottom: 8,
            },
          },
        },
      },
    };
  },
  get ViewAtividadesComplementares() {
    return {
      ...this.ViewGeneric,
      Body: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        Item: {
          width: "50%",
          paddingRight: 16,
          marginBottom: 16,
          Atividade: {
            fontWeight: "bold",
          },
          Datas: {
            ...this.ViewFormacaoAtual.Datas,
          },
          Descricao: {
            marginTop: 16,
            Title: {
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#605F6A",
              marginBottom: 8,
            },
          },
        },
      },
    };
  },
  get ViewIdiomas() {
    return {
      ...this.ViewGeneric,
      Body: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        Item: {
          width: "33%",
          paddingRight: 16,
          marginBottom: 16,
          Idioma: {
            fontWeight: "bold",
            marginBottom: 4,
          },
          Proficiencia: {
            color: "#605F6A",
          },
        },
      },
    };
  },
});
