import React, { useState, useEffect, useRef } from "react";
import { useField } from "@rocketseat/unform";
import NumberFormat from "react-number-format";
import { InputContainer } from "../styles";

export default function InputMoney({
  label,
  placeholder,
  name,
  rounded,
  onChange,
  value
}) {
  const ref = useRef();
  const { fieldName, registerField, error } = useField(name);
  const [rawValue, setRawValue] = useState(value);

  function handleOnValueChange(values) {
    const { floatValue } = values;
    setRawValue(floatValue);
  }

  function handleFormat(value) {
    const formatted = Number(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatted;
  }

  function handleRemoveFormatting(value) {
    return value
      .replace(/\./, "")
      .replace(/,/, ".")
      .replace(/[^0-9.]/g, "");
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "value",
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    if (onChange){
      const event = {
        target: { name: fieldName, value: rawValue || 0 }
      };
      onChange(event);
    }
  }, [rawValue]);


  return (
    <InputContainer label={label} rounded={rounded}>
      {label && <label>{label}</label>}
      <input type="hidden" name={fieldName} value={rawValue} ref={ref} />
      {error && <span>{error}</span>}
      <NumberFormat
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"R$ "}
        placeholder={placeholder || ""}
        onValueChange={handleOnValueChange}
        defaultValue={rawValue}
        // value={Number(rawValue) || undefined}
        format={handleFormat}
        removeFormatting={handleRemoveFormatting}
      />
    </InputContainer>
  );
}
