import React from "react";
import { Route, Redirect } from "react-router-dom";

import EmpresaEntrevistas from "@/pages/Empresa/Entrevistas/Entrevistas";
import AlunoEntrevistas from "@/pages/Aluno/Entrevistas/Entrevistas";

export default function EntrevistaRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch (true) {
    case tipo === "aluno":
      Component = AlunoEntrevistas;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaEntrevistas;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      Component = EmpresaEntrevistas;
      break;
    case ["administrador"].includes(tipo):
      Component = <div>Root: Entrevistas</div>;
      break;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
