import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useAluno } from "@/hooks/alunos";
import { format } from "date-fns";
import { FaEllipsisV, FaPen, FaTrash } from "react-icons/fa";
import { Modal } from "@/components/Common";

import ModalEditAtividade from "./ModalEditAtividade";
import ModalAddAtividade from "./ModalAddAtividade";
import ModalDeleteAtividade from "./ModalDeleteAtividade";

import {
  CircleButton,
  SmallCircleButton,
  Container,
  List,
  ListItem,
  MenuContainer,
} from "./styles";

export default function CursosComplementares() {
  const { aluno } = useAluno();
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  function handleUpdate(atividade) {
    setDataToUpdate(atividade);
    setModalEditOpen(true);
  }

  function handleDelete(id) {
    setIdToDelete(id);
    setModalDeleteOpen(true);
  }

  function renderLista() {
    return (
      <List>
        <>
          {aluno.atividades_complementares.map(a => {
            const dataInicio = format(new Date(a.data_inicio), "MM/yyyy");
            const dataTermino = a.data_termino
              ? format(new Date(a.data_termino), "MM/yyyy")
              : null;

            const menuHandler = (
              <div className="menu">
                <FaEllipsisV />
              </div>
            );

            const menuContent = (
              <MenuContainer>
                <span className="option" onClick={() => handleUpdate(a)}>
                  Editar
                </span>
                <span
                  className="option option-danger"
                  onClick={() => handleDelete(a.id)}
                >
                  Remover
                </span>
              </MenuContainer>
            );

            return (
              <ListItem key={a.id}>
                <h2>{a.nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {a.descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{a.descricao}</p>
                  </>
                )}
                <div className="actions">
                  <SmallCircleButton onClick={() => handleUpdate(a)}>
                    <FaPen size={10} />
                  </SmallCircleButton>
                  <SmallCircleButton danger onClick={() => handleDelete(a.id)}>
                    <FaTrash size={10} />
                  </SmallCircleButton>
                </div>
              </ListItem>
            );
          })}
          <Modal
            open={modalDeleteOpen}
            onClose={() => setModalDeleteOpen(false)}
          >
            <ModalDeleteAtividade
              id={idToDelete}
              onClose={() => setModalDeleteOpen(false)}
            />
          </Modal>
        </>
      </List>
    );
  }

  return (
    <Container>
      <div className="titulo">
        <h2>Atividades complementares: </h2>
        <CircleButton onClick={() => setModalAddOpen(true)}>
          <FiPlus />
        </CircleButton>
      </div>
      <div className="contente">{renderLista()}</div>
      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditAtividade
          data={dataToUpdate}
          onClose={() => setModalEditOpen(false)}
        />
      </Modal>
      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)}>
        <ModalAddAtividade onClose={() => setModalAddOpen(false)} />
      </Modal>
    </Container>
  );
}
