import React from "react";
import { Link } from "react-router-dom";
import { Container, MyLink } from "./styles";

export default function Empresa({ type }) {

  return (
    <Container>
      <MyLink active={type === "vagas"}>
        <Link to="/vagas">Vagas de estágio</Link>
      </MyLink>
      <MyLink active={type === "perfil"}>
        <Link to="/perfil">Meu perfil</Link>
      </MyLink>
      <MyLink active={type === "entrevistas"}>
        <Link to="/entrevistas">Entrevistas</Link>
      </MyLink>
    </Container>
  );
}
