import React from "react";
import { Navbar, Footer } from "@/components/Blocks";
import { Page, Container, Row, Col} from "@/components/Grid";
import { Card, Button } from "@/components/Common";
import { SectionOptions } from "./styles";

import iconEstudante from "@/assets/images/icons/estudante.png";
import iconEmpresa from "@/assets/images/icons/empresa.png";
import iconEscola from "@/assets/images/icons/escola.png";

const content = {
  options: [
    {
      title: "Estudantes",
      description: "Tenha acesso e candidate-se à vagas de estágio em diversas empresas.",
      icon: iconEstudante,
      to: "/cadastro-estudante"
    },
    {
      title: "Empresas",
      description: "Cadastre vagas e encontre estagiários para sua empresa.",
      icon: iconEmpresa,
      to: "/cadastro-empresa"
    },
    {
      title: "Escolas",
      description: "Traga seus estudantes ao Integrador.app ou encontre estagiários para a escola.",
      icon: iconEscola,
      to: "/cadastro-escola"
    },
  ]
};

export default function CadastreSe() {

  const CardsOpcoes = content.options.map((o, index) => {
    return (
      <Col key={index}>
        <Card shadow className="card-opcao">
          <img src={o.icon} />
          <p className="title">{o.title}</p>
          <p className="description">{o.description}</p>
          <Button to={o.to}>Cadastre-se</Button>
        </Card>
      </Col>
    );
  });

  return (
    <Page>
      <Navbar type="login" />
      <SectionOptions>
        <Container>
          <Row hascols gaps="50">
            {CardsOpcoes}
          </Row>
        </Container>
      </SectionOptions>
      <Footer />
    </Page>
  );

};
