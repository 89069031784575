import { call, put, select } from "redux-saga/effects";
import api, { makeOptions, queryBuilder } from "@/services/api";
import toastify from "@/services/toastify";
import Actions from "../../ducks/planilhas/sugestoes-escolas";

const getAuthData = state => state.auth.data;

export function* getLink(action) {
  try {
    const { params } = action;
    const query = queryBuilder(params);
    const authData = yield select(getAuthData);
    const endpoint = `relatorios/planilha-sugestoes-escolas?${query}`;

    const { data: response } = yield call(
      api.get,
      endpoint,
      makeOptions(authData)
    );

    if (response.success) {
      yield put(Actions.success(response.data));
    } else {
      yield put(Actions.failure(response.message));
    }
  } catch (error) {
    toastify("Erro ao se comunicar com a API");
    yield put(Actions.failure("Erro ao se comunicar com a API"));
  }
}
