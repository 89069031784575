import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FaChevronCircleRight, FaSignInAlt } from "react-icons/fa";

import AuthActions from "@/store/ducks/auth";
import { Input } from "@/components/Form";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import Paginator, { Loading } from "@/components/Paginator";
import PlanilhaEscolas from "@/components/Planilhas/Escolas";

import { telefone, cnpj, cpf } from "@/formatters";

import {
  Header,
  FiltroBasico,
  FilterButtonContainer,
  Totais,
  EmpresaListItem,
  ActionButton,
} from "./styles";

export default function Escolas({ history, match }) {
  const dispatch = useDispatch();
  const [empresas, setEmpresas] = useState([]);

  const [readyToPaginate, setReadyToPaginate] = useState(false);
  const [query, setQuery] = useState({ filters: "escola" });

  const [paginator, setPaginator] = useState(null);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  function handleOnFetchComplete(data) {
    setEmpresas(data.data);
    setPaginator(data.paginator);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderHeader() {
    let titulo = "Todas as escolas";

    return (
      <Header>
        <h4>Escolas</h4>
        <h2>{titulo}</h2>
        <div className="breadcrumbs">
          Escolas {">"} <span>{titulo}</span>
        </div>
      </Header>
    );
  }

  function renderFiltroBasico() {
    const colNome = {
      lg: 0.7,
      md: 0.5,
      sm: 1,
    };

    const colButton = {
      lg: 0.3,
      md: 0.5,
      sm: 1,
    };

    return (
      <FiltroBasico>
        <Row hascols>
          <Col {...colNome}>
            <Input
              label="Nome da escola"
              name="nome"
              placeholder="Busque pelo nome da escola"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...colButton}>
            <FilterButtonContainer>
              <PlanilhaEscolas block params={query} />
            </FilterButtonContainer>
          </Col>
        </Row>
      </FiltroBasico>
    );
  }

  function renderTotais() {
    if (loadingEmpresas) return <></>;
    if (paginator !== null) {
      const { total } = paginator;
      if (total === 0) {
        return (
          <Totais>Sem escolas para exibir dentro dos critérios de busca</Totais>
        );
      }
      return (
        <Totais>
          Exibindo {empresas.length} de {total} escolas
        </Totais>
      );
    }
  }

  function handleAssumirControle(empresaId) {
    const data = {
      entidade_tipo: "empresa",
      entidade_id: empresaId,
    };
    dispatch(AuthActions.authAssumirControleRequest(data, history));
  }

  function handleVerPerfil(empresaId) {
    history.push(`/escola/${empresaId}`);
  }

  function renderEmpresas() {
    return empresas.map(empresa => {
      return (
        <EmpresaListItem key={empresa.id}>
          <div className="coluna coluna-nome">
            <h4>{empresa.nome_exibicao}</h4>
            <p>
              {empresa.tipo_pessoa === "J" ? "CNPJ: " : "CPF:"}
              {empresa.tipo_pessoa === "J"
                ? cnpj(empresa.documento)
                : cpf(empresa.documento)}
            </p>
            <h5>{empresa.email}</h5>
          </div>
          <div className="coluna coluna-responsavel">
            <h4>Responsável</h4>
            {empresa.responsavel_legal_nome}
          </div>
          <div className="coluna coluna-responsavel">
            <h4>Contato</h4>
            {telefone(empresa.responsavel_legal_telefone)}
          </div>
          <div className="coluna coluna-actions">
            <ActionButton onClick={() => handleAssumirControle(empresa.id)}>
              <FaSignInAlt color="#271946" size={14} />{" "}
              <span>Assumir controle</span>
            </ActionButton>
            <ActionButton onClick={() => handleVerPerfil(empresa.id)}>
              <FaChevronCircleRight color="#271946" size={14} />{" "}
              <span>Ver perfil</span>
            </ActionButton>
          </div>
        </EmpresaListItem>
      );
    });
  }

  const setQueryCB = useCallback(() => {
    const status = match.params.hasOwnProperty("status")
      ? `escola,${match.params.status}`
      : "escola";
    setQuery(state => ({ ...state, ...(status && { filters: status }) }));
    setReadyToPaginate(true);
  }, [match.params, setQuery, setReadyToPaginate]);

  useEffect(() => {
    setQueryCB();
  }, [setQueryCB]);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        {renderHeader()}
        {renderFiltroBasico()}
        {renderTotais()}
        <Loading loading={loadingEmpresas}>
          {renderEmpresas()}
          {readyToPaginate && (
            <Paginator
              endpoint="empresas"
              query={query}
              onFetchComplete={handleOnFetchComplete}
              onLoading={l => setLoadingEmpresas(l)}
            />
          )}
        </Loading>
      </DashboardContainer>
    </Page>
  );
}
