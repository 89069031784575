import styled from "styled-components";

export const Header = styled.div`
  h2 {
    font-size: 30px;
    color: #000000;
  }
  padding-bottom: 35px;
  border-bottom: 1px solid #b9b7cc;
  margin-bottom: 24px;
`;

export const Titulo = styled.div`
  h3 {
    font-size: 24px;
    color: #2e2d38;
  }
  h4 {
    font-size: 16px;
    color: #9491b4;
    font-weight: 600;
  }
`;

export const ItemLista = styled.div`
  padding: 32px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #b9b7cc;
  }
`;

export const MultiplaEscolha = styled(ItemLista)`
  display: flex;
  flex-direction: row;
  align-items: center;
  .opcoes {
    flex: 0 0 400px;
    margin-left: auto;
    text-align: right;
    padding-left: 24px;
    span {
      margin-top: -24px;
    }
  }

  @media screen and (max-width: 1100px) {
    display: block;
    .opcoes {
      margin-top: 24px;
      text-align: left;
      margin-left: 0px;
      padding-left: 0px;
    }
  }
`;

export const Textual = styled(ItemLista)`
  textarea {
    margin-top: 24px;
    display: block;
    width: 100%;
    height: 150px;
  }
`;

export const Pergunta = styled.div``;
