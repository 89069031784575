import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import EmpresaCursosActions from "@/store/ducks/empresas/cursos";

const getAuthData = state => state.auth.data;

export function* addCurso(action) {
  const { empresaId, cursoId, onSuccess } = action;
  try {
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.put,
      `empresas/${empresaId}/cursos/${cursoId}`,
      {},
      makeOptions(authData)
    );
    if (response.success) {
      if (onSuccess) onSuccess();
      toastify("Curso adicionado com sucesso");
      yield put(EmpresaCursosActions.empresaCursoSuccess());
    } else {
      yield put(EmpresaCursosActions.empresaCursoFailure(response.message));
    }
  } catch (error) {
    toastify("Não foi possível realizar sua requisição", "error");
    yield put(EmpresaCursosActions.empresaCursoFailure("Algo deu errado"));
  }
}

export function* removeCurso(action) {
  const { empresaId, cursoId, onSuccess } = action;
  try {
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.delete,
      `empresas/${empresaId}/cursos/${cursoId}`,
      makeOptions(authData)
    );
    if (response.success) {
      if (onSuccess) onSuccess();
      toastify("Curso removido com sucesso");
      yield put(EmpresaCursosActions.empresaCursoSuccess());
    } else {
      yield put(EmpresaCursosActions.empresaCursoFailure(response.message));
    }
  } catch (error) {
    toastify("Não foi possível realizar sua requisição", "error");
    yield put(EmpresaCursosActions.empresaCursoFailure("Algo deu errado"));
  }
}

export function* addTodosCursos(action) {
  const { empresaId, onSuccess } = action;
  try {
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.put,
      `empresas/${empresaId}/cursos/all`,
      {},
      makeOptions(authData)
    );
    if (response.success) {
      if (onSuccess) onSuccess();
      toastify("Cursos adicionados com sucesso");
      yield put(EmpresaCursosActions.empresaCursoSuccess());
    } else {
      yield put(EmpresaCursosActions.empresaCursoFailure(response.message));
    }
  } catch (error) {
    toastify("Não foi possível realizar sua requisição", "error");
    yield put(EmpresaCursosActions.empresaCursoFailure("Algo deu errado"));
  }
}

export function* removeTodosCursos(action) {
  const { empresaId, onSuccess } = action;
  try {
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.delete,
      `empresas/${empresaId}/cursos/all`,
      makeOptions(authData)
    );
    if (response.success) {
      if (onSuccess) onSuccess();
      toastify("Cursos removidos com sucesso");
      yield put(EmpresaCursosActions.empresaCursoSuccess());
    } else {
      yield put(EmpresaCursosActions.empresaCursoFailure(response.message));
    }
  } catch (error) {
    toastify("Não foi possível realizar sua requisição", "error");
    yield put(EmpresaCursosActions.empresaCursoFailure("Algo deu errado"));
  }
}
