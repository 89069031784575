import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaDownload } from "react-icons/fa";

import PlanilhaEmpresasActions from "@/store/ducks/planilhas/empresas";
import { Button } from "@/components/Common";

export default function DownloadPlanilhaEmpresas({ params = {}, ...rest }) {
  const dispatch = useDispatch();
  const [href, setHref] = useState(null);

  const { data, loading } = useSelector(state => state.planilhaEmpresas);

  function handleRequestPlanilha() {
    dispatch(PlanilhaEmpresasActions.request(params));
  }

  function renderButton() {
    if (!href) {
      return (
        <Button
          title="Solicitar geração de planilha de empresas"
          onClick={handleRequestPlanilha}
          loading={loading}
          {...rest}
        >
          <FaDownload size={13} /> Solicitar geração de planilha
        </Button>
      );
    }

    return (
      <Button
        externalLink
        title="Fazer download da planilha de empresas"
        to={href}
        loading={loading}
        {...rest}
      >
        <FaDownload size={13} /> Download da planilha
      </Button>
    );
  }

  const setHrefCB = useCallback(() => {
    if (!data) return setHref(null);
    const { url } = data;
    setHref(url);
  }, [data, setHref]);

  const resetDataCB = useCallback(() => {
    dispatch(PlanilhaEmpresasActions.reset());
  }, [params, dispatch]);

  useEffect(() => {
    setHrefCB();
  }, [setHrefCB]);

  useEffect(() => {
    resetDataCB();
  }, [resetDataCB]);

  return <>{renderButton()}</>;
}
