import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  createEmpresaRequest: ["data", "history", "pathname"],
  createEmpresaSuccess: ["data"],
  createEmpresaFailure: ["error"],
  createEmpresaReset: [],
});

export const createEmpresaTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const createEmpresaRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const createEmpresaSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const createEmpresaFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const createEmpresaReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_EMPRESA_REQUEST]: createEmpresaRequest,
  [Types.CREATE_EMPRESA_SUCCESS]: createEmpresaSuccess,
  [Types.CREATE_EMPRESA_FAILURE]: createEmpresaFailure,
  [Types.CREATE_EMPRESA_RESET]: createEmpresaReset,
});
