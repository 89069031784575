import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators  } = createActions({
  deleteAlunoRequest: ["id", "history"],
  deleteAlunoSuccess: [],
  deleteAlunoFailure: ["error"],
});

export const deleteAlunoTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  error: null
};

export const deleteAlunoRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const deleteAlunoSuccess = (state, action) => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const deleteAlunoFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_ALUNO_REQUEST]: deleteAlunoRequest,
  [Types.DELETE_ALUNO_SUCCESS]: deleteAlunoSuccess,
  [Types.DELETE_ALUNO_FAILURE]: deleteAlunoFailure,
});
