import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const PainelPrincipalTopo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PainelPrincipalTopoTitulo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px #d5d4e0 solid;
  margin-bottom: 48px;

  ul {
    display: flex;

    li {
      font-size: 14px;
      font-weight: 600;
      color: #a09faa;

      &.ativo {
        color: #271946;
      }
    }
    li + li {
      &::before {
        content: ">";
        padding: 0 8px;
      }
    }
  }
`;

export const TituloVaga = styled.h1`
  color: #2e2d38 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
`;

export const CidadeVaga = styled.h5`
  color: #9491b4;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 32px;
`;

export const DescricaoVaga = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ul {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    list-style: disc;
    padding-left: 16px;

    span.beneficio-valor {
      font-weight: 600;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: #f78764;
    margin-bottom: 16px;
    text-transform: uppercase;
    &.resumo {
      color: #279cd9;
    }
  }
`;

export const ButtonEdit = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px #b9b7cc solid;
  margin-left: 8px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.9);
  }
`;

export const ResumoCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #000;
    padding-bottom: 16px;
    border-bottom: 1px #e7e6ed solid;
    margin-bottom: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #605f6a;
    margin-bottom: 32px;
  }
`;

export const GroupCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ButtonVerLista = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  max-width: 264px;
  margin-bottom: 16px;

  &:hover {
    span {
      transform: scale(0.9);
    }
    p {
      opacity: 0.5;
    }
  }
`;

export const Icon = styled.span`
  display: flex;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  background: #f78764;
  margin-right: 8px;
  transition: 300ms ease;
`;

export const Label = styled.small`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  transition: 300ms ease;
`;

export const Controles = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  border-top: 1px #e7e6ed solid;

  p {
    color: #605f6a;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const GroupButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const ButtonFinaliza = styled.button`
  display: flex;
  padding: 14px 24px;
  background-color: #271946;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 16px;
  transition: 300ms ease;
  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const ButtonCancelar = styled.button`
  display: flex;
  padding: 14px 24px;
  background-color: transparent;
  color: #271946;
  border-radius: 4px;
  border: 1px #271946 solid;
  font-size: 18px;
  font-weight: 600;
  transition: 300ms ease;
  &:hover {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.93);
  }
`;

export const FiltrosCandidatos = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    color: #605f6a;
  }
`;

export const GroupList = styled.div`
  margin: 32px 0;
`;
