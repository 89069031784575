import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Navbar, Footer } from "@/components/Blocks";
import { Card, Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Page, Row, Col } from "@/components/Grid";
import { EntrarContainer, CardWrapper } from "./styles";

import AuthActions from "@/store/ducks/auth";

import schema from "./schemas";

export default function CompletarCadastro({ match, history }) {
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.auth);
  const { codigo } = match.params;

  useEffect(() => {
    return () => {
      dispatch(AuthActions.authReset());
    };
  }, [dispatch]);

  const _handleSubmit = data => {
    const recuperarSenha = {
      recuperacao_senha: codigo,
      senha: data.senha,
    };
    dispatch(AuthActions.authRecover(recuperarSenha, history));
  };

  return (
    <Page>
      <Navbar type="login" />
      <EntrarContainer>
        {codigo !== undefined && (
          <CardWrapper>
            <h1>Crie sua senha</h1>
            <p>Falta apenas criar uma senha para continuar</p>
            <Card shadow>
              <Form schema={schema} onSubmit={_handleSubmit}>
                <Row>
                  <Col>
                    <Input label="Senha:" type="password" name="senha" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      label="Confirme sua senha:"
                      type="password"
                      name="senha_c"
                    />
                  </Col>
                </Row>
                <Row hascols gaps="16">
                  <Col>
                    <Button type="submit" variant="magenta" loading={loading || undefined} block>
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </CardWrapper>
        )}
        {codigo === undefined && (
          <CardWrapper>
            <h1>Ops</h1>
            <p>Por favor, acesse o link presente em seu e-mail</p>
          </CardWrapper>
        )}
      </EntrarContainer>
      <Footer />
    </Page>
  );
}
