import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { SupervisoresContext } from "@/context/supervisores/supervisores";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

const useSupervisores = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(SupervisoresContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchSupervisores(empresaId) {
    _setLoading(true);
    const endpoint = `supervisores/empresa/${empresaId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function createSupervisor(data) {
    _setLoading(true);
    const endpoint = `supervisores`;
    const { data: response } = await api.post(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Supervisor adicionado com sucesso");
      fetchSupervisores(data.empresa_id);
    } else {
      toastify("Não foi possível adicionar este supervisor", "error");
    }
  }

  async function deleteSupervisor(supervisorId) {
    _setLoading(true);
    const endpoint = `supervisores/${supervisorId}`;
    const { data: response } = await api.delete(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      fetchSupervisores(user.empresa.id);
      toastify("Supervisor removido com sucesso");
    } else {
      toastify(response.message, "error");
    }
  }

  return {
    fetchSupervisores,
    createSupervisor,
    deleteSupervisor,
    supervisores: state.data,
    loading: state.loading,
  };
};

export { useSupervisores };
