import React from "react";
import { format, parse } from "date-fns";
import { Container, Avatar, Infos, Nome, Data } from "./styles";

export default function CardEntrevista({ data, onClick }) {
  function handleClick() {
    if (onClick) onClick();
  }

  function renderDataHora() {
    const date = parse(`${data.data} ${data.hora}`, "yyyy-MM-dd HH:mm:ss", new Date());
    return format(date, "dd/MM/yy 'às' HH:mm");
  }

  return (
    <Container onClick={handleClick}>
      <Avatar url={data.aluno.imagem} />
      <Infos>
        <Nome>{data.aluno.nome}</Nome>
        <Data>
          <span>Data: </span>
          {renderDataHora()}
        </Data>
      </Infos>
    </Container>
  );
}
