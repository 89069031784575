import styled from "styled-components";

export const Container = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const MyLink = styled.li`
  &:not(:last-child) {
    margin-right: 36px;
  }
  a {
    text-decoration: none;
    ${props => (props.active ? "color: #271946" : "color: #8b8a95")};
    transition: 300ms ease;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: #271946;
    }
  }
`;
