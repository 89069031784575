import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useAluno } from "@/hooks/alunos";
import { AlunoProvider } from "@/context/alunos/aluno";
import { AvaliacaoProvider } from "@/context/avaliacoes/avaliacao";
import { Page, DashboardContainer } from "@/components/Grid";
import { FullPageLoader } from "@/components/Common";
import { Navbar } from "@/components/Blocks";

import { Sidebar, SubMenu, Curriculo, TCEs } from "@/components/Aluno";
import ListarAvaliacoes from "@/components/Aluno/Avaliacoes/ListarAvaliacoes";

import {
  PaineisContainer,
  Content,
  MenuInterno,
  PainelPrincipal,
  AvisoCurriculo,
} from "./styles";

function _Perfil({ history }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchAluno, aluno } = useAluno();
  const [tabIndex, setTabIndex] = useState(0);

  function renderPerfilAviso() {
    const {
      idiomas,
      cursos_complementares,
      atividades_complementares,
      formacoes_anteriores,
      experiencias_profissionais,
    } = aluno;

    if (
      idiomas.length === 0 &&
      cursos_complementares.length === 0 &&
      atividades_complementares.length === 0 &&
      formacoes_anteriores.length === 0 &&
      experiencias_profissionais.length === 0
    ) {
      return (
        <AvisoCurriculo>
          <p>
            Você pode adicionar formações, experiências profissionais, idiomas e
            outros para complementar seu currículo
          </p>
        </AvisoCurriculo>
      );
    }

    return <></>;
  }

  const handleGoToTabCB = useCallback(() => {
    const { location } = history;
    if (location.hasOwnProperty("state") && location.state !== undefined) {
      const { state } = location;
      if (state.hasOwnProperty("tab")) {
        const { tab } = history.location.state;
        if (tab === "TCEs") setTabIndex(1);
      }
    }
  }, [history]);

  useEffect(() => {
    handleGoToTabCB();
  }, [handleGoToTabCB]);

  useEffect(() => {
    fetchAluno(user.perfil_id)
  }, [user]);

  return (
    <Page>
      <Navbar type="interna" />
      {!aluno && <FullPageLoader />}
      {aluno && (
        <>
          {renderPerfilAviso()}
          <DashboardContainer>
            <PaineisContainer>
              <Sidebar />
              <Content>
                <MenuInterno>
                  <SubMenu type="perfil" />
                </MenuInterno>
                <PainelPrincipal>
                  <h1>Meu Perfil</h1>
                  <Tabs
                    forceRenderTabPanel={true}
                    selectedIndex={tabIndex}
                    onSelect={index => setTabIndex(index)}
                  >
                    <TabList>
                      <Tab>Currículo</Tab>
                      <Tab>Contratos</Tab>
                      <Tab>Avaliações</Tab>
                      {/* <Tab>Minha Conta</Tab> */}
                    </TabList>
                    <TabPanel>
                      <Curriculo />
                    </TabPanel>
                    <TabPanel>
                      <TCEs />
                    </TabPanel>
                    <TabPanel>
                      <ListarAvaliacoes />
                    </TabPanel>
                    {/* <TabPanel>Minha Conta</TabPanel> */}
                  </Tabs>
                </PainelPrincipal>
              </Content>
            </PaineisContainer>
          </DashboardContainer>
        </>
      )}
    </Page>
  );
}

export default function Perfil(props) {
  return (
    <AlunoProvider>
      <AvaliacaoProvider>
        <_Perfil {...props} />
      </AvaliacaoProvider>
    </AlunoProvider>
  );
}
