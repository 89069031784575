import React from "react";
import { Form as RocketForm } from "@rocketseat/unform";

export default function Form({ schema, initialData, context, onSubmit, ...rest }) {

  const _schema = schema !== undefined ? schema : undefined;
  const _initialData = initialData !== undefined ? initialData : undefined;
  const _context = context !== undefined ? context : undefined;
  const _onSubmit = onSubmit !== undefined ? onSubmit : undefined;

  return (
    <RocketForm
      schema={_schema}
      initialData={_initialData}
      context={_context}
      onSubmit={_onSubmit}
     { ...rest }
    />
  )
}
