import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";
import Paginator, { Loading } from "@/components/Paginator";

import { Aluno, Avatar, ActionButton } from "./styles";

function ListarAlunos({ history }) {
  const [alunos, setAlunos] = useState([]);
  const [alunosLoading, setAlunosLoading] = useState(false);
  const [query] = useState({});

  function handleAlunosData(data) {
    setAlunos(data.data);
  }

  function renderAlunos() {
    return alunos.map(aluno => {
      return <Aluno key={aluno.id}>
        <div className="coluna coluna-avatar">
          <Avatar url={aluno.imagem} />
        </div>
        <div className="coluna coluna-nome">{aluno.nome}</div>
        <div className="coluna coluna-curso">
          <h4>Curso:</h4>
          {aluno.curso.titulo}
        </div>
        <div className="coluna coluna-actions">
          <ActionButton onClick={() => {
            history.push(`/aluno/${aluno.id}`);
          }}>
            <FaChevronCircleRight color="#271946" size={14} /> <span>Ver perfil</span>
          </ActionButton>
        </div>
      </Aluno>;
    });
  }

  return (
    <>
      <Loading loading={alunosLoading}>
        {renderAlunos()}
        <Paginator
          endpoint="alunos"
          query={query}
          onLoading={loading => setAlunosLoading(loading)}
          onFetchComplete={handleAlunosData}
        />
      </Loading>
    </>
  );
}

export default withRouter(ListarAlunos);
