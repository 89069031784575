import React, { useState, useEffect, forceUpdate } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import Paginator, { Loading } from "@/components/Paginator";

import { Select } from "@/components/Form";

import { TceListItem, ActionButton, Label, Div } from "./styles";

function TCEs({ history, match }) {
  const [tces, setTces] = useState([]);
  const [query, setQuery] = useState({ filters: "pendente" });
  const [Default, setDefault] = useState("pendente");
  const [paginator, setPaginator] = useState(null);
  const [loadingTces, setLoadingTces] = useState(false);

  const Options = [
    { id: "todos", title: "Todos os Contratos" },
    { id: "pendente", title: "Contratos pendentes de aceite" },
    { id: "ativo", title: "Contrato Ativo" },
    { id: "recusado", title: "Contratos cancelados" },
    { id: "encerrado", title: "Contratos rescindidos" },
  ];

  function handleOnFetchComplete(data) {
    setTces(data.data);
    setPaginator(data.paginator);
  }

  function adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    return numero;
  }

  function handleChangeFilter(e) {
    return setQuery({ filters: e.target.value });
  }

  function renderRescisao(rescisao) {
    if (rescisao === null) return <span></span>;

    const format_data = rescisao.substring(0, rescisao.length - 9);
    const data = new Date(format_data);
    const data_rescisao =
      adicionaZero((data.getDate() + 1).toString()) +
      "/" +
      adicionaZero(data.getMonth() + 1).toString() +
      "/" +
      data.getFullYear();

    return (
      <div className="coluna coluna-rescisao">
        <h4>Data da Rescisão</h4>
        <h5>{data_rescisao}</h5>
      </div>
    );
  }

  function renderTCEs() {
    if (paginator && paginator.total === 0) {
      return <Label>Você não possui nenhum contrato</Label>;
    }

    if (tces.length > 0) {
      return tces.map(tce => (
        <TceListItem key={tce.id}>
          <div className="coluna coluna-nome">
            <h4>{tce.aluno.nome}</h4>
            <h5>{tce.vaga.titulo}</h5>
          </div>
          <div className="coluna coluna-status">
            <h4>Status</h4>
            <h5>{tce.status.descricao}</h5>
          </div>
          {renderRescisao(tce.rescisao_data)}
          <div className="coluna coluna-actions">
            <ActionButton
              onClick={() => {
                history.push(`/tce/${tce.id}`);
              }}
            >
              <FaChevronCircleRight color="#271946" size={14} />{" "}
              <span>Ver Detalhes</span>
            </ActionButton>
          </div>
        </TceListItem>
      ));
    }

    return <></>;
  }

  return (
    <Loading loading={loadingTces}>
      <Div>
        <Select
          name="filtro-tces"
          label="Filtro dos Contratos"
          options={Options}
          onChange={e => {
            handleChangeFilter(e);
            setDefault(e.target.value);
          }}
          value={Default}
        />
        <div className="colunasub">
          <h3>{tces.length}</h3>
        </div>
      </Div>
      <span>
        <br />
      </span>
      {renderTCEs()}
      <Paginator
        endpoint="tces"
        query={query}
        onFetchComplete={handleOnFetchComplete}
        onLoading={loading => setLoadingTces(loading)}
      />
    </Loading>
  );
}

export default withRouter(TCEs);
