import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./config/ReactotronConfig";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./styles/global";
import { store, persistor } from "./store";
import "react-datepicker/dist/react-datepicker.css";
import history from "./services/history";
import Routes from "./routes";
import CheckToken from "./components/Auth/CheckToken";

toast.configure();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        {/* <BrowserRouter basename="/"> */}
        <BrowserRouter basename="/">
          <CheckToken history={history} />
          <Routes history={history} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
