import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import { Form, Input } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";

import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditRedes({ onClose }) {
  const [formData, setFormData] = useState(null);
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  useEffect(() => {
    setFormData(() => {
      const { facebook, linkedin, instagram, twitter } = aluno;
      return { facebook, linkedin, instagram, twitter };
    });
  }, [aluno]);

  const schema = Yup.object().shape({
    facebook: Yup.string().url("Url Inválida"),
    instagram: Yup.string().url("Url Inválida"),
    linkedin: Yup.string().url("Url Inválida"),
    twitter: Yup.string().url("Url Inválida"),
  });

  async function updateRedes(data, resetForm) {
    setLoading(true);
    const endpoint = `alunos/${aluno.id}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      toastify("Redes sociais salvas com sucesso");
      fetchAluno(aluno.id);
      resetForm();
      onClose();
    }
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleSubmit(data, { resetForm }) {
    updateRedes(data, resetForm);
  }

  return (
    <ModalContent>
      {formData && (
        <Form onSubmit={handleSubmit} schema={schema} initialData={formData}>
          <ModalBody>
            <Row hascols>
              <Col>
                <Input
                  type="url"
                  label="Facebook"
                  name="facebook"
                  value={formData.facebook || ""}
                  onChange={handleOnChange}
                />
                <small>Insira o link completo para o seu perfil</small>
              </Col>
              <Col>
                <Input
                  type="url"
                  label="Linkedin"
                  name="linkedin"
                  value={formData.linkedin || ""}
                  onChange={handleOnChange}
                />
                <small>Insira o link completo para o seu perfil</small>
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  type="url"
                  label="Instagram"
                  name="instagram"
                  value={formData.instagram || ""}
                  onChange={handleOnChange}
                />
                <small>Insira o link completo para o seu perfil</small>
              </Col>
              <Col>
                <Input
                  type="url"
                  label="Twitter"
                  name="twitter"
                  value={formData.twitter || ""}
                  onChange={handleOnChange}
                />
                <small>Insira o link completo para o seu perfil</small>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
