import styled from "styled-components";

export const CurriculoContainer = styled.div``;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const HeaderNome = styled.div`
  margin-bottom: 48px;

  @media screen and (max-width: 1100px) {
    margin-bottom: 12px;
  }

  div.linha1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #271946;
    }
  }

  div.linha2 {
    ul li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 35px;
      }
      svg {
        color: #279cd9;
        margin-right: 9px;
        font-size: 16px;
      }
    }
  }
`;

export const DadosPessoais = styled.div.attrs({
  className: "dados-pessoais",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #B9B7CC;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 40px;
  }
`;

export const DadosPessoaisBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  padding-right: 16px;
  h4 {
    color: #F78764;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
  }
`;
