import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useEntrevista } from "@/hooks/entrevistas";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { Form, Input, InputMask, Select, Scope } from "@/components/Form";
import api from "@/services/api";
import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import { estados } from "@/data";
import { subDays } from "date-fns";

import { ModalContent, ModalFooter, ModalBody } from "./styles";

export default function ModalAddEntrevista({
  onClose,
  vagaId,
  alunoId,
  endereco,
}) {
  const { createEntrevista, loading } = useEntrevista();
  const [formData, setFormData] = useState({
    endereco: {},
  });

  const [cidades, setCidades] = useState([]);
  const [cidadesLoading, setCidadesLoading] = useState(false);
  const consultaCep = useSelector(state => state.consultaCep);
  const dispatch = useDispatch();

  const options_estados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });

  const schema = Yup.object().shape({
    data: Yup.date()
      .typeError("Obrigatório")
      .min(subDays(new Date(), 1), "A data não pode estar no passado"),
    hora: Yup.string().required("Obrigatório"),
    endereco: Yup.object().shape({
      cep: Yup.string().required("Obrigatório"),
      logradouro: Yup.string().required("Obrigatório"),
      numero: Yup.string(),
      complemento: Yup.string(),
      bairro: Yup.string().required("Obrigatório"),
      cidade_id: Yup.string().required("Obrigatório"),
    }),
    observacoes: Yup.mixed(),
  });

  async function fetchCidades(estadoId) {
    setCidadesLoading(true);
    const { data: retorno } = await api.get(`/cidades/estado/${estadoId}`);
    if (retorno.success) {
      const cidades = retorno.data.map(c => {
        return { id: c.id, title: c.nome };
      });
      setCidades(cidades);
      setCidadesLoading(false);
    }
  }

  function setInitialData() {
    fetchCidades(endereco.cidade.estado_id);
    endereco.estado = endereco.cidade.estado_id;
    setFormData(state => {
      return { ...state, ...{ endereco } };
    });
  }

  function handleCepChanged(event) {
    const { value } = event.target;
    if (value.length === 9) {
      dispatch(ConsultaCepActions.consultaCepRequest(value));
    }
  }

  function handleEstadoChanged(event) {
    const { value } = event.target;
    event.persist();
    fetchCidades(value);
  }

  function handleEnderecoChanged(event) {
    const { name, value } = event.target;
    const param = name.split(".")[1];

    setFormData(state => {
      const endereco = { ...state.endereco, ...{ [param]: value } };
      return { ...state, ...{ endereco } };
    });
  }

  function handleSubmit(data) {
    const entrevista = {
      ...data,
      ...{ vaga_id: vagaId, aluno_id: alunoId },
    };
    createEntrevista(entrevista);
  }

  useEffect(() => {
    setInitialData();
    return () => {
      dispatch(ConsultaCepActions.consultaCepReset());
    };
  }, []);

  useEffect(() => {
    if (consultaCep.error === null && consultaCep.data !== null) {
      const {
        bairro,
        cep,
        cidade_id,
        complemento,
        estado_id,
        logradouro,
        numero,
      } = consultaCep.data;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        endereco: enderecoFragment,
      };
      fetchCidades(estado_id);
      setFormData(state => {
        return { ...state, ...dataToUpdate };
      });
    }
  }, [consultaCep]);

  useEffect(() => {
    if (!loading) {
      onClose();
    }
  }, [loading]);

  return (
    <ModalContent>
      <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
        <ModalBody>
          <h2>Agendar entrevista com este candidato</h2>
          <Row>
            <Col>
              <Input
                type="date"
                max="2099-01-01"
                label="Data da entrevista"
                name="data"
                value={formData.data}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                type="time"
                label="Horário da entrevista"
                name="hora"
                value={formData.hora}
              />
            </Col>
          </Row>

          <Scope path="endereco">
            <Row hascols>
              <Col md="2/6">
                <InputMask
                  type="cep"
                  label="CEP"
                  name="cep"
                  onChange={handleCepChanged}
                  value={formData.endereco.cep || undefined}
                />
              </Col>
              <Col md="1/6">
                <Select
                  label="UF"
                  name="estado"
                  options={options_estados}
                  value={formData.endereco.estado || undefined}
                  onChange={e => {
                    handleEstadoChanged(e);
                    handleEnderecoChanged(e);
                  }}
                />
              </Col>
              <Col>
                <Select
                  label="Cidade"
                  name="cidade_id"
                  options={cidades}
                  loading={cidadesLoading}
                  value={formData.endereco.cidade_id || undefined}
                  onChange={handleEnderecoChanged}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  label="Logradouro"
                  name="logradouro"
                  value={formData.endereco.logradouro || ""}
                  onChange={handleEnderecoChanged}
                />
              </Col>
              <Col>
                <Input
                  label="Número"
                  name="numero"
                  value={formData.endereco.numero || ""}
                  onChange={handleEnderecoChanged}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  label="Complemento"
                  name="complemento"
                  value={formData.endereco.complemento || ""}
                  onChange={handleEnderecoChanged}
                />
              </Col>
              <Col>
                <Input
                  label="Bairro"
                  name="bairro"
                  value={formData.endereco.bairro || ""}
                  onChange={handleEnderecoChanged}
                />
              </Col>
            </Row>
          </Scope>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            block
            variant="tangerine"
            loading={loading || undefined}
          >
            Agendar entrevista
          </Button>
          <Button
            type="button"
            block
            variant="cancel"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
