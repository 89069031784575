import React from "react";

import { CardContainer } from "./styles";

export default function Card({ shadow, flex, children, ...rest }) {
  const _shadow = shadow !== undefined ? true : undefined;
  const _flex = flex !== undefined ? true : undefined;

  return (
    <CardContainer shadow={_shadow} flex={_flex} { ...rest }>
      {children}
    </CardContainer>
  );
}
