import React, { useState } from "react";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaPen,
} from "react-icons/fa";

import { Modal } from "@/components/Common";
import ModalEditRedes from "./ModalEditRedes";
import {
  Container,
  CircleButton,
  FacebookButton,
  LinkedinButton,
  InstagramButton,
  TwitterButton,
} from "./styles";

export default function RedesSociais({ redes }) {
  const [modalEditOpen, setModalEditOpen] = useState(false);

  return (
    <Container>
      <div className="actions">
        <CircleButton onClick={() => setModalEditOpen(true)}>
          <FaPen size={10} />
        </CircleButton>
      </div>
      <div className="buttons">
        <FacebookButton href={redes.facebook || undefined} target="_blank">
          <FaFacebookSquare />
        </FacebookButton>

        <LinkedinButton href={redes.linkedin || undefined} target="_blank">
          <FaLinkedin />
        </LinkedinButton>

        <InstagramButton href={redes.instagram || undefined} target="_blank">
          <FaInstagram />
        </InstagramButton>

        <TwitterButton href={redes.twitter || undefined} target="_blank">
          <FaTwitter />
        </TwitterButton>
      </div>
      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditRedes onClose={() => setModalEditOpen(false)} />
      </Modal>
    </Container>
  );
}
