import * as Yup from "yup";

const schema = Yup.object().shape({
  senha: Yup.string().required("Obrigatório"),
  senha_c: Yup.string()
    .required("Obrigatório")
    .test("senha-match", "Confirme sua senha", function(value) {
      return this.parent.senha === value;
    }),
});

export default schema;
