import styled from "styled-components";

export const Container = styled.div``;

export const Topo = styled.div`
  margin-bottom: 32px;
  h3.nome {
    font-size: 28px;
    font-weight: 600;
    color: #271946;
  }
  h5.empresa {
    font-size: 18px;
    font-weight: 600;
    color: #423b8d;
  }
`;

export const Controles = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;

  .button-container:not(:last-child) {
    margin-right: 14px;
  }

  .actions {
    margin-left: auto;
  }

  @media screen and (max-width: 1400px) {
    flex-wrap: wrap;
    .button-container {
      button,
      a {
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    display: block;
    .button-container {
      width: 100%;
      display: block;
      button,
      a {
        display: block;
        width: 100%;
      }
    }
  }
`;

export const Sobre = styled.div`
  margin-top: 64px;
  font-size: 16px;
  color: #605f6a;
`;

export const SobreTitulo = styled.div`
  border-bottom: 1px solid #b9b7cc;
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #000000;
  }
`;

export const SobreSection = styled.div`
  margin-top: 32px;
  font-size: 16px;
  h4 {
    color: #279cd9;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  ul {
    line-height: 24px;
    margin-bottom: 16px;
    list-style: disc;
    padding-left: 16px;

    span.beneficio-valor {
      font-weight: 600;
    }
  }
`;

export const SolicitacoesDesligamento = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  border-top: 1px solid #b9b7cc;
  padding: 24px 0;
  .main {
    h4 {
      color: #423b8d;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 16px;
    }
  }
  .action {
    display: flex;
    flex-direction: row;
    .button-container:not(:last-of-type) {
      display: block;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    .main {
      margin-bottom: 24px;
    }
  }
`;
