import React, { useEffect, useState, useCallback } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { Input } from "@/components/Form";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import Paginator, { Loading } from "@/components/Paginator";
import PlanilhaTces from "@/components/Planilhas/Tces";

import {
  Header,
  FiltroBasico,
  FilterButtonContainer,
  Totais,
  TceListItem,
  ActionButton,
} from "./styles";

export default function TCEs({ history, match }) {
  const [tces, setTces] = useState([]);

  const [readyToPaginate, setReadyToPaginate] = useState(false);
  const [query, setQuery] = useState({});

  const [paginator, setPaginator] = useState(null);
  const [loadingTces, setLoadingTces] = useState(false);

  function handleOnFetchComplete(data) {
    setTces(data.data);
    setPaginator(data.paginator);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderHeader() {
    let titulo;

    const status = match.params.hasOwnProperty("status")
      ? match.params.status
      : null;

    switch (true) {
      case status === "pendente":
        titulo = "Contratos Pendentes de aceite";
        break;
      case status === "pendente-integrador":
        titulo = "Contratos Pendentes de aprovação do Integrador.App";
        break;
      case status === "pendente-contratante":
        titulo = "Contratos Pendentes de aceite da empresa concedente";
        break;
      case status === "pendente-contratado":
        titulo = "Contratos Pendentes de aceite do estudante";
        break;
      case status === "pendente-contratado-escola":
        titulo = "Contratos Pendentes de aceite da escola";
        break;
      case status === "ativo":
        titulo = "Contratos ativos";
        break;
      case status === "encerrado":
        titulo = "Contratos encerrados";
        break;
      case status === "efetivado":
        titulo = "Contratos efetivados";
        break;
      case status === "recusado-integrador":
        titulo = "Contratos Recusados pelo Integrador.App";
        break;
      case status === "recusado-contratante":
        titulo = "Contratos Recusados pela empresa concedente";
        break;
      case status === "recusado-contratado":
        titulo = "Contratos Recusados pelo estudante";
        break;
      case status === "recusado-contratado-escola":
        titulo = "Contratos Recusados pela escola";
        break;
      case status === "recusado":
        titulo = "Contratos Recusados";
        break;
      case status === "desligamento-pendente":
        titulo = "Contratos com solicitação de desligamento";
        break;
      default:
        titulo = "Todos";
    }

    return (
      <Header>
        <h4>Contratos</h4>
        <h2>{titulo}</h2>
        <div className="breadcrumbs">
          Contratos > <span>{titulo}</span>
        </div>
      </Header>
    );
  }

  function renderFiltroBasico() {
    const colNome = {
      lg: 0.7,
      md: 0.5,
      sm: 1,
    };

    const colButton = {
      lg: 0.3,
      md: 0.5,
      sm: 1,
    };

    return (
      <FiltroBasico>
        <Row hascols>
          <Col {...colNome}>
            <Input
              label="Nome do estudante"
              name="aluno_nome"
              placeholder="Busque pelo nome do estudante"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...colButton}>
            <FilterButtonContainer>
              <PlanilhaTces block params={query} />
            </FilterButtonContainer>
          </Col>
        </Row>
      </FiltroBasico>
    );
  }

  function renderTotais() {
    if (loadingTces) return <></>;
    if (paginator !== null) {
      const { total } = paginator;
      if (total === 0) {
        return (
          <Totais>
            Sem contratos para exibir dentro dos critérios de busca
          </Totais>
        );
      }
      return (
        <Totais>
          Exibindo {tces.length} de {total} contratos
        </Totais>
      );
    }
  }

  function renderTCEs() {
    return tces.map(tce => {
      return (
        <TceListItem key={tce.id}>
          <div className="coluna coluna-nome">
            <h4>{tce.aluno.nome}</h4>
            <span>Nº Contrato: {tce.id}</span>
          </div>
          <div className="coluna coluna-empresa">
            <h4>Empresa</h4>
            {tce.vaga.empresa.nome_exibicao}
          </div>
          <div className="coluna coluna-escola">
            <h4>Escola</h4>
            {tce.aluno.empresa.nome_exibicao}
          </div>
          <div className="coluna coluna-local">
            <h4>Local</h4>
            {tce.vaga.cidade.nome}/{tce.vaga.cidade.estado.sigla}
          </div>
          <div className="coluna coluna-status">
            <h4>Status</h4>
            {tce.status.descricao}
          </div>
          <div className="coluna coluna-actions">
            <ActionButton
              onClick={() => {
                history.push(`/tce/${tce.id}`);
              }}
            >
              <FaChevronCircleRight color="#271946" size={14} />{" "}
              <span>Ver Detalhes</span>
            </ActionButton>
          </div>
        </TceListItem>
      );
    });
  }

  const setQueryCB = useCallback(() => {
    const status = match.params.hasOwnProperty("status")
      ? match.params.status
      : null;
    setQuery(state => ({ ...state, ...(status && { filters: status }) }));
    setReadyToPaginate(true);
  }, [match.params, setQuery, setReadyToPaginate]);

  useEffect(() => {
    setQueryCB();
  }, [setQueryCB]);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        {renderHeader()}
        {renderFiltroBasico()}
        {renderTotais()}
        <Loading loading={loadingTces}>
          {renderTCEs()}
          {readyToPaginate && (
            <Paginator
              endpoint="tces"
              query={query}
              onFetchComplete={handleOnFetchComplete}
              onLoading={loading => setLoadingTces(loading)}
            />
          )}
        </Loading>
      </DashboardContainer>
    </Page>
  );
}
