const EventEmitter = {
  events: {},
  // disparar evento
  dispatch: function(event, payload = null) {
    if (!this.events[event]) return;
    try {
      this.events[event].forEach(callback => callback(payload));
    } catch (error) {
      console.error({ error });
    }
  },
  // se inscrever a um evento
  subscribe: function(event, callback) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  // remover a inscrição a um evento
  unsubscribe: function(event) {
    if (this.events[event]) delete this.events[event];
  },
};

export default EventEmitter;
