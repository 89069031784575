import React from "react";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";

import {
  Container,
  FacebookButton,
  LinkedinButton,
  InstagramButton,
  TwitterButton,
} from "./styles";

export default function RedesSociais({ redes }) {
  return (
    <Container>
      <div className="buttons">
        <FacebookButton href={redes.facebook || undefined} target="_blank">
          <FaFacebookSquare />
        </FacebookButton>

        <LinkedinButton href={redes.linkedin || undefined} target="_blank">
          <FaLinkedin />
        </LinkedinButton>

        <InstagramButton href={redes.instagram || undefined} target="_blank">
          <FaInstagram />
        </InstagramButton>

        <TwitterButton href={redes.twitter || undefined} target="_blank">
          <FaTwitter />
        </TwitterButton>
      </div>
    </Container>
  );
}
