import styled from "styled-components";


export const LoadingContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const AnimationContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255, .5);
  border-radius: 10px;
  padding-top: 4px;
  width: 80px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -15px;
`;

export const ButtonsContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  border: 1px solid #DDDDDD;
  border-radius: 3px;
  overflow: none;
`;

export const Button = styled.button`
  background-color: #F5F5F5;
  padding: 12px 16px;
  border-radius: 0px;
  font-weight: 600;
  &.active {
    background-color: #279CD9;
    color: #FFFFFF;
  }
`;
