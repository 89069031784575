import React from "react";
import { Route, Redirect } from "react-router-dom";

import EscolaAluno from "@/pages/Escola/Alunos/Aluno";
import AdminAluno from "@/pages/Admin/Alunos/Aluno";
import Login from "@/pages/Auth/Login";

export default function AlunoRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch (true) {
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      Component = EscolaAluno;
      break;
    case tipo === "administrador":
      Component = AdminAluno;
      break;
    default: Component = Login;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
