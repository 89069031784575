import { call, put, select } from "redux-saga/effects";
import api, { makeOptions, queryBuilder } from "@/services/api";

import IndicadoresActions from "@/store/ducks/indicadores/totais-por-periodo";

const getAuthData = state => state.auth.data;

export function* request(action) {
  try {
    const { params = {} } = action;
    const queryParams = queryBuilder(params);
    const authData = yield select(getAuthData);
    const url = `indicadores/totais-por-periodo?${queryParams}`;
    const { data: retorno } = yield call(api.get, url, makeOptions(authData));

    if (retorno.success) {
      yield put(IndicadoresActions.success(retorno.data));
    } else {
      yield put(IndicadoresActions.failure(retorno.message));
    }
  } catch (error) {
    yield put(IndicadoresActions.failure("Erro ao se comunicar com a api"));
  }
}
